import { useEffect, useState } from 'react';
import {
  bbgSymbolToSgSymbol,
  isBBEnvAvailable,
  bbAppComponent,
  getLaunchpadSym,
} from '../../util';
import { ProductType } from '../../types';
import useSetSym from '../../hooks/hiro/useSetSym';
import { useRecoilValue } from 'recoil';
import { hiroManifestState } from '../../states';
import useToast from '../../hooks/useToast';

// this component listens to bloomberg launchpad hotspot changes and correspondingly changes the symbol
// that we are showing to be that symbol
// this should only be used when running on the Bloomberg terminal
// TODO: THERE IS A BUG HERE IF THIS IS USED IN App.tsx
// it does not seem to set the symbol properly on launchpad hotspot change
// for now, let's render this in HiroChart to get around this

export const BloombergListener = () => {
  const { setSym, getSym } = useSetSym();
  const manifest = useRecoilValue(hiroManifestState);
  const { openToast } = useToast();
  const [initedSym, setInitedSym] = useState(false);

  const manifestContainsSym = (sym: string) => {
    return manifest.underlyingsSet.has(sym) || manifest.combosSet.has(sym);
  };

  const symUnsupportedToast = (sym: string) => {
    openToast({
      message: `Unfortunately, we do not currently support the symbol '${sym}'.`,
      type: 'error',
      duration: 5000,
    });
  };

  useEffect(() => {
    if (initedSym || manifest == null) {
      return;
    }

    // only trigger this once manifest loads but do not run more than once
    const launchpadSym = getLaunchpadSym();
    if (launchpadSym != null) {
      const sgSym = bbgSymbolToSgSymbol(launchpadSym);
      if (sgSym != null && manifestContainsSym(sgSym)) {
        setSym(sgSym, ProductType.HIRO);
      } else {
        symUnsupportedToast(sgSym ?? launchpadSym);
      }
    }

    setInitedSym(true);
  }, [manifest, initedSym]);

  useEffect(() => {
    if (!isBBEnvAvailable() || manifest == null || bbAppComponent == null) {
      return;
    }

    // subscribe to future launchpad symbol changes
    const subId = bbAppComponent.addPropertyChangeHandler(
      (evt: string, value: any) => {
        if (evt === 'Security 1' || evt === 'Security 2') {
          console.log('Received security change to', value);

          const sgSym = bbgSymbolToSgSymbol(value);
          if (sgSym === getSym() || (value?.length ?? 0) === 0) {
            return;
          }

          if (sgSym == null || !manifestContainsSym(sgSym)) {
            return symUnsupportedToast(sgSym ?? value);
          }

          setSym(sgSym, ProductType.HIRO);
        }
      },
    );

    return () => {
      if (subId) {
        bbAppComponent.removePropertyChangeHandler(subId);
      }
    };
  }, [manifest]);

  return null;
};
