import { useEffect } from 'react';
import {
  isBBEnvAvailable,
  isPreMarket,
  toBbgSymbol,
  firstMatchInObj,
  ET,
} from '../../../util';
import { MktFields } from '../../../lib/bloomberg/MktFields';
import { PREMARKET_SYMBOLS } from '../../../config';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  bbgMarketSessionState,
  hiroManifestState,
  streamingPricesOverrideForSymState,
} from '../../../states';
import { utcMsToEtSecs } from '../../../util';
import useLog from '../../useLog';
import useSetSym from '../../hiro/useSetSym';

const MILLI_HRS = 60 * 60 * 1_000;

const usePrices = (updatePrice: any) => {
  const [bbgMarketSession, setBbgMarketSession] = useRecoilState(
    bbgMarketSessionState,
  );
  const [streamingPricesOverrideForSym, setStreamingPricesOverrideForSym] =
    useRecoilState(streamingPricesOverrideForSymState);
  const hiroManifest = useRecoilValue(hiroManifestState);
  const { logError } = useLog('usePrices');
  const { sym } = useSetSym();

  const getSubscriptionString = (
    fields: any,
    serviceType: string,
    subscriptionType: string,
    interval: number,
  ) => {
    const options: any = {
      fields: Object.values(fields).join(','),
    };
    if (interval) {
      options.interval = interval;
    }
    const optionsString = Object.keys(options)
      .map((name) => `${name}=${options[name]}`)
      .join('&');
    const subString = `${serviceType}/${subscriptionType}?${optionsString}`;
    return subString;
  };

  useEffect(() => {
    if (!isBBEnvAvailable()) {
      return;
    }

    let realtimeSource: any, subId: string;
    const dontUseRealtimePrices =
      streamingPricesOverrideForSym === sym ||
      process.env.REACT_APP_USE_STREAMING_PRICES_FOR_BBG_REALTIME === 'true';

    const connectToBbgMarketData = async () => {
      if (
        !isBBEnvAvailable() ||
        dontUseRealtimePrices ||
        bbgMarketSession == null
      ) {
        console.log('bbgPrices Not connecting to bbg market data, returning');
        return;
      }

      let priceSym = sym;
      if (hiroManifest?.combos) {
        const match = hiroManifest?.combos.find(
          (hash: any) => hash.combo === sym,
        );
        if (match) {
          priceSym = match.price_sym;
        }
      }

      const currentSymbol = toBbgSymbol(priceSym);

      const subStringArray = [currentSymbol].map((security) => {
        const subString = [
          getSubscriptionString(MktFields, '//blp/mktdata', security!, 1),
          security,
        ];
        return subString;
      });

      const onStatusChangedCallback = function (status: any) {
        console.log('bbgPrices RealtimeSource status changed', status);
      };

      const dataCallback = function* (_realTimeDataSource: any, _elem: any) {
        let data: any;
        if (isPreMarket() && !PREMARKET_SYMBOLS.has(sym)) {
          console.log(
            `bbgPrices Received price update for ${sym} but hiro does not show it premarket`,
          );
        } else {
          // @ts-ignore
          while ((data = yield)) {
            console.log('bbgPrices Received new data', data);
            if (data.IS_DELAYED_STREAM) {
              console.log(
                `bbgPrices The Bloomberg data stream is delayed for ${sym}. Switching to using streaming prices instead...`,
              );
              // instead of showing delayed data, tell the streaming api to use streaming prices instead for this sym
              setStreamingPricesOverrideForSym(sym);
              return;
            }

            // bloomberg seems to have many keys that all have the same value
            // but these keys dont seem to reliably always be on the returned object
            // it's very odd, but check for each key in order of importance and return the first match
            const lastPriceTime = firstMatchInObj(data, [
              'LAST_PRICE_TIME_TODAY_REALTIME',
              'LAST_TRADE_RECEIVED_TIME_RT',
              'LAST_TRADE_PRICE_TIME_TODAY_RT',
              'PRICE_LAST_TIME_RT',
              'LAST_UPDATE_ALL_SESSIONS_RT',
              'TRADE_UPDATE_STAMP_RT',
              'BID_ASK_TIME',
            ]);
            const lastPrice = firstMatchInObj(data, [
              'LAST_CONTINUOUS_TRADE_PRICE_RT',
              'LAST_PRICE',
              'PRICE_LAST_RT',
              'LAST_TRADE',
              'ALL_PRICE',
              'LAST_TRADE_PRICE_TODAY_RT',
              'LAST_PRICE_TDY',
            ]);

            // returning cancels the subscription, so only return when you want to cancel the subscription
            if (lastPriceTime == null || lastPrice == null) {
              console.error(
                'bbgPrices BLPAPI: lastPriceTime or lastPrice is null',
              );
            } else {
              const timeTS = dayjs()
                .tz(ET)
                .hour(lastPriceTime.hours)
                .minute(lastPriceTime.minutes)
                .second(lastPriceTime.seconds)
                .millisecond(lastPriceTime.milliseconds ?? 0);

              // Bloomberg can send us timestamps in different timezones.  Convert it as close to "now" modulo hours.
              const nowMillis = dayjs().valueOf();
              const diff = timeTS.valueOf() - nowMillis;
              const subtrahend = Math.round(diff / MILLI_HRS);
              const timestamp = timeTS.subtract(subtrahend, 'hours');

              let secsTimestamp = utcMsToEtSecs(timestamp);
              const newPrice = {
                value: lastPrice,
                time: secsTimestamp,
              };
              updatePrice(newPrice);
            }
          }
        }
      };

      realtimeSource = bbgMarketSession.createRealtimeDataSource(dataCallback);
      realtimeSource.onStatusChanged(onStatusChangedCallback);
      subId = await realtimeSource.subscribe([...subStringArray]);
    };

    try {
      connectToBbgMarketData();
    } catch (err) {
      logError(err, 'bbgPrices');
      // error connecting to bloomberg market data, so let's use streaming prices instead
      setStreamingPricesOverrideForSym(sym);
    }

    return () => {
      try {
        if (realtimeSource != null) {
          console.log('bbgPrices Unsubscribing from', realtimeSource);
          realtimeSource.unsubscribe(
            subId ?? realtimeSource.activeSubscriptions(),
          );
        }
      } catch (err) {
        logError(err, 'bbgPrices useEffect cleanup');
      }
    };
  }, [sym, bbgMarketSession, streamingPricesOverrideForSym, hiroManifest]);

  return {};
};

export default usePrices;
