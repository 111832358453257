import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Stack,
  AutocompleteRenderGroupParams,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MuiColorInput } from 'mui-color-input';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  isMobileState,
  playAlertAudioState,
  userDetailsState,
  watchlistsState,
} from '../../states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  accountDetailsState,
  colorModeState,
  negativeTrendColorState,
  positiveTrendColorState,
  savePreferencesLoadingState,
  timezoneState,
} from '../../states/auth';
import useSavePreferences from '../../hooks/hiro/useSavePreferences';
import { ColorMode } from '../../theme';
import {
  AccountDetails,
  PreferencesTab,
  TimezoneOption,
  Watchlist,
} from 'types';
import {
  EDITABLE_ACCOUNT_DETAILS,
  PREFERENCES_LABEL_MAPPING,
} from 'config/user';
import { TabContext, TabPanel } from '@mui/lab';
import { Loader, Tabs } from 'components';
import { formatToLLDate, getAllTimezones, isBloomberg } from '../../util';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EditWatchlists } from '../../components/watchlist/EditWatchlists';
import { EditAlertsSettings } from '../../components/core/alerts/EditAlertsSettings';
import useAlerts from '../../hooks/alerts/useAlerts';
import useWatchlists from '../../hooks/useWatchlists';
import useUserDetails from '../../hooks/user/useUserDetails';
import useToast from '../../hooks/useToast';
import { useCookies } from 'react-cookie';

dayjs.extend(utc);
dayjs.extend(timezone);

const getTimezoneOptionLabel = (now: dayjs.Dayjs, timezone: string): string => {
  const tzTime = dayjs(now).tz(timezone);
  const time = tzTime.format('HH:mm');
  return `${timezone} (${tzTime.format('z')}) - time: ${time}`;
};

const getTimezoneOptions = () => {
  return getAllTimezones().map(
    (zone: string): TimezoneOption => ({
      value: zone,
      label: getTimezoneOptionLabel(dayjs(), zone),
    }),
  );
};

const MEMBERSHIP_ID_MAPPING = require('../memberships.json');

export const Preferences = () => {
  const serverPositiveTrendColor = useRecoilValue(positiveTrendColorState);
  const serverNegativeTrendColor = useRecoilValue(negativeTrendColorState);
  const serverColorMode = useRecoilValue(colorModeState);
  const serverAccountDetails = useRecoilValue(accountDetailsState);
  const [watchlists, setWatchlists] = useRecoilState(watchlistsState);

  const [cookies, setCookie, removeCookie] = useCookies(['disableRedirect']);

  const [tab, setTab] = useState<PreferencesTab>(PreferencesTab.Account);
  const currentTimezone = useRecoilValue(timezoneState);
  const currentTZOption = {
    label: getTimezoneOptionLabel(dayjs(), currentTimezone),
    value: currentTimezone,
  };
  const [timezone, setTimezone] = useState<TimezoneOption>(currentTZOption);
  const [timezoneOptions, setTimezoneOptions] = useState<TimezoneOption[]>(
    getTimezoneOptions(),
  );
  const { saveAlertsSettings } = useAlerts();
  const { saveWatchlists } = useWatchlists();

  const handleTimezoneSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    newOption: TimezoneOption | null,
  ) => {
    setTimezone(
      newOption ?? {
        ...currentTZOption,
        label: getTimezoneOptionLabel(dayjs(), currentTimezone),
      },
    );
  };
  const { saveSgSettings } = useUserDetails();
  const userDetails = useRecoilValue(userDetailsState);
  const isMobile = useRecoilValue(isMobileState);
  const [positiveTrendColor, setPositiveTrendColor] = useState<string>(
    serverPositiveTrendColor,
  );
  const [negativeTrendColor, setNegativeTrendColor] = useState<string>(
    serverNegativeTrendColor,
  );
  const [colorMode, setColorMode] = useState<string>(serverColorMode);
  const [accountDetails, setAccountDetails] = useState<
    AccountDetails | undefined
  >(serverAccountDetails);
  const [savePreferencesLoading, setSavePreferencesLoading] = useRecoilState(
    savePreferencesLoadingState,
  );
  const [alertsSaveData, setAlertsSaveData] = useState<Watchlist[]>([]);
  const [watchlistSaveData, setWatchlistSaveData] = useState<Watchlist[]>(
    watchlists ?? [],
  );
  const { saveWordpressPreferences } = useSavePreferences();
  const { openToast } = useToast();

  const navigate = useNavigate();
  const theme = useTheme();
  const [_, setIsLoading] = useState<boolean>(false);
  const playAlertAudio = useRecoilValue(playAlertAudioState);

  useEffect(() => {
    // Update timezones to use up to date times for display
    const updateTimezones = () => {
      const updatedOptions: TimezoneOption[] = getTimezoneOptions();
      setTimezoneOptions(updatedOptions);
      setTimezone((prevTZ: TimezoneOption) => ({
        ...prevTZ,
        label: getTimezoneOptionLabel(dayjs(), prevTZ.value),
      }));
    };

    const intervalId = setInterval(updateTimezones, 60 * 1000); // 60000ms is 1 minute

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (serverAccountDetails) {
        setAccountDetails(serverAccountDetails);
      }
      setPositiveTrendColor(serverPositiveTrendColor);
      setNegativeTrendColor(serverNegativeTrendColor);
      setColorMode(serverColorMode);
      setIsLoading(false);
    }
    fetchData();
  }, [
    userDetails,
    serverAccountDetails,
    serverColorMode,
    serverNegativeTrendColor,
    serverPositiveTrendColor,
  ]);

  const onSave = useCallback(() => {
    async function saveData() {
      setSavePreferencesLoading(true);
      const [{ data, error }, sgSettingsResponse] = await Promise.all([
        saveWordpressPreferences(accountDetails),
        saveSgSettings(
          {
            colors: {
              positiveTrend: positiveTrendColor,
              negativeTrend: negativeTrendColor,
              colorMode: colorMode as ColorMode,
            },
            timezone: timezone.value,
          },
          true,
        ),
        alertsSaveData.length > 0
          ? saveAlertsSettings(alertsSaveData)
          : Promise.resolve(),
      ]);
      setSavePreferencesLoading(false);
      const validWp = data != null || isBloomberg();
      const validSgSettings =
        sgSettingsResponse != null && sgSettingsResponse?.error == null;
      if (validSgSettings && validWp) {
        openToast({
          message: 'Successfully updated preferences.',
          type: 'success',
        });
      } else {
        openToast({
          message: error ?? 'There was an error. Please try again later',
          type: 'error',
        });
        return;
      }
      const response = await saveWatchlists(watchlistSaveData);
      if (Array.isArray(response.watchlists)) {
        setWatchlists(response.watchlists);
      }
    }
    saveData();
  }, [
    timezone,
    accountDetails,
    navigate,
    negativeTrendColor,
    positiveTrendColor,
    colorMode,
    setSavePreferencesLoading,
    saveSgSettings,
    playAlertAudio,
    watchlistSaveData,
    alertsSaveData,
  ]);

  const resetColors = useCallback(() => {
    setPositiveTrendColor(serverPositiveTrendColor);
    setNegativeTrendColor(serverNegativeTrendColor);
  }, [
    setPositiveTrendColor,
    setNegativeTrendColor,
    serverNegativeTrendColor,
    serverPositiveTrendColor,
  ]);

  const currentMemberships = useMemo(() => {
    return (userDetails?.wordpress?.active_memberships ?? []).map(
      (m: { title: string }) => m.title,
    );
  }, [userDetails]);

  const toggleRedirect = () => {
    if (!cookies.disableRedirect) {
      setCookie('disableRedirect', 'true', {
        path: '/',
        domain: '.spotgamma.com',
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000), // 24 hours
        secure: true,
        sameSite: 'none', // necessary if cookies need to be sent in cross-site requests
      });
    } else {
      removeCookie('disableRedirect', {
        path: '/',
        domain: '.spotgamma.com',
        secure: true,
        sameSite: 'none',
      });
    }
  };

  return (
    <Loader isLoading={userDetails == null || accountDetails == null}>
      <Stack
        gap={5}
        sx={{
          ...(isMobile
            ? {
                paddingBottom: '70px !important', // support button
              }
            : {}),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: theme.spacing(5),
            background: theme.palette.background.paper,
            boxShadow: theme.palette.shadows.paperBoxShadow,
            borderRadius: theme.spacing(3),
            overflow: 'auto',
          }}
        >
          <TabContext value={tab}>
            <Tabs
              options={PREFERENCES_LABEL_MAPPING}
              onChange={(_evt, tab: string) => {
                setTab(tab as PreferencesTab);
              }}
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                overflowX: 'auto',
                ...(isMobile ? { maxHeight: 'calc(100% - 150px)' } : {}),
              }}
            >
              <TabPanel value={PreferencesTab.Account as string}>
                <Box
                  sx={{
                    transition: '0.5s',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%',
                    gap: theme.spacing(8),
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    marginTop={theme.spacing(3)}
                    gap={theme.spacing(8)}
                  >
                    {!isBloomberg() && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: theme.spacing(3),
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="6px"
                          alignItems="baseline"
                        >
                          <Typography variant="h4" gutterBottom color="primary">
                            Current Subscription
                          </Typography>
                          <Button
                            key="reset-colors-button"
                            variant="text"
                            sx={{
                              fontFamily: 'SF Pro Display',
                              fontSize: 11,
                              textTransform: 'capitalize',
                              color: theme.palette.grey[500],
                              p: 1,
                            }}
                            href="https://spotgamma.com/account/"
                          >
                            Update Your Subscription
                          </Button>
                        </Box>

                        <Box>
                          {currentMemberships?.map((m) => (
                            <Chip
                              key={`membership-${m}`}
                              label={m}
                              sx={{ color: 'white' }}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}

                    <Autocomplete
                      options={timezoneOptions.sort(
                        (a, b) => -b.label.localeCompare(a.label),
                      )}
                      groupBy={(option: TimezoneOption) =>
                        option.label.split('/')[0]
                      }
                      value={timezone}
                      onChange={handleTimezoneSelect}
                      getOptionLabel={(option: TimezoneOption) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a timezone"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            style: {
                              color: theme.palette.primary.main,
                            },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // Disable autocomplete and autofill
                          }}
                        />
                      )}
                      renderGroup={(params: AutocompleteRenderGroupParams) => [
                        <li key={params.key}>
                          <ul style={{ padding: 0 }}>
                            <li
                              style={{
                                backgroundColor: theme.palette.background.paper,
                              }}
                            >
                              <strong>{params.group}</strong>
                            </li>
                            {params.children}
                          </ul>
                        </li>,
                      ]}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{
                            backgroundColor: theme.palette.background.paper,
                          }}
                        >
                          {children}
                        </Paper>
                      )}
                      componentsProps={{
                        clearIndicator: {
                          style: {
                            color: theme.palette.primary.main,
                          },
                        },
                        popupIndicator: {
                          style: {
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{
                        width: '50%',
                      }}
                    />
                  </Stack>

                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
                  >
                    {EDITABLE_ACCOUNT_DETAILS.map(({ field, label, type }) => (
                      <Box
                        key={`account-details-${field}`}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography variant="h4" gutterBottom color="primary">
                          {label}
                        </Typography>
                        <TextField
                          id={field}
                          type={type}
                          value={accountDetails![field as keyof AccountDetails]}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            accountDetails &&
                              setAccountDetails({
                                ...accountDetails,
                                [field]: event.target.value,
                              });
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={PreferencesTab.Colors as string}>
                <Box
                  sx={{
                    transition: '0.5s',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%',
                    gap: theme.spacing(5),
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="baseline" py={2}>
                      <Typography variant="h3" gutterBottom color="primary">
                        Colors
                      </Typography>
                      <Button
                        key="reset-colors-button"
                        variant="text"
                        sx={{
                          fontFamily: 'SF Pro Display',
                          fontSize: 11,
                          textTransform: 'capitalize',
                          color: theme.palette.grey[500],
                          p: 1,
                        }}
                        onClick={resetColors}
                      >
                        Reset All
                      </Button>
                    </Box>

                    <Box display="flex" gap="12px">
                      <Box display="flex" flexDirection="column" gap="6px">
                        <Typography variant="h4" gutterBottom color="primary">
                          Positive Trend
                        </Typography>
                        <MuiColorInput
                          format="hex"
                          value={positiveTrendColor!}
                          onChange={(value: string) => {
                            setPositiveTrendColor(value);
                          }}
                        />
                      </Box>
                      <Box display="flex" flexDirection="column" gap="6px">
                        <Typography variant="h4" gutterBottom color="primary">
                          Negative Trend
                        </Typography>
                        <MuiColorInput
                          format="hex"
                          value={negativeTrendColor!}
                          onChange={(value: string) => {
                            setNegativeTrendColor(value);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Typography variant="h4" gutterBottom color="primary">
                    Color Mode
                  </Typography>
                  <FormControl>
                    <Select
                      value={colorMode}
                      displayEmpty
                      onChange={(event: SelectChangeEvent) => {
                        setColorMode(event.target.value as string);
                      }}
                    >
                      {Object.values(ColorMode).map((mode) => {
                        return (
                          <MenuItem key={`color-mode-${mode}`} value={mode}>
                            {mode}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </TabPanel>
              <TabPanel value={PreferencesTab.Payments as string}>
                <Box
                  sx={{
                    transition: '0.5s',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%',
                    gap: theme.spacing(5),
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Membership</TableCell>
                        <TableCell align="right">Method</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Invoice</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(userDetails?.wordpress?.recent_transactions ?? []).map(
                        (transaction, idx) => (
                          <TableRow
                            key={`wordpress-transaction-${idx}`}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {formatToLLDate(transaction.created_at)}
                            </TableCell>
                            <TableCell align="right">
                              {transaction.total === '0.0'
                                ? 'Free'
                                : transaction.total}
                            </TableCell>
                            <TableCell align="right">
                              {MEMBERSHIP_ID_MAPPING[transaction.membership]}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {transaction.gateway}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {transaction.status}
                            </TableCell>
                            <TableCell align="right">
                              {transaction.trans_num}
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </TabPanel>
              <TabPanel value={PreferencesTab.Alerts as string}>
                <EditAlertsSettings
                  modal={false}
                  onChange={(data) => setAlertsSaveData(data)}
                />
              </TabPanel>
              <TabPanel value={PreferencesTab.Watchlists as string}>
                <EditWatchlists
                  modal={false}
                  onChange={(data) => setWatchlistSaveData(data)}
                />
              </TabPanel>
            </Box>
          </TabContext>

          {tab !== PreferencesTab.Payments && (
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <LoadingButton
                type="submit"
                onClick={onSave}
                loading={savePreferencesLoading}
                sx={{
                  fontSize: '14px',
                  backgroundColor: theme.palette.primary.main,
                  textTransform: 'capitalize',
                }}
                loadingIndicator={<CircularProgress color="info" size={16} />}
                variant="contained"
              >
                Save Preferences
              </LoadingButton>
            </Box>
          )}
        </Box>
        {userDetails?.wordpress?.profile.mepr_modern_dashboard_access ===
          'beta' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: theme.spacing(5),
              background: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
              borderRadius: theme.spacing(3),
              overflow: 'auto',
            }}
          >
            <Typography>Beta Settings</Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={!cookies.disableRedirect}
                  onChange={toggleRedirect}
                  name="disableRedirect"
                  color="primary"
                />
              }
              label={
                <ListItemText
                  primary="Redirect to Staging"
                  secondary="Can only be disabled for 24 hours"
                  secondaryTypographyProps={{
                    fontSize: 11,
                  }}
                />
              }
            />
          </Box>
        )}
      </Stack>
    </Loader>
  );
};
