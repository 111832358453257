import useAuth from 'hooks/auth/useAuth';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState, useRecoilValue } from 'recoil';
import { loginErrorMessageState, userDetailsState } from 'states';
import { isBBEnvAvailable } from 'util/bloomberg';
import { getAppcues, getHasLoggedIn } from 'util/shared';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from './Link';

interface LogoutLinkProps {
  onClickCallback?: () => void;
}

const LogoutLink = ({ onClickCallback }: LogoutLinkProps) => {
  const resetLoginErrorMessage = useResetRecoilState(loginErrorMessageState);
  const navigate = useNavigate();
  const userDetails = useRecoilValue(userDetailsState);
  const { setTokensAndUserDetails } = useAuth();

  return !isBBEnvAvailable() ? (
    userDetails == null ? (
      <Link
        isLabelShown
        onClick={() => {
          onClickCallback?.();
          navigate('/login');
        }}
        to="/login"
        text="Login"
        icon={<LoginIcon width="24px" />}
      />
    ) : (
      <Link
        isLabelShown
        onClick={() => {
          setTokensAndUserDetails(null, null);
          resetLoginErrorMessage();
          getAppcues().reset();
          // If user has already logged in
          // logout will direct them back to login page
          onClickCallback?.();
          if (getHasLoggedIn()) {
            navigate('/login');
          }
        }}
        to="/home"
        text="Logout"
        disableActive={true}
        icon={<LogoutIcon width="24px" />}
      />
    )
  ) : null;
};

export default LogoutLink;
