import { useCallback, useEffect, useMemo } from 'react';
import { HiroOverview, ProductType, RawHiroOverview } from '../../types';
import { useSetRecoilState, useRecoilValue, useRecoilCallback } from 'recoil';
import {
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
  productAccessState,
  workerState,
} from '../../states';
import { isZerohedge } from '../../util';
import useLog from '../useLog';
import poll from 'util/poll';

const DELTA = Math.exp(-40);
const POLL_INTERVAL = 30_000; // 30s

function transformData(data: any) {
  if (data?.error != null) {
    return { transformedData: [], error: data?.error };
  }
  const transformedData = data.map((entry: RawHiroOverview) => {
    const price =
      entry.currentDayPrice != null ? parseFloat(entry.currentDayPrice) : null;
    const lastClose = entry.lastClose;
    return {
      signal:
        entry.currentDaySignal != null
          ? parseFloat(entry.currentDaySignal)
          : null,
      instrument: entry.symbol,
      companyName: entry.companyName,
      '1 day': {
        low: entry.low1,
        current: entry.currentDaySignal,
        high: entry.high1,
      },
      '5 day': {
        low: entry.low5,
        high: entry.high5,
      },
      '30 day': {
        low: entry.low20,
        high: entry.high20,
      },
      price,
      lastClose,
      priceChange:
        price && lastClose
          ? ((price - lastClose) / (price + DELTA)) * 100
          : null,
      sector: entry.sector,
      live: entry.live,
    };
  });
  return { transformedData };
}

const useHiroList = () => {
  const setStocks = useSetRecoilState(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const worker = useRecoilValue(workerState);
  const products = useRecoilValue(productAccessState);
  const hasAccess = useMemo(
    () => products.includes(ProductType.HIRO) && !isZerohedge(),
    [products],
  );
  const { logError, fetchAPIWithLog } = useLog('useHiroList');

  const url = useMemo(
    () => (hasAccess ? `v6/running_hiro` : `v1/free_running_hiro`),
    [hasAccess],
  );

  const getHiroList = useCallback(async () => {
    const data = await fetchAPIWithLog(url);
    return transformData(data);
    // `fetchHiroImpl` is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const setHiroEntries = useCallback(
    (transformedData: any) => {
      const toEntry = (d: HiroOverview) => [d.instrument.toUpperCase(), d];
      const entries = transformedData.map(toEntry);
      setStocks(new Map(entries));
    },
    [setStocks],
  );

  const fetchHiroImpl = async () => {
    const { transformedData } = await getHiroList();
    setHiroEntries(transformedData);
  };

  useEffect(() => {
    fetchHiroImpl(); // Refetch and reload the HIRO list on any access changes
    // Making `fetchHiroImpl` a dependency causes infinite fetches
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccess]);

  const fetchHiroStocks = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const stocks = await snapshot.getPromise(
          stocksState_SUBSCRIBE_TO_POLLING_RENDER,
        );
        if (stocks.size > 0) {
          return stocks;
        }
        fetchHiroImpl();
      },
    // `fetchHiroImpl` is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleResponse = useCallback(
    ({ json }: { json: any }) => {
      const { transformedData, error } = transformData(json);
      if (error != null) {
        logError(error, 'handleResponse');
        return;
      }
      setHiroEntries(transformedData);
    },
    [setHiroEntries],
  );

  return { getHiroList, fetchHiroStocks, handleResponse, url, POLL_INTERVAL };
};

export default useHiroList;
