import { Box, IconButton, Slider, Stack } from '@mui/material';
import { SGTooltip } from '../../components/core';
import {
  ArrowBack,
  ArrowForward,
  FastForward,
  FastRewind,
  PlayArrow,
  Stop,
} from '@mui/icons-material';
import { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isMobileState,
  oiIntradayFilterPrice,
  oiIntradayTimestampState,
} from '../../states';
import { getOiTicks } from '../../util';
import dayjs, { Dayjs } from 'dayjs';

type TraceTimeSliderProps = {
  timestamps: dayjs.Dayjs[];
  chartWidth: number;
};

const PLAY_INTERVAL_MS = 600;

export const TraceTimeSlider = ({
  timestamps,
  chartWidth,
}: TraceTimeSliderProps) => {
  const [timestamp, setTimestamp] = useRecoilState<Dayjs | null>(
    oiIntradayTimestampState,
  );
  const isMobile = useRecoilValue(isMobileState);
  const setFilterPrice = useSetRecoilState(oiIntradayFilterPrice);

  const [playingInterval, setPlayingInterval] = useState<
    NodeJS.Timer | undefined
  >();
  const playingIntervalRef = useRef<number>(0);

  const timeMarks = getOiTicks(timestamps, chartWidth);

  let selectedTimestampIndex = timestamps.length - 1;
  for (let i = timestamps.length - 1; i >= 0; i--) {
    if (timestamp?.isSame(timestamps[i], 'second')) {
      selectedTimestampIndex = i;
      break;
    }
  }

  const horizGap = isMobile ? '5px' : '25px';
  const showLatestButton = selectedTimestampIndex !== timestamps.length - 1;
  const showBack = selectedTimestampIndex > 0;
  const showForward = selectedTimestampIndex < timestamps.length - 1;

  const handleTimeChange = (
    _evt: Event,
    idx: number | number[],
    _activeThumb: number,
  ) => {
    if (!Array.isArray(idx)) {
      setTimestamp(timestamps[idx]);
    }
  };

  const jumpToLatest = () => {
    setTimestamp(timestamps[timestamps.length - 1]);
  };

  const jumpToEarliest = () => {
    setTimestamp(timestamps[0]);
  };

  const playOrStop = () => {
    if (playingInterval != null) {
      clearInterval(playingInterval);
      setPlayingInterval(undefined);
    } else {
      // without filterPrice = true playing doesnt do anything
      setFilterPrice(true);
      playingIntervalRef.current = selectedTimestampIndex;
      const newInterval = setInterval(() => {
        const newIndex = playingIntervalRef.current + 1;
        setTimestamp(timestamps[newIndex]);
        playingIntervalRef.current = newIndex;
        if (newIndex === timestamps.length - 1) {
          clearInterval(newInterval);
          setPlayingInterval(undefined);
        }
      }, PLAY_INTERVAL_MS);
      setPlayingInterval(newInterval);
    }
  };

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      gap={horizGap}
      marginY={isMobile ? 0 : '10px'}
    >
      <Stack direction="row" width={1} gap={'5px'}>
        <Stack direction="row" width={1} gap="3px">
          <Box visibility={showBack ? 'visible' : 'hidden'}>
            <SGTooltip title={'Rewind to earliest timestamp'}>
              <IconButton
                onClick={jumpToEarliest}
                sx={{ position: 'relative', top: '-4px' }}
              >
                <FastRewind color="primary" />
              </IconButton>
            </SGTooltip>
          </Box>
          <Box visibility={showBack ? 'visible' : 'hidden'}>
            <SGTooltip title={'Go back one timestamp interval'}>
              <IconButton
                onClick={() =>
                  setTimestamp(timestamps[selectedTimestampIndex - 1])
                }
                sx={{
                  position: 'relative',
                  top: '-2px',
                  width: '18px',
                }}
              >
                <ArrowBack color="primary" fontSize="small" />
              </IconButton>
            </SGTooltip>
          </Box>
          <Slider
            aria-label="timestamp"
            marks={timeMarks}
            valueLabelDisplay={isMobile ? 'auto' : 'on'}
            valueLabelFormat={(val: number) =>
              timestamps[val].format('H:mm:ss')
            }
            min={0}
            max={timestamps.length - 1}
            onChange={handleTimeChange}
            value={selectedTimestampIndex}
            getAriaValueText={(val: number) =>
              timestamps[val].format('H:mm:ss')
            }
          />
          <Box visibility={showForward ? 'visible' : 'hidden'}>
            <SGTooltip title={'Go forward one timestamp interval'}>
              <IconButton
                onClick={() =>
                  setTimestamp(timestamps[selectedTimestampIndex + 1])
                }
                sx={{
                  position: 'relative',
                  top: '-2px',
                  width: '22px',
                }}
              >
                <ArrowForward color="primary" fontSize="small" />
              </IconButton>
            </SGTooltip>
          </Box>
        </Stack>
        <Box visibility={showLatestButton ? 'visible' : 'hidden'}>
          <SGTooltip title={'Jump to latest update'}>
            <IconButton
              onClick={jumpToLatest}
              sx={{ position: 'relative', top: '-4px' }}
            >
              <FastForward color="primary" />
            </IconButton>
          </SGTooltip>
        </Box>
        <Box visibility={showLatestButton ? 'visible' : 'hidden'}>
          <SGTooltip
            title={
              playingInterval == null ? "Play the day's moves" : 'Stop playing'
            }
          >
            <IconButton
              onClick={playOrStop}
              sx={{ position: 'relative', top: '-4px', width: '20px' }}
            >
              {playingInterval == null ? (
                <PlayArrow color="primary" />
              ) : (
                <Stop color="primary" />
              )}
            </IconButton>
          </SGTooltip>
        </Box>
      </Stack>
    </Stack>
  );
};
