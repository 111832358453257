import { EquityColumnGroup } from '../../types/equityhub';
import { OCCCategory } from '../../types/sentiment';

export const colorBlindModeTheme = {
  palette: {
    sgGreen: '#00BBAA',
    background: {
      default: '#000000',
      paper: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    gray: '#87888a',
    shadows: {
      default: '1px 1px 3px #bdbdbd',
      paperBoxShadow: '1px 1px 3px #bdbdbd',
    },
    button: {
      default: '#117733',
      hover: '#148e3d',
    },
    core: {
      price: '#7c94eb',
      put: '#1f77b4',
      call: '#ff7f0e',
      translucentBG: 'rgba(220, 220, 220, 0.8)',
    },
    equityHub: {
      fieldColorMapping: {
        upx: '#117733',
        maxfs: '#332288',
        keyg: '#44AA99',
        keyd: '#88CCEE',
        putctrl: '#DDCC77',
        cws: '#CC6677',
        pws: '#AA4499',
      },
      skew: {
        allFirst: '#88CCEE',
        allSecond: '#44AA99',
        nextExpFirst: '#332288',
        nextExpSecond: '#117733',
      },
    },
    equityHubColumns: {
      [EquityColumnGroup.STATISTICS]: '#FFFFFF',
      [EquityColumnGroup.SG_KEY_DAILY_LEVELS]: '#5dd4c2',
      [EquityColumnGroup.DIRECTIONAL_INDICATORS]: '#d1d1d1',
      [EquityColumnGroup.VOLATILITY_INSIGHTS]: '#dbc270',
      [EquityColumnGroup.DARK_POOL_INDICATORS]: '#d97f5d',
    },
    scannerGrid: {
      mainHeader: '#1C1E21',
      pinnedColumn: '#1C1E21',
      pinnedRow: '#133d3b',
    },
    alerts: {
      hiroReversionTop: '#b5674a',
      hiroReversionBottom: '#18bdac',
    },
    hiro: {
      signalBg: '#282c33',
      flow: '#88CCEE',
      indices: {
        SPY: '#117733',
        QQQ: '#88CCEE',
        IWM: '#DDCC77',
      },
      bottomCandles: {
        absolute: '#939393',
      },
      lenses: {
        all: {
          call: '#DDCC77',
          put: '#332288',
          total: '#44AA99',
        },
        retail: {
          call: '#117733',
          put: '#882255',
          total: '#AA4499',
        },
        nextExp: {
          call: '#CC6677',
          put: '#ffffff',
          total: '#88CCEE',
        },
        price: {
          total: '#ffffff',
        },
      },
      priceLines: {
        upx: '#a0a0a0',
        keyg: '#83deff',
        keyd: '#8ea3de',
        cws: '#3aa0ff',
        pws: '#c88070',
        maxfs: '#dd3333',
        sig_high: '#913160',
        sig_low: '#913160',
      },
      grid: {
        lineColor: '#404040',
      },
    },
    sentiment: {
      occ: {
        [OCCCategory.EQUITY_CALLS]: '#e377c2',
        [OCCCategory.EQUITY_PUTS]: '#7f7f7f',
        [OCCCategory.ETF_CALLS]: '#bcbd22',
        [OCCCategory.ETF_PUTS]: '#17becf',
        [OCCCategory.INDEX_CALLS]: '#1f77b4',
        [OCCCategory.INDEX_PUTS]: '#ff7f0e',
      },
      historical: {
        upx: '#a0a0a0',
        max_g_strike: '#a1772a',
        put_wall_strike: '#307b8a',
        call_wall_strike: '#913160',
        gamma_index: '#3aa0ff',
      },
    },
    indices: {
      combos: {
        gamma: '#88CCEE',
      },
      delta: {
        all: '#b8b8b8',
        nextExpMonthly: '#ffe0a1',
        nextExp: '#ffb4a1',
      },
      gammaVanna: {
        gamma: '#2d876d',
        vanna: '#653285',
        delta: '#785329',
        volforecast: '#358c4c',
        nextExp: '#737373',
      },
      tilt: {
        upx: '#919191',
        gamma: '#0000FF',
        delta: '#0000FF',
      },
      zeroDte: {
        volume: '#a82aff',
        oi: '#83e6ff',
        oi_avg: '#FF0000',
      },
      equityRatio: {
        volume: '#383838',
        oi: '#166d8a',
      },
      vol: {
        five_day_vol: '#FF0000',
        one_month_vol: '#00FF00',
        two_month_vol: '#0000FF',
        three_month_vol: '#FFA500',
        six_month_vol: '#000000',
      },
      maxVol: {
        price: '#000000',
        two_six_month: '#653285',
        one_three_month: '#785329',
      },
    },
    iVol: {
      termStructure: {
        date0: '#a0a0a0',
        date1: '#83deff',
        date2: '#8ea3de',
        date3: '#3aa0ff',
        date4: '#c88070',
        date5: '#dd3333',
      },
      volSkew: {
        date0: '#a0a0a0',
        date1: '#83deff',
        date2: '#8ea3de',
        date3: '#3aa0ff',
        date4: '#c88070',
        date5: '#dd3333',
      },
      fixedStrikeMatrix: {
        grayCell: '#BABBBC',
        lowValueCell: '#000000',
        highValueCell: '#18BDAC',
        highlight: '#f5f708',
      },
    },
    resources: {
      earnings: {
        bmo: '#27b0e6',
        amc: '#5814b8',
        other: '#4a4a4a',
        label: '#aaaaaa',
      },
    },
    compass: {
      buttonColor: '#3aa0ff',
    },
  },
};
