import { CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GridTableOverlay from 'components/shared/GridTableOverlay';

interface WrapperProps {
  error?: boolean;
}

const GridTableOverlayWrapper = (props: WrapperProps) => {
  const { error } = props;

  return (
    <GridTableOverlay
      icon={error ? <ErrorOutlineIcon /> : <CircularProgress />}
      text={
        error
          ? 'Something went wrong while fetching data...'
          : 'Waiting for data...'
      }
    />
  );
};

export default GridTableOverlayWrapper;
