import { useEffect, useState, useRef } from 'react';
import { getDateFormatted, getUtcYMD } from 'util/shared/date';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Brush,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import useEquityPCRatio from 'hooks/sentiment/useEquityPCRatio';
import {
  EquityPCRatio,
  IndexSymbol,
  RawEquityPCRatio,
  SentimentTab,
} from '../../types';
import { formatAsCompactNumber, formatAsCurrency } from '../../util';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y2_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../config';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  equityPCChartZoomConfigState,
  equityPCInitialDataState,
  priceDataState,
  timezoneState,
} from 'states';
import {
  ExpandableContentButton,
  InfoButton,
  Loader,
  ZoomOutButton,
} from 'components/shared';
import { getZoomConfigRefArea } from 'util/shared/chart';
import useBrushZoom from 'hooks/useBrushZoom';
import useEndOfDay from 'hooks/sentiment/useEOD';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';

export const EquityPCRatioChart = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useRecoilState(
    equityPCInitialDataState,
  );
  const [zoomConfig, setZoomConfig] = useRecoilState(
    equityPCChartZoomConfigState,
  );
  const [priceData, setPriceData] = useRecoilState(priceDataState);
  const { getEquityPCRatio } = useEquityPCRatio();
  const { getEndOfDay } = useEndOfDay();
  const currentTimezone = useRecoilValue(timezoneState);

  const { zoomChartConfig } = useBrushZoom<EquityPCRatio>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  useEffect(() => {
    async function generatePrices() {
      setIsLoading(true);
      let data = await getEndOfDay([IndexSymbol.SPX]);
      const values = data[IndexSymbol.SPX].values;

      if (values == null) {
        return;
      }
      const ticks: Map<string, number> = new Map(
        values
          .filter((v: any) => v.close != null)
          .map((v: any) => [
            getDateFormatted(dayjs.tz(v.datetime, currentTimezone)),
            parseFloat(v.close),
          ]),
      );
      setIsLoading(false);
      setPriceData(ticks);
    }
    if (!priceData) {
      generatePrices();
    }
  }, [getEndOfDay, setPriceData, currentTimezone]);

  useEffect(() => {
    async function generateRatios() {
      setIsLoading(true);
      let data = await getEquityPCRatio();
      const transformedData = data
        .map(({ trade_date, ...rest }: RawEquityPCRatio) => ({
          epoch_millis: dayjs(trade_date).valueOf(),
          ...rest,
          price: priceData?.get(getDateFormatted(trade_date)),
        }))
        .sort(
          (a: EquityPCRatio, b: EquityPCRatio) =>
            a.epoch_millis - b.epoch_millis,
        );
      setInitialData(transformedData);
      setZoomConfig((prev) => ({ ...prev, data: transformedData }));
      setIsLoading(false);
    }
    if (priceData) {
      generateRatios();
    }
  }, [getEquityPCRatio, priceData, setInitialData, setZoomConfig]);

  return (
    <Loader isLoading={isLoading}>
      <Stack
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          gap={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          <ZoomOutButton
            key="zoom"
            zoomConfig={zoomConfig}
            setZoomConfig={setZoomConfig}
            initialData={initialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: initialData.length - 1,
            }}
          />
          <InfoButton
            key={`${SentimentTab.EquityPutCallRatio}-info`}
            articleKey={SentimentTab.EquityPutCallRatio}
          />
          <ExpandableContentButton type={SentimentTab.EquityPutCallRatio} />
        </Stack>
        <ChartWatermarkContainer ref={ref} size={25} offsetX={50} offsetY={50}>
          <ResponsiveContainer>
            <LineChart margin={DEFAULT_CHART_MARGINS} {...zoomChartConfig}>
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                allowDataOverflow
                label={{
                  ...DEFAULT_X_AXIS_STYLES,
                  value: 'Trade Date',
                  offset: 3,
                }}
                dataKey="epoch_millis"
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 10 }}
                tickFormatter={getUtcYMD}
                type="number"
                tickCount={15}
              />
              <Brush
                dataKey="epoch_millis"
                tickFormatter={getUtcYMD}
                startIndex={zoomConfig.leftIdx}
                endIndex={zoomConfig.rightIdx}
                onChange={(brushIndices: any) =>
                  setZoomConfig((prev) => ({
                    ...prev,
                    leftIdx: brushIndices.startIndex,
                    rightIdx: brushIndices.endIndex,
                  }))
                }
                height={25}
                travellerWidth={15}
                stroke={theme.palette.gray}
                fill={theme.palette.background.paper}
                alwaysShowText
              />
              <YAxis
                allowDataOverflow
                yAxisId="left"
                domain={['dataMin', 'dataMax']}
                tickFormatter={formatAsCompactNumber}
                tick={{ fontSize: 11 }}
                type="number"
                label={{
                  value: 'Put/Call Ratio',
                  ...DEFAULT_Y_AXIS_STYLES,
                }}
              />
              <YAxis
                allowDataOverflow
                yAxisId="right"
                orientation="right"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(value: number) =>
                  `$${formatAsCompactNumber(value)}`
                }
                tick={{ fontSize: 11 }}
                type="number"
                label={{
                  value: 'SPX Price',
                  ...DEFAULT_Y2_AXIS_STYLES,
                }}
              />
              <Tooltip
                isAnimationActive={false}
                formatter={(v: string, name: string) =>
                  name === 'Price'
                    ? formatAsCurrency(v)
                    : parseFloat(v).toLocaleString()
                }
                labelFormatter={getUtcYMD}
                itemStyle={{ fontSize: '11px' }}
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              <Line
                yAxisId="left"
                dataKey="vol_ratio"
                name="Volume Ratio"
                key="vol_ratio"
                dot={false}
                stroke={theme.palette.indices.equityRatio.volume}
              />
              <Line
                yAxisId="left"
                dataKey="oi_ratio"
                name="Open Interest Ratio"
                key="oi_ratio"
                dot={false}
                stroke={theme.palette.indices.equityRatio.oi}
              />
              <Line
                yAxisId="right"
                type="basis"
                dataKey="price"
                name="Price"
                stroke={theme.palette.core.price}
                dot={false}
                strokeWidth={1.25}
                connectNulls
              />
              {getZoomConfigRefArea(zoomConfig, 'left')}
            </LineChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      </Stack>
    </Loader>
  );
};
