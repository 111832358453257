import { useCallback } from 'react';
import { fetchAPI } from '../../util/shared/fetch';
import {
  businessDaysAdd,
  businessDaysSubtract,
  getQueryDate,
} from '../../util';
import { AdminContentCategory } from '../../types';
import useLog from '../useLog';

const useHomeContent = () => {
  const { fetchAPIWithLog } = useLog('useHomeContent');

  const getEarningsCalendar = useCallback(async () => {
    const today = getQueryDate(true);
    const start = businessDaysSubtract(today, 1).format('YYYY-MM-DD');
    const end = businessDaysAdd(today, 5).format('YYYY-MM-DD');
    const url = `v1/earnings?start=${start}&end=${end}`;
    return await fetchAPIWithLog(url);
  }, []);

  const getEarningsForSyms = useCallback(
    async (
      syms: string[],
      startDate = getQueryDate(true),
      endDate = businessDaysAdd(getQueryDate(true), 365),
    ) => {
      if (syms.length === 0) {
        return [];
      }

      const start = startDate.format('YYYY-MM-DD');
      const end = endDate.format('YYYY-MM-DD');
      const encodedSyms = encodeURIComponent(syms.join(','));
      const url = `v1/earnings?syms=${encodedSyms}&start=${start}&end=${end}`;
      return await fetchAPIWithLog(url);
    },
    [],
  );

  const getContentForSpecialCategory = useCallback(async (category: string) => {
    const url = `home/contentForCategory?category=${category}`;
    return await fetchAPIWithLog(url);
  }, []);

  const getTooltips = useCallback(async () => {
    return await getContentForSpecialCategory(AdminContentCategory.Tooltips);
  }, []);

  const getKeyLevelsData = useCallback(async (includeGammaCurve?: boolean) => {
    const url = `home/keyLevels?includeGammaCurve=${includeGammaCurve ? 1 : 0}`;
    return await fetchAPIWithLog(url);
  }, []);

  const getAllData = useCallback(async () => {
    return await fetchAPIWithLog(`home/allData`);
  }, []);

  return {
    getEarningsCalendar,
    getEarningsForSyms,
    getContentForSpecialCategory,
    getKeyLevelsData,
    getAllData,
    getTooltips,
  };
};

export default useHomeContent;
