import { Stack, Typography, useTheme } from '@mui/material';
import { SCANNER_DESCRIPTION_MAPPING, SCANNER_NAME_MAPPING } from 'config';
import { useRecoilValue } from 'recoil';
import { watchlistsState } from 'states';
import {
  activeScannerState,
  scannerActiveWatchlistsIdsState,
  scannerDisplayTypeState,
} from 'states/scanners';
import { ScannerDisplayType } from '../../types/scanners';

const ScannerInfoSection = () => {
  const theme = useTheme();

  const activeScanner = useRecoilValue(activeScannerState);
  const activeWatchlistsIds = useRecoilValue(scannerActiveWatchlistsIdsState);
  const watchlists = useRecoilValue(watchlistsState);
  const activeWatchlists =
    watchlists?.filter((w) => activeWatchlistsIds.includes(w.id as number)) ??
    [];
  const scannerDisplayType = useRecoilValue(scannerDisplayTypeState);

  if (scannerDisplayType === ScannerDisplayType.ScannersWithCompass) {
    return null;
  }

  const getDescription = () => {
    if (activeScanner) {
      return SCANNER_DESCRIPTION_MAPPING[activeScanner];
    } else if (activeWatchlistsIds.length > 0) {
      return 'List of names you want to highlight and track. Add symbols to your watchlists by going to preferences.';
    }

    return null;
  };

  return activeWatchlistsIds.length > 0 || activeScanner ? (
    <Stack gap={2}>
      <Typography
        variant="h2"
        sx={{
          color: theme.palette.text.primary,
          fontWeight: 500,
          fontSize: {
            xs: 16,
            md: 20,
          },
        }}
      >
        {activeScanner
          ? SCANNER_NAME_MAPPING[activeScanner]
          : activeWatchlists.map((w) => w.name).join(', ')}
      </Typography>
      {
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {getDescription()}
        </Typography>
      }
    </Stack>
  ) : null;
};

export default ScannerInfoSection;
