import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedEquityState, productAccessState } from 'states';
import { ProductType } from 'types';
import useLog from '../useLog';

const useRiskReversal = () => {
  const selectedEquity = useRecoilValue(selectedEquityState);
  const products = useRecoilValue(productAccessState);
  const hasAccess = useMemo(
    () => products.includes(ProductType.EQUITYHUB),
    [products],
  );
  const { fetchAPIWithLog } = useLog('useRiskRiversal');

  const getRiskReversal = useCallback(async () => {
    if (selectedEquity?.sym == null) {
      return null;
    }
    const sym = encodeURIComponent(selectedEquity!.sym);
    return await fetchAPIWithLog(
      `v1/${!hasAccess ? 'free_' : ''}rr?sym=${sym}`,
    );
  }, [hasAccess, selectedEquity]);

  return { getRiskReversal };
};

export default useRiskReversal;
