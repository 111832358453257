import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isMobileState } from '../../states';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { shadeColor } from '../../util';
import CloseIcon from '@mui/icons-material/Close';

type PageBannerProps = {
  message: string;
  onClick?: () => void;
  type?: PageBannerType;
};

export enum PageBannerType {
  Info,
  Error,
}

export const PageBanner = ({
  message,
  onClick,
  type = PageBannerType.Info,
}: PageBannerProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const isMobile = useRecoilValue(isMobileState);
  const bg =
    type === PageBannerType.Info
      ? shadeColor(theme.palette.primary.main, -50)
      : shadeColor(theme.palette.error.main, -30);

  return (
    <Collapse in={open}>
      <Box
        sx={{
          width: '100%',
          padding: isMobile ? '10px' : '15px',
          textAlign: 'center',
          background: bg,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          cursor: 'pointer',
          borderRadius: 1,
          position: 'relative',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              maxWidth: '80%',
              display: 'inline-block',
              color: '#fff',
            }}
            onClick={onClick}
          >
            {message}
          </Typography>
          <IconButton
            aria-label="close"
            sx={{
              color: theme.palette.text.primary,
              position: 'absolute',
              right: isMobile ? '10px' : '30px',
            }}
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
    </Collapse>
  );
};
