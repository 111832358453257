import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProductType } from '../../../types';
import { AlertCategory, AlertData, getDesc } from '../../../types';
import {
  productAccessState,
  negativeTrendColorState,
  positiveTrendColorState,
  timezoneState,
} from '../../../states';
import { useRecoilValue } from 'recoil';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { useCallback } from 'react';

function alertPassesThreshold(alert: AlertData) {
  return alert.payload.passed_vol_threshold !== false;
}

function getAuxiliaryIcon(alert: AlertData) {
  if (!alertPassesThreshold(alert)) {
    return null;
  }

  switch (alert.category) {
    case AlertCategory.HIRO_TOP_SIGNAL:
      return (
        <TrendingDownIcon
          style={{ transform: 'translateY(0.2em)', fontWeight: '700' }}
        />
      );
    case AlertCategory.HIRO_BOTTOM_SIGNAL:
      return (
        <TrendingUpIcon
          style={{ transform: 'translateY(0.2em)', fontWeight: '700' }}
        />
      );
    case AlertCategory.HIRO_FLOW_CLOSE_SELL:
    case AlertCategory.HIRO_FLOW_CLOSE_BUY:
      return (
        <StopCircleIcon
          style={{ transform: 'translateY(0.2em)', fontWeight: '700' }}
        />
      );
    default:
      return null;
  }
}

function getTimeString(alert: AlertData, timezone: string) {
  const date = new Date(alert.alert_time);
  return date.toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: timezone,
  });
}

export const onClick = (alert: AlertData, hasHiro: boolean, navigate: any) => {
  const alertTime = dayjs(alert.alert_time).valueOf();
  const sym = encodeURIComponent(alert.symbol);
  const hiroURL = `/${ProductType.HIRO}?sym=${sym}&zoomTo=${alertTime}`;
  switch (alert.category) {
    case AlertCategory.HIRO_TOP_SIGNAL:
    case AlertCategory.HIRO_BOTTOM_SIGNAL:
    case AlertCategory.HIRO_NEG_FLOW:
    case AlertCategory.HIRO_POS_FLOW:
    case AlertCategory.HIRO_FLOW_CLOSE_SELL:
    case AlertCategory.HIRO_FLOW_CLOSE_BUY:
      return navigate(hiroURL);
    case AlertCategory.ONE_PERCENT_CALL_WALL:
    case AlertCategory.ONE_PERCENT_PUT_WALL:
    case AlertCategory.CALL_WALL:
    case AlertCategory.PUT_WALL:
      const ehURL = `/${ProductType.EQUITYHUB}?sym=${sym}&tab=live_price`;
      // TODO: Check that symbol is supported in HIRO before navigating to
      // HIRO. Right now the allow-list for symbols applied to alerts
      // prevents this.
      return navigate(hasHiro ? hiroURL : ehURL);
  }
};

export const Alert = ({
  wide,
  alert,
  flatAndVisible,
  onClickDisabled,
}: {
  wide?: boolean;
  alert: AlertData;
  flatAndVisible?: boolean;
  onClickDisabled?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentTimezone = useRecoilValue(timezoneState);
  const negativeTrendColor = useRecoilValue(negativeTrendColorState);
  const positiveTrendColor = useRecoilValue(positiveTrendColorState);
  const products = useRecoilValue(productAccessState);

  let color = theme.palette.text.primary;
  switch (alert.category) {
    case AlertCategory.ONE_PERCENT_CALL_WALL:
    case AlertCategory.CALL_WALL:
    case AlertCategory.HIRO_BOTTOM_SIGNAL:
      color = positiveTrendColor;
      break;
    case AlertCategory.HIRO_TOP_SIGNAL:
    case AlertCategory.ONE_PERCENT_PUT_WALL:
    case AlertCategory.PUT_WALL:
      color = negativeTrendColor;
      break;
  }

  const onClickCb = useCallback(() => {
    const hasHiro = products.find((p) => p === ProductType.HIRO) != null;
    onClick(alert, hasHiro, navigate);
  }, [alert, products, navigate]);

  const opacity = flatAndVisible || alertPassesThreshold(alert) ? 1.0 : 0.5;
  const children = (
    <Box
      onClick={onClickDisabled ? undefined : onClickCb}
      padding={4}
      sx={{
        overflow: 'hidden',
        opacity,
        background: flatAndVisible ? 'none' : undefined,
      }}
    >
      <Box
        sx={{
          display: wide ? 'inline-block' : 'block',
          float: wide ? 'left' : 'none',
          verticalAlign: 'bottom',
        }}
        color={color}
      >
        <Box
          component="span"
          fontWeight="fontWeightBold"
          sx={{ verticalAlign: 'bottom' }}
        >
          {alert.symbol}
        </Box>
        <Box component="span">: {getDesc(alert.category)}</Box>
        <Box component="span" sx={{ marginLeft: '2px' }}>
          {getAuxiliaryIcon(alert)}
        </Box>
      </Box>
      <Box
        sx={{
          display: wide ? 'inline-block' : 'block',
          float: wide ? 'right' : 'none',
          verticalAlign: 'bottom',
          opacity: '0.8',
          fontSize: '13px',
        }}
      >
        {getTimeString(alert, currentTimezone)}
      </Box>
    </Box>
  );

  return flatAndVisible ? (
    <>{children}</>
  ) : (
    <Paper sx={{ marginBottom: '2px', cursor: 'pointer' }} elevation={10}>
      {children}
    </Paper>
  );
};
