import * as d3 from 'd3';
import * as arrow from 'apache-arrow';
import Plotly from 'plotly.js';
import {
  ET,
  nonProdDebugLog,
  predicateSearch,
  roundToNearest,
  roundUpToNearest,
  stockMarketClose,
  stockMarketOpen,
} from './shared';
import dayjs from 'dayjs';
import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  OIScaleRange,
  PRICE_BOUNDS,
  PriceCandle,
  PriceCandleWithDateTime,
} from '../types';
import {
  IntradayFiltersAxisLabels,
  IntradayFiltersStatsInnerKeys,
  IntradayFiltersStatsKeys,
} from '../config/oi';

// TODO different colors for dark/light mode?
const ZERO = 'rgb(255,255,255)';
const UP_CANDLE_FILL = '#FFF';
const UP_CANDLE_LINE = '#a0a0a0';
const DOWN_CANDLE_COLOR = '#000';
const HIRO_Y_AXIS_BASE_PADDING = 100_000_000; // 100m

// TODO: get actual numbers from brent/john. these were just guessed
const LENSE_BASE_COLORSCALE_RANGES = new Map([
  [IntradayGammaLense.GAMMA, 500_000_000], //500m
  [IntradayGammaLense.DELTA, 50_000_000_000], //50b
  [IntradayGammaLense.GAMMA_COLOR, 10_000_000], //10m
  [IntradayGammaLense.DELTA_CHARM, 10_000_000], //10m
  [IntradayGammaLense.DELTA_END_DIFF, 5_000_000_000], //5b
  [IntradayGammaLense.DELTA_DIRECTIONAL, 5_000_000_000], //5b
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 100_000_000], //100m
]);

const OI_CALL_COLOR = '#d8723a';
const OI_PUT_COLOR = '#3a79d8';

export function getColorScale(
  values: number[],
  negativeTrendColor: string,
  positiveTrendColor: string,
  selectedLense: IntradayGammaLense,
  selectedScaleRange: OIScaleRange,
): Plotly.ColorScale {
  if (values.length === 0 || selectedScaleRange !== OIScaleRange.AUTO) {
    return [
      [0, negativeTrendColor],
      [0.5, ZERO],
      [1, positiveTrendColor],
    ];
  }
  let min = values.reduce((a, b) => Math.min(a, b));
  let max = values.reduce((a, b) => Math.max(a, b));

  if (max < 0) {
    // 0 is most negative, and 1 is closer to zero
    return [
      [0, negativeTrendColor],
      [1, ZERO],
    ]; // TODO ZERO should be interpolated here
  } else if (min > 0) {
    return [
      [0, ZERO],
      [1, positiveTrendColor],
    ]; // TODO ZERO should be interpolated here
  }
  const zeroFrac = Math.abs(min) / (max - min);
  let pos = positiveTrendColor;
  let neg = negativeTrendColor;
  if (zeroFrac < 0.5) {
    neg = d3.interpolateRgb(ZERO, negativeTrendColor)(zeroFrac);
  } else {
    pos = d3.interpolateRgb(ZERO, positiveTrendColor)(1 - zeroFrac);
  }
  const val = [
    [0, neg],
    [d3.interpolateNumber(zeroFrac, 0)(0.2), d3.interpolateRgb(ZERO, neg)(0.5)],
    [d3.interpolateNumber(zeroFrac, 0)(0.1), d3.interpolateRgb(ZERO, neg)(0.2)],
    [zeroFrac, ZERO],
    [d3.interpolateNumber(zeroFrac, 1)(0.1), d3.interpolateRgb(ZERO, pos)(0.2)],
    [d3.interpolateNumber(zeroFrac, 1)(0.2), d3.interpolateRgb(ZERO, pos)(0.5)],
    [1, pos],
  ];
  return val as Plotly.ColorScale;
}

export function getGexData(
  table: arrow.Table,
  invert: boolean,
  netParquetKeys: string[],
  min: number | undefined,
  max: number | undefined,
  timestamp: dayjs.Dayjs | null,
  negGammaColor: string,
  posGammaColor: string,
  zeroDTE: boolean,
  strikeBarType: IntradayStrikeBarType,
): Plotly.Data[] | null {
  if (strikeBarType === IntradayStrikeBarType.NONE) {
    return null;
  }

  const array = table.toArray();
  if (array.length === 0 || timestamp == null || min == null || max == null) {
    nonProdDebugLog(
      'something gex related is null, returning early',
      array,
      timestamp,
      min,
      max,
    );
    return null;
  }

  const xMap: Map<string, { colors: string[]; x: number[]; y: number[] }> =
    new Map();
  const ts = timestamp.valueOf();

  const start = predicateSearch(array, (e) => e.timestamp < ts) + 1;
  const end = predicateSearch(array, (e) => e.timestamp <= ts);
  const data = array.slice(start, Math.max(0, end) + 1);

  for (const e of data) {
    const strike = e.strike_price;
    if (strike < min || strike > max) {
      continue;
    }
    const values = getGexValues(
      e,
      netParquetKeys,
      invert,
      zeroDTE,
      strikeBarType,
    );
    for (const entry of values) {
      const curr = xMap.get(entry.name) ?? {
        colors: [],
        x: [],
        y: [],
      };
      curr.x.push(entry.value);
      curr.y.push(strike);
      curr.colors.push(
        entry.color ?? (entry.value >= 0 ? posGammaColor : negGammaColor),
      );
      xMap.set(entry.name, curr);
    }
  }

  const gexData = [...xMap.keys()].map((traceName: string) => {
    const traceData = xMap.get(traceName)!;
    return {
      x: traceData.x,
      y: traceData.y,
      xaxis: 'x2',
      yaxis: 'y',
      orientation: 'h',
      type: 'bar',
      marker: {
        color: traceData.colors,
      },
      name: traceName,
      hoverinfo: 'none',
    } as Plotly.Data;
  });

  nonProdDebugLog(
    `gex data rendering for timestamp ${timestamp}, min ${min}, max ${max}:`,
    gexData,
  );

  nonProdDebugLog(
    'gex latest timestamp available',
    dayjs(array[array.length - 1].timestamp),
  );

  return gexData;
}

const getGexValues = (
  data: any,
  netParquetKeys: string[],
  invert: boolean,
  zeroDTE: boolean,
  strikeBarType: IntradayStrikeBarType,
): { name: string; value: number; color?: string }[] => {
  switch (strikeBarType) {
    case IntradayStrikeBarType.GAMMA:
      return [
        {
          name: strikeBarTraceName(strikeBarType),
          value: getTableValue(
            data,
            netParquetKeys,
            greekForLense(IntradayGammaLense.GAMMA),
            invert,
            zeroDTE,
          ),
        },
      ];
    case IntradayStrikeBarType.OI:
    case IntradayStrikeBarType.OI_NET:
      const buy = getTableValue(
        data,
        netParquetKeys,
        invert ? 'sell_oi' : 'buy_oi',
        false,
        zeroDTE,
      );
      const sell = getTableValue(
        data,
        netParquetKeys,
        invert ? 'buy_oi' : 'sell_oi',
        false,
        zeroDTE,
      );
      const color = data.is_call ? OI_CALL_COLOR : OI_PUT_COLOR;
      if (strikeBarType === IntradayStrikeBarType.OI_NET) {
        return [
          {
            name: strikeBarTraceName(strikeBarType, data.is_call),
            value: buy - sell,
            color,
          },
        ];
      }

      return [
        {
          name: strikeBarTraceName(strikeBarType, data.is_call, true),
          value: buy,
          color,
        },
        {
          name: strikeBarTraceName(strikeBarType, data.is_call, false),
          value: -sell,
          color,
        }, // in non-net view, sell should be negative
      ];
    case IntradayStrikeBarType.NONE:
      return [];
  }
};

const strikeBarTraceName = (
  strikeBarType: IntradayStrikeBarType,
  isCall?: boolean,
  long?: boolean,
) => {
  if (strikeBarType === IntradayStrikeBarType.GAMMA) {
    return 'GEX';
  }

  const type = isCall ? 'Call' : 'Put';
  if (strikeBarType === IntradayStrikeBarType.OI_NET) {
    return `Net ${type} Position`;
  }

  return `${long ? 'Long' : 'Short'} ${type}s`;
};

const getTableValue = (
  e: any,
  netParquetKeys: string[],
  suffix: string,
  invert: boolean,
  zeroDTE?: boolean,
) => {
  const value = netParquetKeys.reduce(
    (tot, k) => tot + e[tableKeyForLense(k, suffix, zeroDTE)],
    0,
  );
  return invert ? -value : value;
};

export function getContourData(
  table: arrow.Table,
  timestamp: number | undefined,
  netParquetKeys: string[],
  invert: boolean = false,
  priceBounds: number[] | null,
  offsetMs: number,
  negativeTrendColor: string,
  positiveTrendColor: string,
  filter: IntradayGammaLense,
  lastPrice: PriceCandleWithDateTime | undefined, // this is only set if a filter that needs it is selected (rn just Directional)
  selectedScaleRange: OIScaleRange,
  chartWidth: number,
): { chartData: Plotly.Data; firstChartTimestamp: number } | null {
  const array = table.toArray();
  if (array.length === 0) {
    return null;
  }

  // Filter down to the timestamp in question. Using binary search cuts filter time significantly
  const start = predicateSearch(array, (e) => e.timestamp < timestamp!) + 1;
  const end = predicateSearch(array, (e) => e.timestamp <= timestamp!);
  let data = array.slice(start, Math.max(0, end) + 1);
  if (priceBounds != null) {
    data = data.filter(
      (e) => e.spot >= priceBounds[0] && e.spot <= priceBounds[1],
    );
  }

  const rawChartTimes: number[] = [];
  const x: Date[] = [];
  const y: number[] = [];
  let z: number[] = [];

  for (const e of data) {
    x.push(new Date(e.time + offsetMs));
    y.push(Number(e.spot));
    const value = getTableValue(
      e,
      netParquetKeys,
      greekForLense(filter),
      invert,
    );
    z.push(value);
    rawChartTimes.push(e.time);
  }

  nonProdDebugLog(
    `before filter ${filter}: contour data with timestamp ${timestamp}, lastPrice ${lastPrice}:`,
  );
  nonProdDebugLog(x, y, z);

  if (z.length > 0) {
    if (
      filter === IntradayGammaLense.DELTA_DIRECTIONAL ||
      filter === IntradayGammaLense.GAMMA_DIRECTIONAL ||
      filter === IntradayGammaLense.DELTA_END_DIFF
    ) {
      const lastChartTime = rawChartTimes[rawChartTimes.length - 1];
      const marketClose = stockMarketClose(dayjs(lastChartTime)).valueOf();
      let lastTime = (lastPrice?.time ?? 0) * 1_000; // secs to ms
      if (filter === IntradayGammaLense.DELTA_END_DIFF) {
        const times = [...new Set(rawChartTimes)];
        const idx = predicateSearch(times, (t) => t < marketClose);
        lastTime = times[idx]; // last time before market close
      }
      const lastPriceDirValue = getDirectionalValueAtPrice(
        lastPrice?.close,
        rawChartTimes,
        y,
        z,
        lastTime,
      );
      z = applyDirectionalFilter(
        z,
        lastPriceDirValue,
        greekForLense(filter) === 'delta',
      );
    } else if (
      filter === IntradayGammaLense.GAMMA_COLOR ||
      filter === IntradayGammaLense.DELTA_CHARM
    ) {
      let idxDistanceBetweenStrikes: number = -1;
      for (let i = 1; i < data.length; i++) {
        if (data[i].time !== data[i - 1].time) {
          idxDistanceBetweenStrikes = i;
          break;
        }
      }
      let timeBetweenStampsMs: number[] = [];
      // For every time bucket, send down the difference between buckets
      for (
        let i = 0;
        i + idxDistanceBetweenStrikes < data.length;
        i += idxDistanceBetweenStrikes
      ) {
        timeBetweenStampsMs.push(
          data[i + idxDistanceBetweenStrikes].time - data[i].time,
        );
      }

      z = applyColorCharmFilter(
        z,
        rawChartTimes,
        idxDistanceBetweenStrikes,
        timeBetweenStampsMs,
      );
    }
  }

  const type = 'contour';
  const colorscale = getColorScale(
    z,
    negativeTrendColor,
    positiveTrendColor,
    filter,
    selectedScaleRange,
  );
  let contourOptions = { coloring: 'heatmap' } as any;
  if (selectedScaleRange !== OIScaleRange.AUTO) {
    contourOptions = {
      ...contourOptions,
      ...predefinedContourColorRange(selectedScaleRange, filter),
    };
  }

  nonProdDebugLog(
    `after filter ${filter}: contour data with timestamp ${timestamp}, lastPrice ${lastPrice}:`,
  );
  nonProdDebugLog(x, y, z);

  // @ts-ignore
  return {
    chartData: {
      x,
      y,
      z,
      colorscale,
      // @ts-ignore
      type,
      contours: contourOptions,
      colorbar: shouldShowAxisLabels(chartWidth)
        ? {
            title: IntradayFiltersAxisLabels.get(filter)!,
            titleside: 'right',
          }
        : {},
      hoverinfo: 'none',
    },
    firstChartTimestamp: rawChartTimes[0],
  };
}

const predefinedContourColorRange = (
  selectedScaleRange: OIScaleRange,
  selectedLense: IntradayGammaLense,
): { start: number; end: number } | {} => {
  if (selectedScaleRange === OIScaleRange.AUTO) {
    return {};
  }

  const rangeMinMax =
    LENSE_BASE_COLORSCALE_RANGES.get(selectedLense) ?? 50_000_000;

  const multiplier = multiplierForScaleRange(selectedScaleRange);
  return {
    start: rangeMinMax * multiplier * -1,
    end: rangeMinMax * multiplier,
  };
};

const multiplierForScaleRange = (selectedScaleRange: OIScaleRange) => {
  if (selectedScaleRange === OIScaleRange.LOW) {
    return 0.5;
  } else if (selectedScaleRange === OIScaleRange.HIGH) {
    return 2;
  }

  return 1;
};

const getDirectionalValueAtPrice = (
  lastPrice: number | undefined,
  chartTimes: number[],
  strikes: number[],
  values: number[],
  timestamp: number | undefined,
) => {
  if (lastPrice == null || timestamp == null) {
    return undefined;
  }

  for (let i = strikes.length - 1; i > 0; i--) {
    if (timestamp < chartTimes[i]) {
      // use < instead of == because sometimes the timestamp after market will
      // be well after the 4:30pm max we show on the chart
      continue;
    }

    if (lastPrice >= strikes[i]) {
      return values[i];
    } else if (strikes[i] >= lastPrice && strikes[i - 1] <= lastPrice) {
      // calculate gamma by finding the closest strikes and doing offset math if necessary to find out where the gamma
      // at the current price would lie in the range of the values of the 2 closest strikes
      const valueDiff = values[i] - values[i - 1];
      const strikeDiff = strikes[i] - strikes[i - 1];

      return (
        values[i - 1] + valueDiff * ((lastPrice - strikes[i - 1]) / strikeDiff)
      );
    }
  }

  nonProdDebugLog(
    `using directional value for last price ${lastPrice}:`,
    values[0],
  );

  return values[0];
};

const applyDirectionalFilter = (
  z: number[],
  lastPriceDirValue: number | undefined,
  invert: boolean,
) => {
  if (lastPriceDirValue == null) {
    // dont throw here as this may trigger once without lastPrice set as the react state change bubbles through
    return z;
  }

  return z.map((val) => (val - lastPriceDirValue) * (invert ? -1 : 1));
};

const applyColorCharmFilter = (
  z: number[],
  chartTimes: number[],
  idxDistanceBetweenStrikes: number,
  timeBetweenStampsMs: number[],
) => {
  if (idxDistanceBetweenStrikes < 0 || !(timeBetweenStampsMs?.length > 0)) {
    throw idxDistanceBetweenStrikes < 0
      ? 'Unable to calculate idx distance between strikes.'
      : 'Unable to calculate time between chartTimes.';
  }

  const date = dayjs(chartTimes[chartTimes.length - 1]);
  const marketOpen = stockMarketOpen(date).valueOf();
  const marketClose = stockMarketClose(date).valueOf();

  return z.map((val, idx) => {
    if (
      idx - idxDistanceBetweenStrikes < 0 ||
      chartTimes[idx] === marketOpen ||
      chartTimes[idx] === marketClose
    ) {
      return 0;
    }
    const timeIdx = Math.floor(
      (idx - idxDistanceBetweenStrikes) / idxDistanceBetweenStrikes,
    );
    const timeBetweenStampsMins = timeBetweenStampsMs[timeIdx] / 60_000;
    return (val - z[idx - idxDistanceBetweenStrikes]) / timeBetweenStampsMins;
  });
};

// Convert price candles into Plotly trace
export function getCandles(
  candles: PriceCandleWithDateTime[],
  timestamp: number | undefined,
  offsetMs: number,
): Plotly.Data {
  candles = timestamp
    ? candles.filter((c) => c.datetime.valueOf() <= timestamp)
    : candles;
  const x = candles.map((c) => new Date(c.datetime.valueOf() + offsetMs));
  const open = candles.map((c) => c.open);
  const high = candles.map((c) => c.high);
  const low = candles.map((c) => c.low);
  const close = candles.map((c) => c.close);
  return {
    // @ts-ignore
    x,
    open,
    high,
    low,
    close,
    xaxis: 'x',
    type: 'candlestick',
    hoverinfo: 'skip',
    // @ts-ignore
    increasing: { fillcolor: UP_CANDLE_FILL, line: { color: UP_CANDLE_LINE } },
    decreasing: { line: { color: DOWN_CANDLE_COLOR } },
    line: { width: 2 },
  };
}

export function getHiro(
  candles: PriceCandle[],
  timestamp: number | undefined,
  offsetMs: number,
  color: string,
  symbol: string,
): { chartData: Plotly.Data; min: number; max: number } | null {
  const x = [];
  const y = [];
  let min = Infinity;
  let max = -1;

  candles = timestamp
    ? candles.filter((c) => c.time * 1000 <= timestamp)
    : candles;

  if (candles.length === 0) {
    return null;
  }

  for (const candle of candles) {
    x.push(new Date(candle.time * 1000 + offsetMs));
    y.push(candle.close);
    min = Math.min(candle.close, min);
    max = Math.max(candle.close, max);
  }

  return {
    chartData: {
      x,
      y,
      yaxis: 'y2',
      xaxis: 'x',
      type: 'scatter',
      line: { width: 2, color },
      mode: 'lines',
      name: `${symbol} HIRO`,
      hoverinfo: 'skip',
    },
    min,
    max,
  };
}

// We want to ensure that certain important ticks show if they are available in the timestamps slider
// like the market open tick, and the start and end range ticks
// it's more complicated than you'd think to do that well, hence this function
export const getOiTicks = (timestamps: dayjs.Dayjs[], chartWidth: number) => {
  const timeMarks = [];
  const marketOpen = timestamps[0]
    .tz(ET)
    .set('hour', 9)
    .set('minute', 30)
    .set('second', 0);
  const indexOfMarketOpen = timestamps.findIndex((t) =>
    t.isSame(marketOpen, 'second'),
  );

  // the below are really just trial/error to figure out what works best
  const maxLabels = Math.floor(Math.max((chartWidth - 400) / 90, 2));
  const tickStep = Math.floor(timestamps.length / maxLabels);

  for (let i = 0; i < timestamps.length; i += tickStep) {
    // we always want to show the market open 9:30 timestamp
    // if we are about to show the timestamp right before 9:30
    // instead, show 9:30
    if (
      indexOfMarketOpen >= 0 &&
      i <= indexOfMarketOpen &&
      i + tickStep >= indexOfMarketOpen
    ) {
      i = indexOfMarketOpen;
    } else if (
      maxLabels > 3 &&
      i > timestamps.length - tickStep - 1 &&
      i + tickStep >= timestamps.length
    ) {
      // for the last timestamp we are showing
      // if this timestamp is less than label distance to the end of the slider,
      // we wont be able to show the last timestamp's label in the block after this, since the labels will overlap
      // in that case, instead of showing whatever timestamp we are about to show,
      // just show the timestamps.length-1 timestamp here
      // this looks weird if there arent enough timestamps available, so only do this if we have at least 4 ticks
      // i.e. timestamps.length > TICK_STEP * 3
      i = timestamps.length - 1;
    }

    timeMarks.push({
      value: i,
      label: `${timestamps[i].format('HH:mm')}`,
    });
  }

  // if we have enough space at the end of what we last added in the for loop above to add the last timestamp label
  // then add it. this if block should never be true if the if block directly above was true
  if (
    timeMarks.length < maxLabels &&
    timeMarks[timeMarks.length - 1].value <= timestamps.length - 1 - tickStep
  ) {
    timeMarks.push({
      value: timestamps.length - 1,
      label: `${timestamps[timestamps.length - 1].format('HH:mm')}`,
    });
  }

  return timeMarks;
};

export const greekForLense = (lense: IntradayGammaLense) => {
  switch (lense) {
    case IntradayGammaLense.GAMMA:
    case IntradayGammaLense.GAMMA_DIRECTIONAL:
    case IntradayGammaLense.GAMMA_COLOR:
      return 'gamma';
    default:
      return 'delta';
  }
};

export function convertTwelveCandleDatetimes(
  candles: any[],
  tz: string,
): PriceCandleWithDateTime[] {
  return candles?.map((c) => ({
    ...c,
    datetime: dayjs.tz(c.datetime, tz),
  }));
}

export const tableKeyForLense = (
  key: string,
  suffix: string,
  zeroDTE?: boolean,
) => `${key}_${suffix}${zeroDTE ? '_0' : ''}`;

export const shouldShowAxisLabels = (chartWidth: number) => chartWidth >= 900;

export const getHiroRange = (hiroData: any, priceBounds: number) => {
  const priceBoundsMultiplier =
    PRICE_BOUNDS.length - PRICE_BOUNDS.indexOf(priceBounds);
  const minPadding = HIRO_Y_AXIS_BASE_PADDING * priceBoundsMultiplier;

  return [
    roundToNearest(hiroData.min - minPadding, HIRO_Y_AXIS_BASE_PADDING),
    roundToNearest(hiroData.max + minPadding, HIRO_Y_AXIS_BASE_PADDING),
  ];
};

const gexBaseRange = (strikeBarType: IntradayStrikeBarType) => {
  if (strikeBarType === IntradayStrikeBarType.GAMMA) {
    return 100_000_000; //100m
  }

  return 10_000;
};

export const getGexRangeXAxis = (
  gexChartDataArr: any,
  scaleRange: OIScaleRange,
  gexTable: any,
  invert: boolean,
  zeroDTE: boolean,
  netParquetKeys: string[],
  strikeBarType: IntradayStrikeBarType,
  timestamp: dayjs.Dayjs | null,
) => {
  if (
    timestamp == null ||
    gexTable == null ||
    (gexChartDataArr?.length ?? 0) === 0
  ) {
    return undefined;
  }

  let val = -1;
  if (scaleRange === OIScaleRange.AUTO) {
    // get an array of all the values for the whole day so far
    const array = gexTable.toArray();
    if (array.length === 0) {
      return undefined;
    }

    // only account for the strikes we are showing
    const minStrike = Math.min(...gexChartDataArr[0].y);
    const maxStrike = Math.max(...gexChartDataArr[0].y);

    const ts = timestamp.valueOf();
    // get the max gamma absolute value we will be showing all day
    for (const e of array) {
      if (
        e.strike_price < minStrike ||
        e.strike_price > maxStrike ||
        e.timestamp !== ts
      ) {
        continue;
      }
      const strikeVals = getGexValues(
        e,
        netParquetKeys,
        invert,
        zeroDTE,
        strikeBarType,
      );
      const maxVal = Math.max(...strikeVals.map((v) => Math.abs(v.value)));
      val = Math.max(val, maxVal);
    }
  } else {
    const multiplier = multiplierForScaleRange(scaleRange);
    // pick the max of the default min/max and the max in the current set of data
    val = Math.max(
      gexBaseRange(strikeBarType) * multiplier,
      ...gexChartDataArr.flatMap((chartData: any) =>
        chartData.x.map((v: number) => Math.abs(v)),
      ),
    );
  }

  if (strikeBarType === IntradayStrikeBarType.GAMMA) {
    val = roundUpToNearest(val, 10_000_000);
  }
  return [-val, val];
};

export const getStatsPercentile = (
  value: number | undefined,
  lenseOrStrikeType: IntradayGammaLense | IntradayStrikeBarType,
  statsData: any,
  parquetKeys: string[],
  inverted: boolean,
  isStrikeBar: boolean,
  zeroDte: boolean,
  traceName: string,
  lookbackDays: number,
) => {
  let key = IntradayFiltersStatsKeys.get(lenseOrStrikeType);
  if (
    value == null ||
    statsData == null ||
    key == null ||
    statsData[key] == null ||
    parquetKeys.length !== 1 ||
    lenseOrStrikeType === IntradayStrikeBarType.NONE
  ) {
    return null;
  }

  const parquetKey = parquetKeys[0];
  const uninvertedVal = inverted ? -value : value;

  let innerKey = IntradayFiltersStatsInnerKeys.get(lenseOrStrikeType) ?? key;
  if (lenseOrStrikeType === IntradayStrikeBarType.OI) {
    // if the trace name equals what the tracename would be if long puts or calls, then we want to use the 'buy' key
    const buy = [
      strikeBarTraceName(IntradayStrikeBarType.OI, true, true),
      strikeBarTraceName(IntradayStrikeBarType.OI, false, true),
    ].includes(traceName);
    innerKey = buy ? 'buy' : 'sell';
  }

  const valKey = `${innerKey}${isStrikeBar && zeroDte ? '_0' : ''}${
    lenseOrStrikeType === IntradayStrikeBarType.OI
      ? ''
      : uninvertedVal > 0
      ? '_pos'
      : '_neg'
  }`;
  const statsForKey = statsData[key][`${lookbackDays}`]?.[parquetKey]?.[valKey];
  const percentiles = statsForKey?.percentiles;
  if (percentiles == null) {
    return null;
  }
  const percentilesArr: any[] = [...Object.entries(percentiles)].map((v) => [
    parseInt(v[0]),
    v[1],
  ]);
  const absVal = Math.abs(value);
  const idx = predicateSearch(
    percentilesArr,
    (percentile: any[]) => percentile[1] < absVal,
  );
  const lower = idx < 0 ? [0, statsForKey.min] : percentilesArr[idx];
  const upper =
    idx < percentilesArr.length - 1
      ? percentilesArr[idx + 1]
      : [100, statsForKey.max];

  const valDiffPerOne = (upper[1] - lower[1]) / (upper[0] - lower[0]);
  const percent = lower[0] + (absVal - lower[1]) / valDiffPerOne;
  return percent.toFixed(2);
};
