import dayjs from 'dayjs';
import { getUtcYMD } from 'util/shared/date';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Bar,
  ComposedChart,
  Line,
} from 'recharts';
import { useRecoilState } from 'recoil';
import { useEffect, useState, useRef, useMemo } from 'react';
import {
  tiltChartZoomConfigState,
  tiltInitialDataState,
} from '../../../states/indices';
import {
  IndicesContentType,
  RawTilt,
  SymSelectorSettings,
  Tilt,
  TiltType,
} from '../../../types';
import { formatAsCompactNumber } from '../../../util';
import useTilt from '../../../hooks/indices/useTilt';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y2_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import { Loader, ZoomOutButton } from 'components/shared';
import {
  TIMESTAMP_TICK_CONFIG,
  getTicksBrushed,
  getZoomConfigRefArea,
} from 'util/shared/chart';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import useBrushZoom from 'hooks/useBrushZoom';

interface TiltChartProps {
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  selectedSym: string;
  symSelectorSettings?: SymSelectorSettings;
}

export const TiltChart = ({
  selectedSym,
  chartStyleOverrides,
  containerStyleOverrides,
  symSelectorSettings,
}: TiltChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const { getTilt } = useTilt();
  const [tiltMode, setTiltMode] = useState<TiltType>(TiltType.GAMMA);
  const [initialData, setInitialData] = useRecoilState(tiltInitialDataState);
  const [zoomConfig, setZoomConfig] = useRecoilState(tiltChartZoomConfigState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { zoomChartConfig } = useBrushZoom<Tilt>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  const ticks = useMemo(
    () =>
      getTicksBrushed(
        initialData.map((d) => d.epoch_millis),
        zoomConfig,
        TIMESTAMP_TICK_CONFIG,
      ),
    [initialData, zoomConfig],
  );

  useEffect(() => {
    async function generateChartData() {
      setIsLoading(true);
      let data = await getTilt(selectedSym);
      const transformedData = data
        .map(({ delta_tilt, gamma_tilt, trade_date, upx }: RawTilt) => ({
          delta_tilt,
          gamma_tilt,
          epoch_millis: dayjs(trade_date).valueOf(),
          upx,
        }))
        .sort((a: Tilt, b: Tilt) => a.epoch_millis - b.epoch_millis);
      setZoomConfig((prev) => ({ ...prev, data: transformedData }));
      setInitialData(transformedData);
      setIsLoading(false);
    }
    generateChartData();
  }, [getTilt, selectedSym, setZoomConfig, setInitialData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        symbol={selectedSym}
        type={IndicesContentType.TILT}
        title={`${tiltMode === TiltType.GAMMA ? 'Gamma' : 'Delta'} Tilt Chart`}
        symSelectorSettings={symSelectorSettings}
        expandable
        controllerProps={{
          buttonGroups: [
            {
              buttons: [
                { value: TiltType.GAMMA, label: 'Gamma' },
                { value: TiltType.DELTA, label: 'Delta' },
              ],
              setter: setTiltMode,
              curValue: tiltMode,
            },
          ],
        }}
        customController={
          <ZoomOutButton
            zoomConfig={zoomConfig}
            setZoomConfig={setZoomConfig}
            initialData={initialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: initialData.length - 1,
            }}
          />
        }
      />
      <Loader isLoading={isLoading}>
        {zoomConfig.data && (
          <ChartWatermarkContainer
            ref={ref}
            size={25}
            offsetX={55}
            offsetY={40}
            style={{
              ...chartStyleOverrides,
            }}
          >
            <ResponsiveContainer>
              <ComposedChart
                margin={DEFAULT_CHART_MARGINS}
                barGap={2}
                barSize={6}
                {...zoomChartConfig}
              >
                <CartesianGrid
                  strokeDasharray="1 10"
                  stroke={theme.palette.gray}
                />
                <XAxis
                  allowDataOverflow
                  label={{
                    ...DEFAULT_X_AXIS_STYLES,
                    value: 'Trade Date',
                    offset: 3,
                  }}
                  dataKey="epoch_millis"
                  domain={['dataMin', 'dataMax']}
                  tick={{ fontSize: 10 }}
                  tickFormatter={getUtcYMD}
                  type="number"
                  tickCount={15}
                  ticks={ticks}
                />
                <Brush
                  dataKey="epoch_millis"
                  tickFormatter={getUtcYMD}
                  startIndex={zoomConfig.leftIdx}
                  endIndex={zoomConfig.rightIdx}
                  onChange={(brushIndices: any) =>
                    setZoomConfig((prev) => ({
                      ...prev,
                      leftIdx: brushIndices.startIndex,
                      rightIdx: brushIndices.endIndex,
                    }))
                  }
                  height={20}
                  travellerWidth={15}
                  stroke={theme.palette.gray}
                  fill={theme.palette.background.paper}
                  alwaysShowText
                />
                <YAxis
                  allowDataOverflow
                  yAxisId="left"
                  tickFormatter={(v: number) => `$${formatAsCompactNumber(v)}`}
                  tick={{ fontSize: 11 }}
                  domain={['dataMin', 'dataMax']}
                  type="number"
                  label={{
                    value: 'Price',
                    ...DEFAULT_Y_AXIS_STYLES,
                  }}
                  orientation="left"
                />
                <YAxis
                  allowDataOverflow
                  yAxisId="right"
                  tickFormatter={formatAsCompactNumber}
                  tick={{ fontSize: 11 }}
                  domain={['dataMin', 'dataMax']}
                  type="number"
                  label={{
                    value: 'Tilt',
                    ...DEFAULT_Y2_AXIS_STYLES,
                  }}
                  orientation="right"
                />
                <Tooltip
                  formatter={(v: string, name: string) =>
                    (name === 'Price' ? '$' : '') +
                    parseFloat(v).toLocaleString()
                  }
                  labelFormatter={getUtcYMD}
                  itemStyle={{ fontSize: '11px' }}
                  contentStyle={{
                    color: theme.palette.text.primary,
                    border: 'none',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.palette.shadows.paperBoxShadow,
                  }}
                  separator=": "
                />

                <Bar
                  isAnimationActive={false}
                  yAxisId="right"
                  dataKey={tiltMode}
                  name={
                    tiltMode === TiltType.DELTA ? 'Delta Tilt' : 'Gamma Tilt'
                  }
                  key={tiltMode}
                  fill={
                    tiltMode === TiltType.DELTA
                      ? theme.palette.indices.tilt.delta
                      : theme.palette.indices.tilt.gamma
                  }
                />
                <Line
                  isAnimationActive={false}
                  yAxisId="left"
                  dataKey="upx"
                  name="Price"
                  key="upx"
                  type="linear"
                  stroke={theme.palette.indices.tilt.upx}
                  dot={false}
                  strokeWidth={3}
                  connectNulls
                />
                {getZoomConfigRefArea(zoomConfig, 'left')}
              </ComposedChart>
            </ResponsiveContainer>
          </ChartWatermarkContainer>
        )}
      </Loader>
    </Box>
  );
};
