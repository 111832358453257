import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select as MaterialSelect,
  SelectChangeEvent,
} from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { SetterOrUpdater } from 'recoil';
import { SGTooltip } from '../core';
import { TooltipProps } from '@mui/material/Tooltip';

interface SGSelectProps {
  key: string;
  label: string;
  value: any;
  setter: SetterOrUpdater<any>;
  options: any[];
  dontRenderAllSelected?: boolean;
  optionsTextTransform?: (val: any) => string;
  tooltipTransform?: (val: any) => string | undefined;
  sx?: SxProps;
  multiple?: boolean;
  selectTooltipProps?: Partial<TooltipProps>;
}

export const SGSelect = ({
  key,
  label,
  value,
  setter,
  options,
  optionsTextTransform,
  tooltipTransform,
  selectTooltipProps,
  dontRenderAllSelected = false,
  sx = {},
  multiple = true,
}: SGSelectProps) => {
  const theme = useTheme();

  const onChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setter(multiple && typeof value === 'string' ? value.split(',') : value);
  };

  const toText = (option: any) =>
    optionsTextTransform ? optionsTextTransform(option) : option;

  const tooltipProps: any = {
    title:
      multiple && dontRenderAllSelected && value.length > 0
        ? value.map(toText).join(', ')
        : undefined,
    placement: 'top',
    ...(selectTooltipProps ?? {}),
  };
  return (
    <SGTooltip {...tooltipProps}>
      <MaterialSelect
        id={key}
        multiple={multiple}
        value={value}
        displayEmpty
        size="small"
        sx={{
          fontSize: 11,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '.MuiSelect-icon': {
            color: theme.palette.primary.main,
          },
          ...sx,
        }}
        renderValue={(selected) => {
          if (!Array.isArray(selected)) {
            return toText(selected);
          }

          if (selected.length === 0) {
            return label ?? `Click to select`;
          }
          if (!dontRenderAllSelected) {
            return selected.map(toText).join(' + ');
          }
          return value.length >= options.length
            ? 'Selected All'
            : `Selected ${value.length} of ${options.length}`;
        }}
        onChange={onChange}
        onClick={(e) => e.stopPropagation()}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option}
              value={option}
              sx={{
                fontSize: 10,
                py: 0,
                px: 4,
              }}
            >
              {multiple && <Checkbox checked={value.indexOf(option) > -1} />}
              <SGTooltip
                title={tooltipTransform ? tooltipTransform(option) : undefined}
                placement="left"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 30], // offset some px left to avoid covering checkbox
                        },
                      },
                    ],
                  },
                }}
              >
                <ListItemText
                  sx={
                    multiple
                      ? {}
                      : {
                          paddingY: '10px',
                        }
                  }
                >
                  {toText(option)}
                </ListItemText>
              </SGTooltip>
            </MenuItem>
          );
        })}
      </MaterialSelect>
    </SGTooltip>
  );
};
