import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Fragment, useEffect, useState } from 'react';
import {
  DATA_EXCHANGE_AGREEMENT_QUESTIONS,
  DEFAULT_AGREEMENT_FORM,
} from 'config';
import useInstituionalDetails from 'hooks/user/useInstituionalDetails';
import CloseIcon from '@mui/icons-material/Close';
import { DataExchangeAgreement, DataExchangeAgreementMap } from 'types';
import { Loader } from './Loader';
import { useSetRecoilState } from 'recoil';
import { currentToastState } from 'states';

interface IsInstitutionalModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface QuestionItemProps {
  text: string;
  value: boolean | null;
  onChange: (event: React.MouseEvent<HTMLElement>, nextView: string) => void;
}

const QuestionItem = ({ text, value, onChange }: QuestionItemProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      gap={8}
      sx={{
        paddingY: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: 14,
            md: 16,
          },
        }}
      >
        {text}
      </Typography>

      <Box>
        <ToggleButtonGroup exclusive value={value} onChange={onChange}>
          <ToggleButton
            value={true}
            sx={{
              textTransform: 'none',
              borderRadius: 2,
              borderColor: alpha(theme.palette.primary.main, 0.35),
            }}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <CheckRoundedIcon
                sx={{
                  fontSize: 18,
                  color: 'text.primary',
                }}
              />
              <Typography>Yes</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton
            value={false}
            sx={{
              textTransform: 'none',
              borderRadius: 2,
              borderColor: alpha(theme.palette.primary.main, 0.35),
            }}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <CloseRoundedIcon
                sx={{
                  fontSize: 18,
                  color: 'text.primary',
                }}
              />
              <Typography>No</Typography>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Stack>
  );
};

const IsInstitutionalModal = ({ open, setOpen }: IsInstitutionalModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const setToast = useSetRecoilState(currentToastState);

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);

  const { saveIsInstitutional, getInstitutionalForm } =
    useInstituionalDetails();
  const [institutionalForm, setInstitutionalForm] =
    useState<DataExchangeAgreementMap>(DEFAULT_AGREEMENT_FORM);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        setIsFetchLoading(true);
        const formData = await getInstitutionalForm();

        setInstitutionalForm(formData ?? DEFAULT_AGREEMENT_FORM);
      } catch (err) {
        setToast({
          message:
            'Something went wrong while fetching form data. Try again or contact us if the issue persists.',
          type: 'error',
        });
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchForm();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveChanges = async () => {
    try {
      setIsSaveLoading(true);
      await saveIsInstitutional(institutionalForm);
      setToast({
        message: 'Successfully saved your answers!',
        type: 'success',
        duration: 5_000,
      });
    } catch (err) {
      setToast({
        message:
          'Something went wrong while saving your answers. Try again or contact us if the issue persists.',
        type: 'error',
      });
    } finally {
      setIsSaveLoading(false);
    }
  };

  const hasUnansweredQuestions = Object.values(institutionalForm).some(
    (value) => value === null,
  );

  const modalActionBtns = (
    <>
      <Button
        onClick={handleClose}
        sx={{
          textTransform: 'none',
          color: theme.palette.text.secondary,
          fontWeight: 300,
          width: {
            xs: '100%',
            md: 150,
          },
        }}
      >
        Close
      </Button>
      <Button
        variant="contained"
        onClick={handleSaveChanges}
        disabled={isFetchLoading || isSaveLoading || hasUnansweredQuestions}
        sx={{
          backgroundColor: theme.palette.button.default,
          color: theme.palette.text.primary,
          textTransform: 'none',
          width: {
            xs: '100%',
            md: 150,
          },
          ':hover': {
            backgroundColor: theme.palette.button.hover,
          },
        }}
      >
        {isSaveLoading ? <CircularProgress /> : 'Proceed'}
      </Button>
    </>
  );

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="is-institutional-dialog-title"
        maxWidth="lg"
        sx={{
          paddingBottom: {
            xs: 0,
            md: '35px',
          },
        }}
      >
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingX: '24px', paddingTop: '20px' }}
        >
          <Typography
            id="is-institutional-dialog-title"
            sx={{
              fontSize: {
                xs: 16,
                md: 22,
              },
            }}
          >
            Accept Market Data Exchange Agreements
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
        </Stack>

        <DialogContent>
          <Stack gap={6}>
            <DialogContentText>
              In order to access the data you've requested, please accept the
              corresponding data agreements. First, please answer a few
              questions so we could certify your status as a professional or
              non-professional investor. These forms are required by SEC
              regulations and will only take a few seconds to complete.
            </DialogContentText>

            <Loader isLoading={isFetchLoading}>
              <Stack gap={2}>
                {Object.entries(DATA_EXCHANGE_AGREEMENT_QUESTIONS).map(
                  (
                    [agreementKey, text]: [string, string],
                    index: number,
                    array: [string, string][],
                  ) => (
                    <Fragment key={agreementKey}>
                      <QuestionItem
                        key={agreementKey}
                        text={text}
                        value={
                          institutionalForm[
                            agreementKey as DataExchangeAgreement
                          ]
                        }
                        onChange={(
                          _event: React.MouseEvent<HTMLElement>,
                          nextView: string,
                        ) => {
                          setInstitutionalForm((prevState) => ({
                            ...prevState,
                            [agreementKey]: nextView,
                          }));
                        }}
                      />
                      {index !== array.length - 1 && (
                        <Divider
                          sx={{
                            borderColor: alpha(theme.palette.text.primary, 0.2),
                          }}
                        />
                      )}
                    </Fragment>
                  ),
                )}
              </Stack>
            </Loader>

            <Stack
              direction="row"
              gap={1}
              sx={{
                display: {
                  xs: 'flex',
                  md: 'none',
                },
                justifyContent: 'space-between',
                paddingBottom: '70px',
              }}
            >
              {modalActionBtns}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          {modalActionBtns}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default IsInstitutionalModal;
