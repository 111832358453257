import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { isMobileState, userDetailsState } from 'states';
import { useRecoilValue } from 'recoil';
import { SGModal } from '../SGModal';

interface UpsellModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  subtitle: string;
  items: string[];
}

export const UpsellModal = ({
  open,
  setOpen,
  title,
  subtitle,
  items,
}: UpsellModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userDetails = useRecoilValue(userDetailsState);
  const action = userDetails != null ? 'Upgrade' : 'Subscribe';
  const isMobile = useRecoilValue(isMobileState);

  const customActions = [
    <Button
      key={action}
      sx={{
        textTransform: 'uppercase',
        backgroundColor: theme.palette.primary.main,
        // TODO: We need better default text color when on buttons.  All
        // modes should have roughly the below to be legible on our
        // SpotGamma green button
        color: 'black',
        fontSize: '14px',
        py: '6px',
        px: '24px',
      }}
      onClick={() =>
        window.open('https://spotgamma.com/subscribe-to-spotgamma/', '_blank')
      }
    >
      {`${action} Now`}
    </Button>,
    <Button
      key="login"
      sx={{
        textTransform: 'uppercase',
        backgroundColor: theme.palette.text.disabled,
        color: 'black',
        fontSize: '14px',
        py: '6px',
        px: '24px',
      }}
      onClick={() => navigate('/login')}
    >
      Login
    </Button>,
  ];

  return (
    <SGModal
      open={open}
      onClose={() => setOpen(false)}
      customActions={customActions}
    >
      <Box
        sx={{
          padding: isMobile ? '0.4em' : '1em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '12px',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.disabled,
            fontSize: '16px',
            textTransform: 'uppercase',
          }}
        >
          {`${action} to Unlock`}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '24px',
            textTransform: 'uppercase',
            display: 'block',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            paddingBottom: '1em',
            paddingLeft: '1em',
            fontSize: '16px',
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 'inherit',
              textTransform: 'uppercase',
            }}
          >
            {subtitle}
          </Typography>
          <List
            dense={true}
            sx={{ color: theme.palette.text.primary, flex: 1 }}
          >
            {items.map((item, i) => {
              return (
                <ListItem key={i}>
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        color: theme.palette.text.primary,
                        fontSize: '14px',
                        lineHeight: '16px',
                      },
                    }}
                    primary={item}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </SGModal>
  );
};
