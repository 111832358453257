import { NavLink } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

import {
  businessDaysAdd,
  businessDaysSubtract,
  formatAsPercentage,
  getDateFormatted,
  getQueryDate,
  getQueryDateFormatted,
} from '../../util';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  productAccessState,
  isMobileState,
  timezoneState,
  hiroSymbolsState,
  earningsCalendarState,
} from '../../states';
import { ProductType } from '../../types';
import { useState, useMemo, useEffect } from 'react';
import useHomeContent from 'hooks/home/useHomeContent';
import useLog from 'hooks/useLog';
import { Loader } from 'components/shared';
import { Center } from 'components/shared/Center';

const PERIOD_LABEL = new Map([
  ['BMO', 'Pre-market'],
  ['AMC', 'After-hours'],
  ['DMH', 'During market hours'],
]);

const TableCell = styled(MuiTableCell)({
  borderBottom: 'none',
  padding: 0,
});

const EarningsList = ({ earnings }: { earnings: any[] }) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const hiroSyms = useRecoilValue(hiroSymbolsState);
  const products = useRecoilValue(productAccessState);
  const productsWithAccess = useMemo(() => new Set(products), [products]);
  const fontSize = isMobile ? 12 : 14;
  const currentTimezone = useRecoilValue(timezoneState);

  const renderRow = (data: any, key: string) => {
    const etTime = dayjs.utc(data.utc).tz(currentTimezone);
    const inHiro =
      productsWithAccess.has(ProductType.HIRO) && hiroSyms.has(data.sym);

    return (
      <TableRow key={key}>
        <TableCell>
          <ListItemText
            primary={
              <NavLink
                to={
                  inHiro
                    ? `/${ProductType.HIRO}?sym=${data.sym}`
                    : `/${ProductType.EQUITYHUB}?sym=${data.sym}`
                }
                style={{ textDecoration: 'none' }}
              >
                <Typography color="primary" sx={{ fontSize: fontSize }}>
                  {data.sym}
                </Typography>
              </NavLink>
            }
            secondary={data.company_name}
          />
        </TableCell>
        <TableCell align="right">
          <Typography
            sx={{
              fontSize: fontSize,
              marginRight: '10px',
              textAlign: 'right',
            }}
            color={theme.palette.text.secondary}
          >
            {data.implied_move && formatAsPercentage(data.implied_move)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            sx={{
              fontSize: fontSize,
              marginRight: '10px',
              textAlign: 'right',
            }}
            color={theme.palette.text.secondary}
          >
            {`${PERIOD_LABEL.get(data.period) ?? ''}: `}
            {etTime.format('ddd, h:mm[\u00a0]A[\u00a0]z')}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  if (earnings == null || earnings.length === 0) {
    return <Typography>No earnings scheduled.</Typography>;
  }

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="150px" component="th">
              Name
            </TableCell>
            <TableCell component="th" align="right">
              Implied Move
            </TableCell>
            <TableCell component="th" align="center">
              Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {earnings
            .filter((e) => e.confirmed)
            .map((elem, idx: number) => renderRow(elem, `${idx}`))}
        </TableBody>
      </Table>
    </Box>
  );
};

export const EarningsDay = ({
  day,
  earnings,
  onExpanded,
  expanded,
}: {
  day: dayjs.Dayjs;
  earnings: any[];
  onExpanded: (day: dayjs.Dayjs | undefined) => void;
  expanded: boolean;
}) => {
  return (
    <Accordion
      key={day.toString()}
      defaultExpanded={expanded}
      sx={{ margin: '0px !important' }}
      onChange={(_evt, expanded: boolean) =>
        expanded ? onExpanded(day) : onExpanded(undefined)
      }
    >
      <AccordionSummary
        sx={{
          height: '40px',
          minHeight: '40px',
        }}
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <Typography>
          <h4 style={{ margin: '8px 0px 10px 0px' }}>
            {day == null
              ? `Upcoming In Your Watchlist`
              : `Earnings for ${day.format('dddd, MMMM DD, YYYY')}`}
          </h4>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {
          // Only render what's visible
          expanded && <EarningsList earnings={earnings} />
        }
      </AccordionDetails>
    </Accordion>
  );
};

export const EarningsCalendar = () => {
  const today = getQueryDate(true);
  const [expandedDay, setExpandedDay] = useState<dayjs.Dayjs | undefined>(
    today,
  );

  const [earningsCalendar, setEarningsCalendar] = useRecoilState<any>(
    earningsCalendarState,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const { getEarningsCalendar } = useHomeContent();

  const { logError } = useLog('Home');

  const fetchData = async () => {
    setLoading(true);
    setErrored(false);

    try {
      const earningsCalendarFetched =
        earningsCalendar != null &&
        earningsCalendar.get(getQueryDateFormatted()) != null; // only need to fetch this once a day

      const earningsCalendarResponse = earningsCalendarFetched
        ? earningsCalendar
        : await getEarningsCalendar();

      const noEarnings =
        !earningsCalendarFetched && !Array.isArray(earningsCalendarResponse);

      if (noEarnings) {
        return setErrored(true);
      }

      if (!earningsCalendarFetched) {
        // Only show the company if we have call volume and if time is supplied
        const earnings = (earningsCalendarResponse ?? [])
          .filter((e: any) => e.cv != null && e.utc != null)
          .reduce((map: Map<String, any>, entry: any) => {
            const date = getDateFormatted(dayjs.utc(entry.day));
            const entries = map.get(date) ?? [];
            entries.push(entry);
            return map.set(date, entries);
          }, new Map());
        setEarningsCalendar(earnings);
      }
    } catch (err) {
      logError(err, 'fetch earnings calendar data');
      setErrored(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (earningsCalendar == null) {
    return null;
  }

  const datesToDisplay = [
    businessDaysSubtract(today, 1),
    today,
    businessDaysAdd(today, 1),
    businessDaysAdd(today, 2),
    businessDaysAdd(today, 3),
    businessDaysAdd(today, 4),
    businessDaysAdd(today, 5),
  ];

  // TODO: Add 'upcoming in your watchlist' section
  return (
    <Loader isLoading={loading}>
      {errored ? (
        <Center
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography onClick={fetchData} sx={{ fontSize: '14px' }}>
            Unfortunately, there was an error. Click here to try again.
          </Typography>
        </Center>
      ) : (
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Stack sx={{ width: '100%' }}>
            {datesToDisplay.map((date: dayjs.Dayjs, idx: number) => (
              <EarningsDay
                key={`${idx}`}
                day={date}
                earnings={
                  earningsCalendar.get(getDateFormatted(date!)) as any[]
                }
                onExpanded={(day) => setExpandedDay(day)}
                expanded={expandedDay?.isSame(date, 'day') ?? false}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Loader>
  );
};
