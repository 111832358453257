import {
  OPTION_FEED_IDX,
  OptionFlag,
  OptionsFeedColumnKey,
  TradeSide,
  OptionTransactionType,
  OptionType,
  RawOptionFeedData,
  OptionTradeSide,
  OptionSaleType,
} from 'types/optionsFeed';

import { v4 as uuidv4 } from 'uuid';

const Mask = {
  OPTION_TYPE: 0x1,
  TNS_TYPE: 0x6,
  SIDE_TYPE: 0x18,
  IS_NEXT_EXP: 0x20,
  IS_RETAIL: 0x40,
  IS_BLOCK: 0x80,
  IS_SPREAD: 0x100,
};

const TYPE_STRS = [
  OptionTransactionType.NEW, // new
  OptionTransactionType.CORRECTION, // correction
  OptionTransactionType.CANCEL, // cancel
];

const typeToString = (tns_type: number): OptionTransactionType =>
  TYPE_STRS[tns_type];

const SIDE_STRS = [
  TradeSide.UNDETERMINED, // undetermined
  TradeSide.BUY, // buy
  TradeSide.SELL, // sell
];

const sideToString = (side_idx: number): TradeSide => SIDE_STRS[side_idx];

export const parseOptionFlags = (flags: number): OptionFlag => ({
  type: flags & Mask.OPTION_TYPE ? OptionType.PUT : OptionType.CALL,
  transactionType: typeToString((flags & Mask.TNS_TYPE) >> 1),
  sideType: sideToString((flags & Mask.SIDE_TYPE) >> 3),
  isNextExp: !!(flags & Mask.IS_NEXT_EXP) ? true : false,
  isRetail: !!(flags & Mask.IS_RETAIL) ? true : false,
  isBlock: !!(flags & Mask.IS_BLOCK) ? true : false,
  isSpread: !!(flags & Mask.IS_SPREAD) ? true : false,
});

// rawSignal format: ['AAPL', 1718733518879n, 6553.737343480935, 228.74070754478853, 12244.064045890529, 213.61, 7381904254035821727n, 1737147600000n, 200, 1, 273, 'f', 7, 6.95, 7.05, 0.2009438256563837]
export const getOptionFeedDataRow = (rawSignal: any[]): RawOptionFeedData => {
  const row: RawOptionFeedData = rawSignal.reduce((acc, value, index) => {
    switch (index) {
      case OPTION_FEED_IDX.SYM:
        acc[OptionsFeedColumnKey.Sym] = value.replace(/^.*\|/, '');
        break;
      case OPTION_FEED_IDX.TIME:
        acc[OptionsFeedColumnKey.Time] = BigInt(value);
        break;
      case OPTION_FEED_IDX.DELTA:
        acc[OptionsFeedColumnKey.Delta] = value;
        break;
      case OPTION_FEED_IDX.GAMMA:
        acc[OptionsFeedColumnKey.Gamma] = value;
        break;
      case OPTION_FEED_IDX.VEGA:
        acc[OptionsFeedColumnKey.Vega] = value;
        break;
      case OPTION_FEED_IDX.STOCK_PRICE:
        acc[OptionsFeedColumnKey.StockPrice] = value;
        break;
      case OPTION_FEED_IDX.TNS_INDEX:
        acc[OptionsFeedColumnKey.TnsIndex] = value;
        break;
      case OPTION_FEED_IDX.EXPIRATION:
        acc[OptionsFeedColumnKey.Expiration] = BigInt(value);
        break;
      case OPTION_FEED_IDX.STRIKE:
        acc[OptionsFeedColumnKey.Strike] = value;
        break;
      case OPTION_FEED_IDX.SIZE:
        acc[OptionsFeedColumnKey.Size] = value;
        break;
      case OPTION_FEED_IDX.FLAG:
        acc[OptionsFeedColumnKey.Flags] = parseOptionFlags(value);
        break;
      case OPTION_FEED_IDX.EXCHANGE_SALE_CONDITIONS:
        acc[OptionsFeedColumnKey.ExchangeSaleCondition] = value;
        break;
      case OPTION_FEED_IDX.PRICE:
        acc[OptionsFeedColumnKey.Price] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.BID:
        acc[OptionsFeedColumnKey.Bid] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.ASK:
        acc[OptionsFeedColumnKey.Ask] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.IVOL:
        acc[OptionsFeedColumnKey.IVol] = value;
        break;
      default:
        break;
    }
    return acc;
  }, {} as RawOptionFeedData);

  // dynamic field values
  row[OptionsFeedColumnKey.Premium] =
    row[OptionsFeedColumnKey.Size] * row[OptionsFeedColumnKey.Price] * 100;

  row[OptionsFeedColumnKey.Side] = getOptionTradeSide(row);

  row[OptionsFeedColumnKey.SaleType] = getOptionSaleTypeFlag(row);

  row.id = `${row[OptionsFeedColumnKey.Sym]}-${
    row[OptionsFeedColumnKey.Strike]
  }-${row[OptionsFeedColumnKey.Flags].type}-${
    row[OptionsFeedColumnKey.Expiration]
  }-${row[OptionsFeedColumnKey.TnsIndex]}-${uuidv4()}`;

  return row;
};

export const satisfiesDefaultConditions = (row: RawOptionFeedData) =>
  row[OptionsFeedColumnKey.Premium] > 1000;

export const getOptionTradeSide = (row: RawOptionFeedData) => {
  const optPrice = row[OptionsFeedColumnKey.Price];
  const bidPrice = row[OptionsFeedColumnKey.Bid];
  const askPrice = row[OptionsFeedColumnKey.Ask];

  if (optPrice < bidPrice) {
    return OptionTradeSide.BB;
  }

  if (optPrice === bidPrice) {
    return OptionTradeSide.B;
  }

  if (optPrice > bidPrice && optPrice < askPrice) {
    return OptionTradeSide.M;
  }

  if (optPrice === askPrice) {
    return OptionTradeSide.A;
  }

  return OptionTradeSide.AA;
};

export const getOptionSaleTypeFlag = (
  row: RawOptionFeedData,
): OptionSaleType | undefined => {
  const flags = row[OptionsFeedColumnKey.Flags];

  if (flags.isBlock) {
    return OptionSaleType.BLOCK;
  } else if (flags.isSpread) {
    return OptionSaleType.SPREAD;
  }

  switch (row[OptionsFeedColumnKey.ExchangeSaleCondition]) {
    case 'b':
    case 'S':
      return OptionSaleType.SWEEP;
    case 'c':
    case 'o':
    case 'p':
      return OptionSaleType.CROSS;
    default:
      return undefined;
  }
};
