import {
  bbgSymbolToSgSymbol,
  isBBEnvAvailable,
  isBloomberg,
  isValidBbgSymbol,
} from '../../../util';
import {
  getWorksheetsQuery,
  sampleWorksheetsResponse,
} from 'config/bloomberg/data';
import { COMBINED_SIGNALS, ComboSymbol } from '../../../config';
import useTerminalConnect from './useTerminalConnect';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  bssoTokenState,
  hiroManifestState,
  watchlistsState,
} from '../../../states';
import { Watchlist, BbgWorksheet } from 'types';
import useLog from '../../useLog';

const useWorksheets = () => {
  const bssoToken = useRecoilValue(bssoTokenState);
  const hiroManifest = useRecoilValue(hiroManifestState);
  const setWatchlists = useSetRecoilState(watchlistsState);

  const { runTCQuery } = useTerminalConnect();
  const { fetchBssoGraphQlToken } = useTerminalConnect();
  const { fetchAPIWithLog } = useLog('useWatchlists');

  const manifestContainsSym = (sym: string) => {
    if (hiroManifest == null) {
      return false;
    } else if (hiroManifest.combosSet.has(sym)) {
      return true;
    }
    const symbol = bbgSymbolToSgSymbol(sym);
    return hiroManifest.underlyingsSet.has(symbol);
  };

  const filterWorksheetSymbols = (symbols: string[]) => {
    const syms = symbols.filter((sym: string) => {
      if (
        hiroManifest == null ||
        hiroManifest.underlyingsSet.size === 0 ||
        hiroManifest.combosSet.size === 0
      ) {
        if (COMBINED_SIGNALS.has(sym)) {
          return true;
        }
        return isValidBbgSymbol(sym);
      }

      return manifestContainsSym(sym);
    });

    return syms.map((s) => bbgSymbolToSgSymbol(s));
  };

  const parseWorksheet = (worksheetData: any) => {
    const symbols = filterWorksheetSymbols(
      worksheetData.securities.edges.map((security: any) => security.node.id),
    );

    return { id: worksheetData.id, name: worksheetData.name, symbols };
  };

  const fetchWorksheets = async () => {
    if (!isBloomberg()) {
      return;
    }

    const bbgWorksheets = await getWorksheetsFromBbg();
    let watchlists: Watchlist[] | undefined = [];
    if (bbgWorksheets.length > 0) {
      watchlists = await getServerWatchlistWorksheets(bbgWorksheets);
    }

    setWatchlists(watchlists);
    return watchlists;
  };

  const getServerWatchlistWorksheets = async (
    worksheets: BbgWorksheet[],
    retries: number = 0,
  ): Promise<Watchlist[] | undefined> => {
    const body = JSON.stringify({ worksheets });
    const postOpts = { method: 'POST', body };
    const result = await fetchAPIWithLog('bbg/watchlists', postOpts);
    if (result.error != null) {
      if (retries < 1) {
        return await getServerWatchlistWorksheets(worksheets, retries + 1);
      }
      // if we error out twice, return no watchlists.
      // user can retry manually by clicking the refresh icon next to watchlists
      return undefined;
    }
    return result.watchlists;
  };

  async function getWorksheetsFromBbg() {
    let worksheetsData: any;
    let bssoTokenToUse = bssoToken;

    if (isBBEnvAvailable()) {
      if (bssoToken == null) {
        bssoTokenToUse = await fetchBssoGraphQlToken();
      }
      console.log(`fetching worksheets with BSSO token ${bssoTokenToUse}`);
      const response = await runTCQuery(getWorksheetsQuery, bssoTokenToUse);
      if (response != null) {
        worksheetsData = response.data;
        console.log('Received worksheets data', JSON.stringify(worksheetsData));
      } else {
        console.log('worksheets invalid response', JSON.stringify(response));
      }
    } else {
      worksheetsData = sampleWorksheetsResponse;
    }

    const worksheets = worksheetsData.worksheets.edges;
    let worksheetsFiltered: BbgWorksheet[] = worksheets.flatMap(
      (worksheetData: any) => {
        const parsed = parseWorksheet(worksheetData.node);
        return parsed.symbols.length > 0 ? [parsed] : [];
      },
    );
    const sgWorksheet = {
      id: 'spotgamma-worksheet',
      name: 'SpotGamma Indices',
      symbols: [
        ComboSymbol.SPX,
        ComboSymbol.NDX,
        ComboSymbol.RUT,
        ComboSymbol.MAG7,
      ],
    };
    return worksheetsFiltered.concat([sgWorksheet]);
  }

  return { fetchWorksheets };
};

export default useWorksheets;
