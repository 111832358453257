import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentProductTypeState,
  homeEditModalOpenState,
  isMobileState,
} from 'states';
import EditIcon from '@mui/icons-material/Edit';
import { ProductType } from 'types';

interface PageTitleProps {
  title: string;
}

const PageTitle = ({ title }: PageTitleProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const setEditableModalOpen = useSetRecoilState(homeEditModalOpenState);
  const currentProductType = useRecoilValue(currentProductTypeState);
  const isPageEditable = currentProductType === ProductType.HOME;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={3}
      margin={isMobile ? '5px auto 0px auto' : undefined}
      sx={{ flexShrink: 50 }}
    >
      <Box
        component="img"
        alt="SpotGamma logo"
        src="images/logo.png"
        width="32px"
        height="32px"
      />
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          sx={{
            fontSize: isMobile ? '18px' : 'calc(max(min(18px, 2vw), 14px))',
            textTransform: 'uppercase',
          }}
          color="text.primary"
        >
          {title}
        </Typography>
        {isPageEditable && (
          <IconButton
            onClick={() => setEditableModalOpen(true)}
            sx={{
              color: theme.palette.primary.main,
              zIndex: 1000,
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default PageTitle;
