import { FREE_SYMBOLS } from 'config';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { productAccessState } from 'states';
import { ProductType } from 'types';
import useLog from '../useLog';

const usePrices = () => {
  const products = useRecoilValue(productAccessState);
  const hasAccess = useMemo(
    () => products.includes(ProductType.EQUITYHUB),
    [products],
  );
  const { fetchAPIWithLog } = useLog('usePrices');

  const getPrices = useCallback(
    async (symbols: string[]) => {
      const syms = (hasAccess ? symbols : FREE_SYMBOLS)
        .map(encodeURIComponent)
        .join('-');
      const obj: Record<string, string> = await fetchAPIWithLog(
        `v1/prices?syms=${syms}`,
      );
      return new Map(Object.entries(obj).map(([s, p]) => [s, parseFloat(p)]));
    },
    // fetchAPIWithLog is safe to leave out of deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAccess],
  );

  return { getPrices };
};

export default usePrices;
