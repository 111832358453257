import { Box, Portal, Stack, SxProps, Theme, useTheme } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSlots,
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@spotgamma/x-data-grid-premium';
import { useMemo } from 'react';
import {
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
} from 'types/optionsFeed';

import {
  defaultGridTableSlots,
  getDefaultGridTableSlotProps,
  getDefaultGridTableStyles,
} from 'util/shared';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { isMobileState, userDetailsState, watchlistsState } from 'states';
import { WatchlistMultiSelect } from 'components/shared';
import useTnSsymbols from './useTnSsymbols';
import {
  tnsActiveWatchlistsIdsState,
  tnsFilterModelState,
} from 'states/optionsFeed';
import {
  DEFAULT_TNS_FIELDS,
  OPTIONS_FEED_WATCHLIST_FILTER_KEY,
  TNS_UNMODIFIABLE_FIELDS,
} from 'config/optionsFeed';
import GridTableOverlayWrapper from './GridTableOverlayWrapper';
import DataAgreementContainer from './DataAgreementContainer';
import { CustomGridController } from 'types';

interface CustomToolbarProps {
  customPositionedControllers?: CustomGridController[];
  disableToolbar?: boolean;
}

const CustomToolbar = ({
  customPositionedControllers,
  disableToolbar,
}: CustomToolbarProps) => {
  const sxProps: SxProps<Theme> = {
    textTransform: 'capitalize',
    fontSize: {
      xs: 12,
      md: 14,
    },
  };

  const setFilterModel = useSetRecoilState(tnsFilterModelState);
  const watchlists = useRecoilValue(watchlistsState);

  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    tnsActiveWatchlistsIdsState,
  );

  const { updateSymbols } = useTnSsymbols();

  return (
    <>
      {customPositionedControllers?.map((c) => (
        <Portal container={() => document.getElementById(c.elementId)!}>
          <Box key={c.elementId}>{c.component}</Box>
        </Portal>
      ))}
      {!disableToolbar && (
        <GridToolbarContainer sx={{ padding: '5px' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            width="100%"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <GridToolbarFilterButton
                slotProps={{
                  button: {
                    sx: sxProps,
                  },
                }}
              />
              <GridToolbarColumnsButton
                slotProps={{
                  button: {
                    sx: sxProps,
                  },
                }}
              />
            </Stack>

            <WatchlistMultiSelect
              activeWatchlistIds={activeWatchlistIds}
              setActiveWatchlistIds={(ids: number[]) => {
                setActiveWatchlistIds(ids);
                const activeWatchlists =
                  watchlists?.filter((w) => ids.includes(w.id as number)) ?? [];

                const watchlistSyms = new Set(
                  activeWatchlists.flatMap((w) => w.symbols),
                );

                updateSymbols([...watchlistSyms]);

                setFilterModel((prev) => {
                  const filterItem = {
                    field: OptionsFeedColumnKey.Sym,
                    id: OPTIONS_FEED_WATCHLIST_FILTER_KEY,
                    operator: 'isAnyOf',
                    value: [...watchlistSyms],
                  };

                  return prev
                    ? {
                        ...prev,
                        items: prev.items.some(
                          (item) =>
                            item.id === OPTIONS_FEED_WATCHLIST_FILTER_KEY,
                        )
                          ? prev.items.map((item) =>
                              item.id === OPTIONS_FEED_WATCHLIST_FILTER_KEY
                                ? filterItem
                                : item,
                            )
                          : [...prev.items, filterItem],
                      }
                    : { items: [filterItem] };
                });
              }}
            />
          </Stack>
        </GridToolbarContainer>
      )}
    </>
  );
};

interface Props {
  rows: RawOptionFeedData[];
  columns: GridColDef[];
  filterModelState: RecoilState<GridFilterModel>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  activeWatchlistIdsState: RecoilState<number[]>;
  error?: string;
  customGridSlotProps?: GridSlotsComponentsProps;
}

const OptionsFeedDatagrid = ({
  rows,
  error,
  columns,
  filterModelState,
  columnVisibilityState,
  columnOrderState,
  columnSizingState,
  activeWatchlistIdsState,
  customGridSlotProps,
}: Props) => {
  const isMobile = useRecoilValue(isMobileState);
  const userDetails = useRecoilValue(userDetailsState);
  const theme = useTheme();

  const { updateSymbols } = useTnSsymbols();

  const [filterModel, setFilterModel] = useRecoilState(filterModelState);

  const [columnVisibilityModel, setColumnVisibilityModel] = useRecoilState(
    columnVisibilityState,
  );
  const [columnOrder, setColumnOrder] = useRecoilState(columnOrderState);
  const [columnSizing, setColumnSizing] = useRecoilState(columnSizingState);

  const watchlists = useRecoilValue(watchlistsState);

  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    activeWatchlistIdsState,
  );

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(
        (column) =>
          !TNS_UNMODIFIABLE_FIELDS.includes(
            column.field as OptionsFeedColumnKey,
          ),
      )
      .map((column) => column.field);
  };

  const orderedColumns = useMemo(() => {
    const orderMap = new Map(columnOrder.map((field, index) => [field, index]));
    const fallback = new Map(
      DEFAULT_TNS_FIELDS.map((field, idx) => [field, idx + columns.length]),
    );

    return [...columns].sort(
      (a, b) =>
        (orderMap.get(a.field as OptionsFeedColumnKey) ??
          fallback.get(a.field as OptionsFeedColumnKey)!) -
        (orderMap.get(b.field as OptionsFeedColumnKey) ??
          fallback.get(b.field as OptionsFeedColumnKey)!),
    );
  }, [columnOrder, columns]);

  const dynamicallySizedColumns = useMemo(() => {
    return orderedColumns.map((col: GridColDef) => ({
      ...col,
      width: columnSizing[col.field as OptionsFeedColumnKey],
    }));
  }, [orderedColumns, columnSizing]);

  const maxWidth = useMemo(
    () =>
      dynamicallySizedColumns.reduce(
        (acc, col) => acc + Math.max(col.width ?? 0, col.minWidth ?? 0),
        0,
      ),
    [dynamicallySizedColumns],
  );

  const handleColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    _event: any,
    _details: any,
  ) => {
    const result = columnOrder.slice(); // Create a copy of the array
    const [removedElement] = result.splice(params.oldIndex, 1); // Remove the element from its original position
    result.splice(params.targetIndex, 0, removedElement); // Insert the element at the new position

    setColumnOrder(result);
  };

  return (
    <>
      {userDetails?.isInstitutional === false ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            maxWidth: isMobile ? maxWidth : maxWidth + 10,
            height: '100%',
            overflowY: 'hidden',
            overFlowX: 'auto',
          }}
        >
          <DataGridPremium
            rows={rows}
            // update datagrid rows ui every 3s
            throttleRowsMs={3000}
            filterModel={filterModel}
            onFilterModelChange={(model: GridFilterModel) => {
              setFilterModel(model);
              const watchlistSymsFilterItem = model?.items?.find(
                (item) => item.id === OPTIONS_FEED_WATCHLIST_FILTER_KEY,
              );
              if (watchlistSymsFilterItem) {
                const activeWatchlists =
                  watchlists?.filter((w) =>
                    activeWatchlistIds.includes(w.id as number),
                  ) ?? [];

                // makes sure active watchlist state is in sync with the actual filters in the grid panel
                // in case user changes symbols from the panel
                const newActiveWatchlists = activeWatchlists.filter((ws) =>
                  ws.symbols.every((s) =>
                    (watchlistSymsFilterItem.value as string[]).includes(s),
                  ),
                );

                setActiveWatchlistIds(
                  newActiveWatchlists.map((w) => w.id as number),
                );
              }

              // sync any symbol filters to url params
              model.items.forEach((item) => {
                if (
                  item.field === OptionsFeedColumnKey.Sym &&
                  item.value != null
                ) {
                  updateSymbols(
                    Array.isArray(item.value) ? item.value : [item.value],
                  );
                }
              });
            }}
            filterDebounceMs={300}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              setColumnVisibilityModel(newModel);
            }}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnResize={(params: GridColumnResizeParams) =>
              setColumnSizing((prev) => ({
                ...prev,
                [params.colDef.field]: params.width,
              }))
            }
            columns={dynamicallySizedColumns}
            initialState={{
              pinnedColumns: { left: ['time', 'sym'] },
              sorting: {
                sortModel: [
                  {
                    field: OptionsFeedColumnKey.Time,
                    sort: 'desc', // latest time always at the top by default
                  },
                ],
              },
              // TODO: figure out default filters that are reasonable on initial load
              // filter: {
              //   filterModel: {
              //     items: [
              //       {
              //         field: OptionsFeedColumnKey.Premium,
              //         operator: '>',
              //         value: 500,
              //       },
              //     ],
              //   },
              // },
            }}
            hideFooter
            disableRowSelectionOnClick
            disableMultipleRowSelection
            disableAggregation
            rowHeight={45}
            columnHeaderHeight={60}
            disableRowGrouping
            density="compact"
            slots={{
              ...defaultGridTableSlots,
              toolbar: CustomToolbar as GridSlots['toolbar'],
              noRowsOverlay:
                GridTableOverlayWrapper as GridSlots['noRowsOverlay'],
            }}
            slotProps={{
              ...getDefaultGridTableSlotProps(theme),
              columnsManagement: {
                getTogglableColumns,
              },
              noRowsOverlay: {
                error,
              } as any,
              ...customGridSlotProps,
            }}
            sx={{
              ...getDefaultGridTableStyles(theme),
              width: '100%',
            }}
          />
        </Box>
      ) : (
        <Stack
          gap={2}
          sx={{
            width: '100%',
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DataAgreementContainer />
        </Stack>
      )}
    </>
  );
};

export default OptionsFeedDatagrid;
