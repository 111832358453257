import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Column } from 'react-table';
import { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  hiroActiveWatchlistsIdsState,
  hiroWatchlistState,
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  productAccessState,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
  userDashAccessState,
  watchlistsState,
} from '../../../states';
import {
  hasDashAccessToProduct,
  HiroOverview,
  LowHigh,
  ProductType,
} from '../../../types';
import {
  ComponentHeader,
  FullscreenBottomDrawerButton,
  TableWrapper,
} from '../../shared';
import { useSearchParams } from 'react-router-dom';
import { HiroTable } from '..';
import { STOCK_SCREENER_FLOW_CONTENT_ID } from '../../../util';
import { AppcuesInfoButton } from 'components/shared/AppcuesInfoButton';
import { WatchlistMultiSelect } from 'components/shared/WatchlistMultiSelect';
import useSetSym from '../../../hooks/hiro/useSetSym';

const calcGaugeCircleStyle = (data: HiroOverview) => {
  const scale = d3
    .scaleLinear()
    .domain([data['30 day']?.low, data['30 day']?.high])
    .range([0, 100]);

  const colorScale = d3
    .scaleLinear<string>()
    .domain([0, 50, 100])
    .range(['#de3737', '#d6d94a', '#1db22c']);

  return {
    left: Math.max(Math.min(scale(data.signal) - 13.5, 100 - 13.5), 0),
    border: `4.5px solid ${colorScale(scale(data.signal))}`,
  };
};

export const StockScreener = () => {
  const data = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const theme = useTheme();
  const userDashAccess = useRecoilValue(userDashAccessState);
  const watchlist = useRecoilValue(hiroWatchlistState);
  const watchlists = useRecoilValue(watchlistsState);
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    hiroActiveWatchlistsIdsState,
  );
  const { getSym } = useSetSym();

  const activeWatchlists =
    watchlists?.filter((w) => activeWatchlistIds.includes(w.id as number)) ??
    [];

  const positiveTrendColor = useRecoilValue(positiveTrendColorState);
  const negativeTrendColor = useRecoilValue(negativeTrendColorState);
  const isMobile = useRecoilValue(isMobileState);
  const tableRef = useRef<any>(null);
  const [searchParams, _setSearch] = useSearchParams();
  const products = useRecoilValue(productAccessState);
  const hasHiroAccess = useMemo(
    () => products.includes(ProductType.HIRO),
    [products],
  );

  const calcSort = (data: HiroOverview) => {
    var scale = d3
      .scaleLinear()
      .domain([data['30 day']?.low, data['30 day']?.high])
      .range([0, 100]);

    return scale(data.signal);
  };

  const columns = useMemo(() => {
    const calcGaugeStyle = (
      data: HiroOverview,
      d: LowHigh,
      is30Day: boolean,
    ) => {
      const scale = d3
        .scaleLinear()
        .domain([data['30 day']?.low, data['30 day']?.high])
        .range([0, 100]);

      const colorScale = d3
        .scaleLinear<string>()
        .domain([0, 50, 100])
        .range(['#de3737', '#d6d94a', '#1db22c']);

      const width = Math.min(scale(d?.high) - scale(d?.low), 100);
      const left = Math.round(Math.max(scale(d?.low || 0), 0));
      const right = Math.round(Math.max(scale(d?.high || 0), 0));
      const middle = Math.round((left + right) / 2);
      return {
        width: width || 100,
        left: left,
        background: !is30Day
          ? `linear-gradient(90deg, ${colorScale(left)} ${0}px, ${colorScale(
              middle,
            )} ${middle - left}px,${colorScale(right)} ${right - left}px)`
          : theme.palette.hiro.signalBg,
      };
    };

    return [
      {
        Header: 'Name',
        accessor: 'instrument',
        Cell: (props: { value: string }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(3),
              }}
            >
              <div
                className="company-photo"
                style={{
                  backgroundImage: `url("images/hiro-logos/${props.value}.png")`,
                  width: '22px',
                  height: '22px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  borderRadius: '100%',
                }}
              ></div>
              <Typography>{props.value}</Typography>
            </Box>
          );
        },
      },

      {
        Header: 'Current Price',
        accessor: 'price',
        sortType: (
          a: { original: HiroOverview },
          b: { original: HiroOverview },
        ) => {
          return a.original.price - b.original.price;
        },
        Cell: (props: { value: number }) => {
          return (
            <Typography style={{ paddingRight: '20%', textAlign: 'right' }}>
              {typeof props.value === 'number'
                ? props.value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                : ''}
            </Typography>
          );
        },
      },
      {
        Header: 'Price Change',
        accessor: 'priceChange',
        sortType: (
          a: { original: HiroOverview },
          b: { original: HiroOverview },
        ) => {
          return a.original.priceChange - b.original.priceChange;
        },
        Cell: (props: { value: number }) => {
          return (
            <Typography
              style={{
                paddingRight: '20%',
                textAlign: 'right',
                color:
                  props.value > 0 ? positiveTrendColor : negativeTrendColor,
              }}
            >
              {typeof props.value === 'number'
                ? `${props.value.toFixed(2)}%`
                : ''}
            </Typography>
          );
        },
      },
      {
        Header: 'HIRO Signal',
        accessor: 'signal',
        sortType: (a: any, b: any) => {
          return calcSort(a.original) - calcSort(b.original);
        },
        Cell: (props: { value: string; row: { original: HiroOverview } }) => {
          return props.value != null ? (
            <div
              style={{
                paddingRight: '20%',
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
              }}
            >
              <div className="gauge">
                <div
                  className="full-range"
                  style={calcGaugeStyle(
                    props.row.original,
                    props.row.original['30 day'],
                    true,
                  )}
                ></div>
                <div
                  className="current-range"
                  style={calcGaugeStyle(
                    props.row.original,
                    props.row.original['5 day'],
                    false,
                  )}
                ></div>
                <div
                  className="current-hiro"
                  style={calcGaugeCircleStyle(props.row.original)}
                ></div>
              </div>
            </div>
          ) : (
            ''
          );
        },
      },
      {
        Header: 'Sector',
        accessor: 'sector',
        Cell: (props: { value: string }) => {
          return <Typography>{props.value}</Typography>;
        },
      },
    ];
  }, [negativeTrendColor, positiveTrendColor, theme]);

  const currentData = useMemo(() => {
    if (!hasHiroAccess) {
      // If free HIRO, update sort to be: Current Free Symbol -> Rest Free Syms -> Rest Syms sorted alphabetically
      return Array.from(data.values()).sort((a, b) => {
        if (a.live) {
          return -1;
        } else if (b.live) {
          return 1;
        } else if ((a.signal != null) !== (b.signal != null)) {
          return a.signal != null ? -1 : 1;
        } else {
          return a.instrument.localeCompare(b.instrument);
        }
      });
    }
    if (activeWatchlists.length > 0) {
      const watchlistSyms = activeWatchlists.flatMap((w) => w.symbols);
      return watchlistSyms
        .map((sym) => data.get(sym))
        .filter((d): d is HiroOverview => d != null);
    } else {
      return Array.from(data.values());
    }
  }, [data, hasHiroAccess, watchlist, activeWatchlists]);

  useEffect(() => {
    const searchedSym = getSym(ProductType.HIRO);
    if (!isMobile && searchedSym != null) {
      const targetRow = tableRef.current?.querySelector(
        `[data-sym="${searchedSym}"]`,
      );
      targetRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isMobile, searchParams]);

  return hasDashAccessToProduct(userDashAccess, ProductType.OPTIONS_FEED) ? (
    <TableWrapper ref={tableRef}>
      <HiroTable
        columns={columns as Column<HiroOverview>[]}
        data={currentData}
      />
    </TableWrapper>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        mt: '6px',
        width: 1.0,
        overflowX: 'auto',
      }}
    >
      <ComponentHeader
        title="Stock Screener"
        buttons={
          <>
            <FullscreenBottomDrawerButton />
            <WatchlistMultiSelect
              activeWatchlistIds={activeWatchlistIds}
              setActiveWatchlistIds={setActiveWatchlistIds}
            />
            <AppcuesInfoButton articleKey={STOCK_SCREENER_FLOW_CONTENT_ID} />
          </>
        }
        styleProps={{ p: theme.spacing(3), pl: 6 }}
      />
      <TableWrapper ref={tableRef}>
        <HiroTable
          columns={columns as Column<HiroOverview>[]}
          data={currentData}
        />
      </TableWrapper>
    </Box>
  );
};
