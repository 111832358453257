import useLog from 'hooks/useLog';
import { useCallback } from 'react';
import { DataExchangeAgreementMap } from 'types';
import { setToken } from 'util/shared';
import useUserDetails from './useUserDetails';

const useInstituionalDetails = () => {
  const { fetchAPIWithLog } = useLog('InstitutionalDetails');
  const { setPartialUserDetails } = useUserDetails();

  const saveIsInstitutional = useCallback(
    async (agreementRecord: DataExchangeAgreementMap): Promise<boolean> => {
      const response = await fetchAPIWithLog(`v1/users/saveIsInstitutional`, {
        method: 'POST',
        body: JSON.stringify(agreementRecord),
      });

      if (response.error != null) {
        throw new Error(response.error);
      }

      const newToken = response?.updatedToken;

      if (newToken) {
        setToken(newToken);
        setPartialUserDetails({ isInstitutional: response?.isInstitutional });
      }

      return response?.isInstitutional;
    },
    [],
  );

  const getInstitutionalForm = useCallback(async () => {
    const response = await fetchAPIWithLog(`v1/users/institutionalForm`);
    if (response.error != null) {
      throw new Error(response.error);
    }
    return response?.institutionForm as DataExchangeAgreementMap;
  }, []);

  return {
    saveIsInstitutional,
    getInstitutionalForm,
  };
};

export default useInstituionalDetails;
