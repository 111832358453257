import { Typography } from '@mui/material';
import {
  CompassPayload,
  CompassSeverity,
  StrategyCompassMode,
} from '../types/compass';
import { COMBINED_SIGNALS } from '../config';

const VOWELS = new Set(['a', 'e', 'i', 'o', 'u']);
export const aOrAn = (word: string) => {
  if (word.length === 0) {
    return word;
  }
  return `${VOWELS.has(word[0]) ? 'an' : 'a'} ${word}`;
};

export const toPercentString = (num: number) => (num * 100.0).toFixed(2);

export const getTradeInfo = (data: CompassPayload) => {
  const xSev = calculateSeverity(data.x);
  const ySev = calculateSeverity(data.y);

  return {
    tooltipXDesc: COMPASS_DESCRIPTIONS[xSev].tooltipXDesc,
    tooltipYDesc: COMPASS_DESCRIPTIONS[ySev].tooltipYDesc,
    trade: getTrade(xSev, ySev),
  };
};

export const COMPASS_DESCRIPTIONS = {
  [CompassSeverity.VERY_SLIGHT]: {
    tooltipXDesc: 'very high chance of a bullish price reversal',
    tooltipYDesc: 'extremely cheap',
  },
  [CompassSeverity.SLIGHT]: {
    tooltipXDesc: 'high chance of a bullish price reversal',
    tooltipYDesc: 'very cheap',
  },
  [CompassSeverity.MILD]: {
    tooltipXDesc: 'above average chance of a bullish price reversal',
    tooltipYDesc: 'cheap',
  },
  [CompassSeverity.NEUTRAL_LOW]: {
    tooltipXDesc: 'average bullish price reversal risk',
    tooltipYDesc: 'slightly cheap',
  },
  [CompassSeverity.NEUTRAL_HIGH]: {
    tooltipXDesc: 'average bearish price reversal risk',
    tooltipYDesc: 'slightly expensive',
  },
  [CompassSeverity.MODERATE]: {
    tooltipXDesc: 'above average chance of a bearish price reversal',
    tooltipYDesc: 'expensive',
  },
  [CompassSeverity.HIGH]: {
    tooltipXDesc: 'high chance of a bearish price reversal',
    tooltipYDesc: 'very expensive',
  },
  [CompassSeverity.VERY_HIGH]: {
    tooltipXDesc: 'very high chance of a bearish price reversal',
    tooltipYDesc: 'extremely expensive',
  },
};

const getTrade = (xSev: CompassSeverity, ySev: CompassSeverity) => {
  if (ySev === CompassSeverity.NEUTRAL_LOW) {
    if (xSev === CompassSeverity.NEUTRAL_LOW) {
      return 'buying call spreads';
    } else if (xSev === CompassSeverity.NEUTRAL_HIGH) {
      return 'buying put spreads';
    } else if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'buying calls';
    } else {
      return 'buying puts';
    }
  } else if (ySev === CompassSeverity.NEUTRAL_HIGH) {
    if (xSev === CompassSeverity.NEUTRAL_LOW) {
      return 'selling put spreads';
    } else if (xSev === CompassSeverity.NEUTRAL_HIGH) {
      return 'selling call spreads';
    } else if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'selling puts';
    } else {
      return 'selling calls';
    }
  } else if (ySev < CompassSeverity.NEUTRAL_LOW) {
    if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'buying calls';
    } else if (xSev > CompassSeverity.NEUTRAL_HIGH) {
      return 'buying puts';
    } else if (
      xSev === CompassSeverity.NEUTRAL_LOW ||
      xSev === CompassSeverity.NEUTRAL_HIGH
    ) {
      return 'buying a straddle or strangle';
    }
  } else if (ySev > CompassSeverity.NEUTRAL_HIGH) {
    if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'selling puts';
    } else if (xSev > CompassSeverity.NEUTRAL_HIGH) {
      return 'selling calls';
    } else if (
      xSev === CompassSeverity.NEUTRAL_LOW ||
      xSev === CompassSeverity.NEUTRAL_HIGH
    ) {
      return 'selling a straddle or strangle';
    }
  }

  return '';
};

export const generateTooltipText = (data: CompassPayload) => {
  const { tooltipXDesc, tooltipYDesc, trade } = getTradeInfo(data);
  return (
    <>
      <Typography>
        {data.sym} has a 1 month implied volatility in the{' '}
        {toPercentString(data.y)}% of its 52-wk range, indicating {tooltipYDesc}{' '}
        implied volatility relative to its history.
      </Typography>
      <Typography>
        {data.sym}'s current price is in the {toPercentString(data.x)}% of it's
        Bollinger Band range, indicating {aOrAn(tooltipXDesc)}.
      </Typography>
      <Typography>
        Based purely on the {tooltipYDesc} IV and {tooltipXDesc}, perhaps
        consider{' '}
        <Typography fontWeight="bold" sx={{ textDecoration: 'underline' }}>
          {trade}
        </Typography>
        .
      </Typography>
      <Typography fontSize="9px" marginTop="5px">
        All trading involves risk. The information provided here is not
        investment advice. Your trades are solely the result of your own
        actions, and SpotGamma assumes no liability for them.
      </Typography>
    </>
  );
};

export const calculateSeverity = (val: number) => {
  // values here for NEUTRAL_LOW and NEUTRAL_HIGH should match SPREAD_THRESHOLD in StrategyCompass
  // to ensure that values in the spread boxes print out the correct recommendation
  if (val < 0.075) {
    return CompassSeverity.VERY_SLIGHT;
  } else if (val < 0.175) {
    return CompassSeverity.SLIGHT;
  } else if (val < 0.25) {
    return CompassSeverity.MILD;
  } else if (val >= 0.25 && val < 0.5) {
    return CompassSeverity.NEUTRAL_LOW;
  } else if (val >= 0.5 && val <= 0.75) {
    return CompassSeverity.NEUTRAL_HIGH;
  } else if (val > 0.75 && val <= 0.85) {
    return CompassSeverity.MODERATE;
  } else if (val > 0.85 && val < 0.925) {
    return CompassSeverity.HIGH;
  } else {
    // 92.5 - 100
    return CompassSeverity.VERY_HIGH;
  }
};

export const isValidCompassSymbol = (sym: string) => {
  return (sym?.length ?? 0) > 0 && !COMBINED_SIGNALS.has(sym);
};

export const getModeLabel = (mode: StrategyCompassMode) => {
  switch (mode) {
    case StrategyCompassMode.PriceVol:
      return 'Price to IV';
    case StrategyCompassMode.SkewVol:
      return 'Skew to IV';
  }
};
