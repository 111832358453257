import { useEffect, useMemo, useRef, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  ComposedChart,
  Area,
} from 'recharts';
import { useRecoilValue } from 'recoil';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import {
  equityQuantilesState,
  isMobileState,
  screenWidthState,
  selectedEquityLevelsState,
  selectedEquityState,
} from '../../../states';
import {
  formatAsCompactNumber,
  calculateTextWidth,
  shadeColor,
  formatAsCurrency,
} from '../../../util';
import { ColorMode } from '../../../theme';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from 'config';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';

type CompositeViewGraphProps = {
  showKeyLevels: boolean;
};
export const CompositeViewGraph = ({
  showKeyLevels,
}: CompositeViewGraphProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const selectedEquity = useRecoilValue(selectedEquityState);
  const levels = useRecoilValue(selectedEquityLevelsState);
  const [gammaData, setGammaData] = useState<any[]>([]);
  const equityQuantiles = useRecoilValue(equityQuantilesState);
  const screenWidth = useRecoilValue(screenWidthState);
  const theme = useTheme();
  const fieldColorMapping = theme.palette.equityHub.fieldColorMapping;
  const isMobile = useRecoilValue(isMobileState);

  useEffect(() => {
    if (selectedEquity == null || selectedEquity.mf_list == null) {
      return;
    }
    const gammaList = JSON.parse(selectedEquity.mf_list);
    const strikeList = JSON.parse(selectedEquity.smf_list);

    setGammaData(
      strikeList.map((px: number, i: number) => ({
        strike: px,
        gamma: gammaList[i],
      })),
    );
  }, [setGammaData, selectedEquity]);

  const offset = useMemo(() => {
    if (gammaData?.length > 0 && selectedEquity) {
      const values = gammaData.map((d) => d.strike);
      const min = Math.min(...values);
      const max = Math.max(...values);
      return (selectedEquity.putctrl - min) / (max - min);
    }
  }, [selectedEquity, gammaData]);

  const maxGammaStrikeDate =
    selectedEquity &&
    dayjs.utc(selectedEquity.max_exp_g_date).format('L').toString();

  const maxDeltaStrikeDate =
    selectedEquity &&
    dayjs.utc(selectedEquity.max_exp_d_date).format('L').toString();

  const gammaHedgeEst = useMemo(
    () =>
      selectedEquity &&
      Math.round(
        (selectedEquity.atmgc + selectedEquity.atmgp) / selectedEquity.upx,
      ).toLocaleString(),
    [selectedEquity],
  );

  const recentActivityColor = useMemo(() => {
    if (equityQuantiles && selectedEquity) {
      return d3
        .scaleSequential(d3.interpolateBlues)
        .domain([equityQuantiles.activityMin, equityQuantiles.activityMax])(
        selectedEquity.activity_factor,
      );
    }
    return theme.palette.text.primary;
  }, [equityQuantiles, selectedEquity]);

  const positionGreenColor = useMemo(() => {
    if (equityQuantiles && selectedEquity) {
      return d3
        .scaleSequential(d3.interpolateGreens)
        .domain([equityQuantiles.positionMin, equityQuantiles.positionMax])(
        selectedEquity.position_factor,
      );
    }
    return theme.palette.text.primary;
  }, [equityQuantiles, selectedEquity]);

  const positionRedColor = useMemo(() => {
    if (equityQuantiles && selectedEquity) {
      return d3
        .scaleSequential(d3.interpolateReds)
        .domain([equityQuantiles.positionMin, equityQuantiles.positionMax])(
        selectedEquity.position_factor,
      );
    }
    return theme.palette.text.primary;
  }, [equityQuantiles, selectedEquity]);

  let referenceLines;
  if (levels != null && showKeyLevels) {
    // show longer level names on top to make it easier to read
    const sortedLevels = [...levels].sort((a, b) =>
      a.name.length < b.name.length ? 1 : -1,
    );
    referenceLines = sortedLevels.map(({ field, value, name }, i) => {
      const values = gammaData.map((d) => d.strike);
      const min = Math.min(...values);
      const max = Math.max(...values);
      const xAxisTicks = max - min;
      // 0.7 is default content width in dashboardLayout.tsx
      const chartWidth = ref.current?.offsetWidth ?? screenWidth * 0.7;
      const pxPerTick = chartWidth / xAxisTicks;
      const pxFromMax = (max - value) * pxPerTick;
      // if window is taller, add more spacing
      // if there are more than 5 levels to add, decrease spacing proportionally since youll need it
      // note window.innerHeight does not handle resizing after render, youd need to refresh
      const levelsCountRatio = Math.min(1, 5 / levels.length);
      const y =
        20 +
        i * Math.max(((55 * window.innerHeight) / 1000) * levelsCountRatio, 20);
      const text = `${name} $${value}`;
      const width = calculateTextWidth(text);
      const x = width > pxFromMax ? -1 * width * 0.82 : 1;

      const stroke =
        fieldColorMapping[
          field.split(',')[0] as keyof typeof fieldColorMapping
        ];
      const textShadowColor = shadeColor(
        stroke,
        theme.colorMode === ColorMode.LIGHT ? -60 : -90,
      );
      return (
        <ReferenceLine
          x={value}
          key={`composite-reference-line-${field}`}
          stroke={stroke}
          strokeWidth={2}
          label={
            <Label
              position={{ x: x, y: y }}
              value={text}
              angle={0}
              height={20}
              style={{
                textAnchor: 'start',
                fill: stroke,
                fontSize: 13,
                fontWeight: '900',
                textShadow: `-1px -1px 0 ${textShadowColor}, 1px -1px 0 ${textShadowColor}, -1px 1px 0 ${textShadowColor}, 1px 1px 0 ${textShadowColor}`,
              }}
            />
          }
          isFront
        />
      );
    });
  }

  return (
    <ChartWatermarkContainer
      ref={ref}
      style={{
        ...(isMobile ? { minHeight: '450px' } : {}),
      }}
      size={15}
      offsetX={50}
      offsetY={25}
      sym={selectedEquity?.sym}
      symStyles={{
        right: '15px',
      }}
    >
      <ResponsiveContainer>
        <ComposedChart data={gammaData} margin={DEFAULT_CHART_MARGINS}>
          <CartesianGrid
            strokeDasharray={
              theme.colorMode === ColorMode.LIGHT ? '2 8' : '0.3 8'
            }
          />
          <XAxis
            dataKey="strike"
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 11 }}
            label={{
              value: 'Strike',
              ...DEFAULT_X_AXIS_STYLES,
            }}
            tickCount={10}
            type="number"
            interval="preserveStartEnd"
          />
          <YAxis
            domain={['auto', 'auto']}
            tick={{ fontSize: 11 }}
            tickFormatter={formatAsCompactNumber}
            label={{
              value: 'SG Acceleration Indicator',
              ...DEFAULT_Y_AXIS_STYLES,
            }}
          />
          <Tooltip
            formatter={formatAsCompactNumber}
            itemStyle={{
              fontSize: '11px',
              color: theme.palette.text.secondary,
            }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
            }}
            labelFormatter={(value) =>
              `Strike: ${formatAsCurrency(value, true)}`
            }
            separator=": "
          />
          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="1" y2="0">
              <stop
                offset={offset}
                stopColor={positionRedColor}
                stopOpacity={1}
              />
              <stop
                offset={offset}
                stopColor={positionGreenColor}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            name="Gamma"
            dataKey="gamma"
            stroke={recentActivityColor}
            strokeWidth={5}
            fill="url(#splitColor)"
            fillOpacity={1}
          />
          {referenceLines}
          <Legend
            content={
              <div
                style={{
                  fontSize: 11,
                  color: theme.palette.text.secondary,
                }}
              >
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 30,
                    textAlign: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    justifyContent: 'space-around',
                  }}
                >
                  <div>Gamma Hedge Est {gammaHedgeEst}</div>
                  <div>Largest Gamma Strike {maxGammaStrikeDate}</div>
                  <div>Largest Delta Strike {maxDeltaStrikeDate}</div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    justifyContent: 'space-around',
                    marginTop: 15,
                  }}
                >
                  <div>
                    Recent Activity
                    <div
                      style={{
                        background: `linear-gradient(to left, ${d3.interpolateBlues(
                          1,
                        )}, ${d3.interpolateBlues(0.2)})`,
                        width: 125,
                        height: 12,
                        marginTop: 5,
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>0.0</div>
                      <div>0.3</div>
                    </div>
                  </div>

                  <div>
                    Position Size
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 15,
                        marginTop: 5,
                      }}
                    >
                      <div>
                        <div
                          style={{
                            background: `linear-gradient(to left, ${d3.interpolateReds(
                              1,
                            )}, ${d3.interpolateReds(0.2)})`,
                            width: 125,
                            height: 12,
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>0.0</div>
                          <div>0.1</div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            background: `linear-gradient(to left, ${d3.interpolateGreens(
                              1,
                            )}, ${d3.interpolateGreens(0.2)})`,
                            width: 125,
                            height: 12,
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>0.0</div>
                          <div>0.1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};
