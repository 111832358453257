import { Stack, Typography, alpha, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import LockIcon from '@mui/icons-material/Lock';
import { isSideDrawerExpandedState, userSubLevelState } from 'states';
import { SubLevel } from 'types';
import { SubLevelTitles } from 'util/shared';
import { isBloomberg } from '../../../util';

interface GroupHeaderProps {
  title: string;
  subLevelRequired: SubLevel;
}

const GroupHeader = ({ title, subLevelRequired }: GroupHeaderProps) => {
  const theme = useTheme();
  const isDrawerExpanded = useRecoilValue(isSideDrawerExpandedState);
  const userSubLevel: SubLevel = useRecoilValue(userSubLevelState);

  return (
    <Stack
      direction="row"
      gap={3}
      alignItems="center"
      sx={{
        opacity: isDrawerExpanded ? 100 : 0,
      }}
    >
      <Typography
        sx={{
          textTransform: 'uppercase',
          color: alpha(theme.palette.text.primary, 0.55),
          fontWeight: 300,
        }}
      >
        {title}
      </Typography>
      {!isBloomberg() && userSubLevel < subLevelRequired && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            backgroundColor: theme.palette.primary.main,
            padding: 1,
            borderRadius: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: 10,
              color: theme.palette.getContrastText(theme.palette.primary.main),
              fontWeight: 'bold',
            }}
          >
            {SubLevelTitles[subLevelRequired]}
          </Typography>
          <LockIcon
            sx={{
              fontSize: 10,
              color: theme.palette.getContrastText(theme.palette.primary.main),
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default GroupHeader;
