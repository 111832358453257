import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import { useCallback } from 'react';
import { IntradayGamma } from './components/IntradayGamma';

export const IntradayGammaPage = () => {
  return (
    <AccessProtectedPage
      productType={ProductType.TRACE}
      renderContent={useCallback(
        () => (
          <IntradayGamma productType={ProductType.TRACE} />
        ),
        [],
      )}
    />
  );
};
