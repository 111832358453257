import { Paper, Typography, Button, useTheme } from '@mui/material';
import IsInstitutionalModal from 'components/shared/IsInstitutionalModal';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userDetailsState } from 'states';

const DataAgreementContainer = () => {
  const [instModalOpen, setInstModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const userDetails = useRecoilValue(userDetailsState);

  return (
    <Paper
      sx={{
        display: 'flex',
        maxWidth: '600px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        padding: '24px',
        borderRadius: '8px',
      }}
    >
      <QuestionAnswerIcon
        sx={{
          color: theme.palette.text.secondary,
          fontSize: {
            xs: 24,
            md: 36,
          },
        }}
      />
      <Typography
        sx={{
          fontSize: {
            xs: 16,
            md: 18,
          },
        }}
      >
        {userDetails?.isInstitutional != null
          ? 'Your Market Data Exchange Agreement response indicates that you may qualify as an institutional trader which prevents us from giving you access to the live Options Feed data.'
          : 'Market Data Exchange Agreement is required before you can view this data.'}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          textTransform: 'none',
        }}
        onClick={() => setInstModalOpen(true)}
      >
        {userDetails?.isInstitutional == null
          ? 'Open Agreement'
          : 'Edit Response'}
      </Button>
      <IsInstitutionalModal open={instModalOpen} setOpen={setInstModalOpen} />
    </Paper>
  );
};

export default DataAgreementContainer;
