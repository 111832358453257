import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HiroChart from './trading_view/HiroChart';
import { convertData } from './convertData';
import {
  ET,
  getHiroUrlParams,
  getQueryDate,
  getSgData,
  initHiroData,
  isPreMarket,
  isZerohedge,
} from '../../../util';
import {
  LenseToCfg,
  PREMARKET_SYMBOLS,
  ROLLING_SECONDS_TO_LABEL,
} from 'config';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  endQueryDateState,
  hiroAlertsState,
  hiroETHState,
  hiroUpdatingTypeState,
  isMobileState,
  lastManuallySetDateState,
  productAccessState,
  selectedHiroData,
  spotgammaDataState,
  startQueryDateState,
  timezoneState,
  userIsLoggedInState,
  workerState,
} from '../../../states';
import {
  AlertCategory,
  AlertData,
  ProductType,
  HiroUpdatingType,
} from '../../../types';
import { optionTypeState, sumWindowState } from 'states';
import useLog from '../../../hooks/useLog';
import poll from 'util/poll';
import { Loader } from '../../shared';

// Stitch together data from multiple days

// keys must match the relevant keys for chart lenses in theme.ts
const LEGEND: any = {
  ...LenseToCfg,
  price: {
    label: 'Price',
  },
};

const LENSES = ['all', 'nextExp', 'retail'];
const HIRO_ALERTS = new Set([
  AlertCategory.HIRO_TOP_SIGNAL,
  AlertCategory.HIRO_BOTTOM_SIGNAL,
  AlertCategory.HIRO_NEG_FLOW,
  AlertCategory.HIRO_POS_FLOW,
  AlertCategory.HIRO_FLOW_CLOSE_SELL,
  AlertCategory.HIRO_FLOW_CLOSE_BUY,
]);

const filterHiroAlerts = (alerts: AlertData[]) =>
  (Array.isArray(alerts) ? alerts : []).filter((a) =>
    HIRO_ALERTS.has(a.category),
  );

export const useHiroData = (sym: string) => {
  const [hiroData, setHiroData] = useRecoilState(selectedHiroData);
  const includeHiroETH = useRecoilValue(hiroETHState);
  const optionType = useRecoilValue(optionTypeState);
  const setHiroAlerts = useSetRecoilState(hiroAlertsState);
  const rollingSeconds = useRecoilValue(sumWindowState);
  const [startDate, setStartDate] = useRecoilState(startQueryDateState);
  const [endDate, setEndDate] = useRecoilState(endQueryDateState);
  const currentTimezone = useRecoilValue(timezoneState);
  const [searchParams, _setSearchParams] = useSearchParams();
  const products = useRecoilValue(productAccessState);
  const userIsLoggedIn = useRecoilValue(userIsLoggedInState);
  const hasHiroAccess = useMemo(
    () => products.includes(ProductType.HIRO),
    [products],
  );
  const lastManuallySetDate = useRecoilValue(lastManuallySetDateState);
  const { fetchAPIWithLog } = useLog('HiroTradingViewContainer');
  const hiroUpdatingType = useRecoilValue(hiroUpdatingTypeState);
  const [sgData, setSgData] = useRecoilState(spotgammaDataState);

  const getStartEndDates = () => {
    let startDateNew = startDate;
    let endDateNew = endDate;

    if (searchParams.get('zoomTo') == null && isPreMarket()) {
      const allowPreOpen = PREMARKET_SYMBOLS.has(sym) && includeHiroETH;
      const today = getQueryDate(allowPreOpen);
      const hasSetDateToday = lastManuallySetDate?.isSame(
        dayjs().tz(ET),
        'day',
      );

      if (
        !hasSetDateToday &&
        !today.isSame(endDate, 'day') &&
        endDate.isSameOrAfter(getQueryDate(false), 'day')
      ) {
        // if showing premarket data and startdate is for yesterday, update to today
        // if we just set startdate to today but this new symbol does not support premarket data
        // then set startdate to yesterday
        if (
          (allowPreOpen && startDate.isSame(endDate, 'day')) ||
          (!allowPreOpen && startDate.isAfter(today, 'day'))
        ) {
          startDateNew = today;
        }
        endDateNew = today;
      }
    }

    return { start: startDateNew, end: endDateNew };
  };

  useEffect(() => {
    if (sym == null) {
      return;
    }
    async function fetchData() {
      const { start, end } = getStartEndDates();
      if (start != startDate || end != endDate) {
        setStartDate(start);
        setEndDate(end);
        return; // let the fetchData retrigger again
      }

      const startYMD = startDate.tz(ET).format('YYYY-MM-DD');
      const params = getHiroUrlParams(
        { syms: sym },
        startYMD,
        endDate.format('YYYY-MM-DD'),
      );
      const syms = encodeURIComponent(sym);
      const alertEnd = dayjs(endDate).tz(ET).add(1, 'day').format('YYYY-MM-DD');
      const alertUrl = `v1/alerts?syms=${syms}&start=${startYMD}&end=${alertEnd}`;
      const [rawHiroData, levelsData, alerts] = await Promise.all([
        hiroUpdatingType === HiroUpdatingType.POLLING
          ? fetchAPIWithLog(
              hasHiroAccess ? `v11/hiro?${params}` : `v1/free_hiro?${params}`,
            )
          : Promise.resolve(),
        getSgData(endDate, sym),
        userIsLoggedIn && hasHiroAccess
          ? fetchAPIWithLog(alertUrl)
          : Promise.resolve(),
      ]);
      if (hiroUpdatingType === HiroUpdatingType.POLLING) {
        setHiroData(
          convertData(rawHiroData[sym], includeHiroETH, currentTimezone),
        );
      }
      setSgData(levelsData);
      setHiroAlerts(filterHiroAlerts(alerts));
    }
    setHiroData(initHiroData());
    fetchData();
    // fetchAPIWithLog is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTimezone,
    endDate,
    hasHiroAccess,
    includeHiroETH,
    lastManuallySetDate,
    searchParams,
    setEndDate,
    setHiroAlerts,
    setHiroData,
    setStartDate,
    startDate,
    sym,
    userIsLoggedIn,
  ]);

  return {
    sym,
    hiroData,
    sgData,
    rollingSeconds,
    sigKey: 'mid_signal',
    showTotal: optionType === 'total',
    date: endDate,
    getStartEndDates,
  };
};

// FIXME:  This should be refactored to be typescript compliant
// I am not in love with the decomposition here.
const HiroTradingViewContainer = (props: any) => {
  const theme = useTheme();
  const { getStartEndDates, ...hiroProps } = useHiroData(props.sym);
  const startEnd = getStartEndDates();

  const { hiroData, showTotal } = hiroProps;
  const rollingSeconds = useRecoilValue(sumWindowState);
  // eslint-disable-next-line no-undef
  const startDate = useRecoilValue(startQueryDateState);
  const endDate = useRecoilValue(endQueryDateState);
  const setHiroAlerts = useSetRecoilState(hiroAlertsState);
  const worker = useRecoilValue(workerState);
  const hiroUpdatingType = useRecoilValue(hiroUpdatingTypeState);
  const isMobile = useRecoilValue(isMobileState);
  const userIsLoggedIn = useRecoilValue(userIsLoggedInState);

  const correctDates =
    startDate.isSame(startEnd.start, 'day') &&
    endDate.isSame(startEnd.end, 'day');

  const chartProps = {
    ...hiroProps,
    data: hiroData,
    showTotal,
    styling: LEGEND,
  };

  useEffect(() => {
    if (props.sym == null || worker == null || !userIsLoggedIn) {
      return;
    }
    const syms = encodeURIComponent(props.sym);
    const alertEnd = dayjs(endDate).add(1, 'day').format('YYYY-MM-DD');
    const startYMD = startDate.tz(ET).format('YYYY-MM-DD');
    const alertUrl = `v1/alerts?syms=${syms}&start=${startYMD}&end=${alertEnd}`;
    const opts = {
      url: alertUrl,
      onResponse: ({ json }: any) => setHiroAlerts(filterHiroAlerts(json)),
    };
    return poll(worker, opts);
  }, [endDate, props, startDate, setHiroAlerts, userIsLoggedIn, worker]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        width: isZerohedge() ? 'calc(100% + 30px)' : '100%',
        height: isMobile ? '400px' : '100%',
        marginLeft: isZerohedge() ? '-15px' : '0px',
        paddingTop: '5px',
        position: 'relative',
      }}
    >
      {
        // do not render HIRO until we've set the dates to what we actually want because bbg
        // will fetch a bunch of candle data using the wrong date at first then have to fetch it all over again when the date changes
        correctDates &&
        hiroData &&
        (hiroUpdatingType != HiroUpdatingType.POLLING ||
          hiroData.all.TOT.length > 0) ? (
          <div className="hiro-chart-container">
            <Box
              sx={{
                position: 'absolute',
                zIndex: 2,
                marginLeft: '70px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  zIndex: 2,
                  padding: '2px 6px',
                  background: theme.palette.background.paper,
                }}
              >
                <Typography
                  color={theme.palette.text.secondary}
                  sx={{ fontSize: '12px' }}
                >
                  {props.sym}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  justifyItems: 'flex-start',
                  p: '6px',
                  gap: '6px',
                  fontWeight: 500,
                }}
              >
                {showTotal ? (
                  Object.keys(LEGEND)
                    .filter((legendKey) =>
                      isZerohedge()
                        ? ['all', 'price'].includes(legendKey)
                        : true,
                    )
                    .map((k) => (
                      <Typography
                        key={k}
                        fontSize={'10px'}
                        color={(theme.palette.hiro.lenses as any)[k].total}
                      >
                        {LEGEND[k].label}
                      </Typography>
                    ))
                ) : (
                  <>
                    {LENSES.map((k) => (
                      <Box
                        key={k}
                        display="flex"
                        flexDirection="row"
                        color="white"
                        gap="5px"
                      >
                        <Typography fontSize={'10px'}>
                          {LEGEND[k].label}:
                        </Typography>
                        <Typography
                          fontSize={'10px'}
                          color={(theme.palette.hiro.lenses as any)[k].put}
                        >
                          Puts
                        </Typography>
                        <Typography
                          fontSize={'10px'}
                          color={(theme.palette.hiro.lenses as any)[k].call}
                        >
                          Calls
                        </Typography>
                      </Box>
                    ))}
                  </>
                )}

                <Typography
                  fontSize={'10px'}
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {ROLLING_SECONDS_TO_LABEL.get(rollingSeconds)}
                </Typography>
              </Box>
            </Box>
            <HiroChart {...chartProps} />
            <Typography
              sx={{
                fontSize: '9px',
                zIndex: 9, // sidebar is z index 10, this should be under
                width: '50px',
                textAlign: 'center',
                float: 'left',
                position: 'relative',
                marginTop: '-12px',
                left: '7px',
              }}
            >
              ↑ Price
            </Typography>
            <Typography
              sx={{
                fontSize: '9px',
                zIndex: 9,
                textAlign: 'center',
                width: '50px',
                float: 'right',
                position: 'relative',
                marginTop: '-23px',
                left: '-6px',
              }}
            >
              ↑ Delta Notional
            </Typography>
          </div>
        ) : (
          <Loader isLoading={true} />
        )
      }
    </Box>
  );
};

export default HiroTradingViewContainer;
