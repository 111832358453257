import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { updateSearch } from 'util/shared';

export const DEFAULT_TNS_SYM = '*';
export const DEFAULT_TNS_SYMBOLS = [DEFAULT_TNS_SYM];

const useTnSsymbols = () => {
  const [searchParams, setSearch] = useSearchParams();
  const symsParam = searchParams.get('syms');

  const symbols = useMemo(
    () =>
      symsParam
        ? decodeURIComponent(symsParam)
            .split(',')
            .map((symbol) => symbol.toUpperCase())
        : DEFAULT_TNS_SYMBOLS,
    [symsParam],
  );

  const updateSymbols = useCallback(
    (syms: string[]) => {
      setSearch(
        updateSearch({
          syms:
            syms && syms.length > 0
              ? syms.map((s: string) => s.toUpperCase()).join(',')
              : DEFAULT_TNS_SYM,
        }),
      );
    },
    [setSearch],
  );

  return {
    symbols,
    updateSymbols,
  };
};

export default useTnSsymbols;
