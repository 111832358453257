import { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Theme, useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  LabelList,
  TooltipProps,
  Legend,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import {
  EarningsImpliedMove,
  EarningsPeriodType,
  RawEarningsImpliedMove,
  TransformedEarnings,
} from 'types/resources';
import dayjs from 'dayjs';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from 'config';
import {
  chunkArray,
  formatAsPercentage,
  transformEarningsData,
  getEarningsImpliedMovePercentTicks,
  getEarningsPeriodFromString,
  getEarningsPeriodLabelText,
  getNoonIdx,
  getTimeFromEarnings,
  getFillColorFromEarningsPeriod,
} from '../../../util';
import useEarnings from 'hooks/resources/useEarnings';
import {
  earningsChartEndDateState,
  earningsChartStartDateState,
  showAllEarningsSymbolsState,
} from 'states/resources';
import {
  EarningsChartControls,
  EarningsChartSettings,
} from './EarningsChartSettings';
import { componentIsExpandedState, isMobileState, timezoneState } from 'states';
import { HomeEventsCalendarTab } from 'types';
import {
  ComponentHeader,
  ExpandableContentButton,
  Loader,
} from 'components/shared';
import { Center } from 'components/shared/Center';

const MAX_LABEL_ROWS = 3;
const renderCustomizedLabel = (props: {
  x?: any;
  y?: any;
  width?: any;
  value?: any;
  theme: Theme;
}) => {
  const { x, y, width, value, theme } = props;
  const gap = 6;
  const chunks = chunkArray(value ?? [], 2);
  const startX = x + width / 2;
  const startY = y <= 6 ? y + gap + 6 : y - gap;

  let renderedChunks = chunks;
  // If we exceed our max rows, add an ellipsis to the first row (they're
  // rendered bottom to top)
  if (chunks.length > MAX_LABEL_ROWS) {
    renderedChunks = chunks.splice(0, MAX_LABEL_ROWS);
    renderedChunks[0].splice(-1, 1, '\u2026'); // ellipsis
  }

  return (
    <g width={40}>
      {renderedChunks.map((chunk, idx) => {
        return (
          <text
            x={startX}
            y={startY - idx * 10 * (y <= 6 ? -1 : 1)}
            fill={theme.palette.resources.earnings.label}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              width: '20px',
              fontSize: '9px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {chunk.join(', ')}
          </text>
        );
      })}
    </g>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: '4px',
  ':nth-of-type(odd)': { backgroundColor: theme.palette.background.default },
  ':nth-of-type(even)': { backgroundColor: theme.palette.background.paper },
}));

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const currentTimezone = useRecoilValue(timezoneState);
  const theme = useTheme();
  if (active && payload && payload.length > 0) {
    // @ts-ignore
    const group: EarningsImpliedMove = payload[0].payload;
    return (
      <Box
        style={{
          zIndex: 100,
          padding: '4px',
          border: '1px solid black',
          background: theme.palette.background.default,
        }}
      >
        <Typography
          sx={{ fontWeight: 600 }}
          align="center"
          variant="h4"
          color="primary"
        >
          Date: {dayjs(group.day).format('MM-DD')}
        </Typography>
        <Table sx={{ padding: '20px', minWidth: 20 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '4px', fontWeight: 600 }}>
                Name
              </TableCell>
              <TableCell sx={{ padding: '4px', fontWeight: 600 }} align="right">
                Time
              </TableCell>
              <TableCell
                sx={{ padding: '4px', fontWeight: 700 }}
                align="right"
              >{`%\u00a0Move`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {group.bin
              .slice()
              .sort((a, b) => b.implied_move - a.implied_move)
              .map((r: RawEarningsImpliedMove, idx) => (
                <StyledTableRow
                  theme={theme}
                  key={`tooltip-earnings-row-${idx}`}
                >
                  <TableCell padding="checkbox">{r.sym}</TableCell>
                  <TableCell align="right" padding="checkbox">
                    {getTimeFromEarnings(r, currentTimezone)}
                  </TableCell>
                  <TableCell align="right" padding="checkbox">
                    {formatAsPercentage(r.implied_move, false)}
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return null;
};

const renderColorfulLegendText = (period: string, entry: any) => {
  const { color } = entry;
  return (
    <span style={{ color, fontSize: '11px' }}>
      {getEarningsPeriodLabelText(period)}
    </span>
  );
};

interface EarningsChartProps {
  chartOnly?: boolean;
}

export const EarningsChart = ({ chartOnly = false }: EarningsChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [errored, setErrored] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [earningsData, setEarningsData] = useState<
    RawEarningsImpliedMove[] | undefined
  >(undefined);
  const { getEarnings } = useEarnings();
  const isMobile = useRecoilValue(isMobileState);
  const showAllSymbols = useRecoilValue(showAllEarningsSymbolsState);
  const startDate = useRecoilValue(earningsChartStartDateState);
  const endDate = useRecoilValue(earningsChartEndDateState);
  const isExpanded = useRecoilValue(
    componentIsExpandedState(HomeEventsCalendarTab.EARNINGS_CHART),
  );

  async function generateChartData() {
    try {
      setLoading(true);
      setErrored(false);
      const data: RawEarningsImpliedMove[] = await getEarnings(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
      );
      const filteredData = data.filter((d) => d.implied_move > 0);

      setEarningsData(filteredData);
    } catch (err) {
      setErrored(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    generateChartData();
  }, [getEarnings, setEarningsData, startDate, endDate]);

  let ticks: Map<number, string> | null = null;
  let transformedEarningsData: TransformedEarnings[] | null = null;
  let unpartitioned: EarningsImpliedMove[] = [];
  if (earningsData != null) {
    unpartitioned = transformEarningsData(earningsData, showAllSymbols);
    const partitions: Record<string, TransformedEarnings> = {
      BMO: {
        period: EarningsPeriodType.BMO,
        members: [],
      },
      AMC: {
        period: EarningsPeriodType.AMC,
        members: [],
      },
      OTHER: { period: EarningsPeriodType.OTHER, members: [] },
    };
    for (const group of unpartitioned) {
      partitions[getEarningsPeriodFromString(group.period)].members.push(group);
    }
    transformedEarningsData = [
      partitions.BMO,
      partitions.AMC,
      partitions.OTHER,
    ];

    ticks = new Map(
      unpartitioned.map((g) => [getNoonIdx(g.idx), g.day.format('MM-DD')]),
    );
  }

  return (
    <Loader isLoading={loading}>
      {errored ? (
        <Center
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography onClick={generateChartData} sx={{ fontSize: '14px' }}>
            Unfortunately, there was an error. Click here to try again.
          </Typography>
        </Center>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            margin: 'auto',
            position: 'relative',
          }}
        >
          {!chartOnly && (
            <Box sx={{ textAlign: 'center', p: theme.spacing(2) }}>
              <Typography
                variant="h3"
                fontWeight="800"
                gutterBottom
                color="text.primary"
                textTransform="capitalize"
              >
                Earnings Implied Move
              </Typography>
              <EarningsChartControls />
            </Box>
          )}
          {chartOnly && (
            <ComponentHeader
              styleProps={{
                margin: 0,
                maxHeight: 25,
                position: isMobile ? 'unset' : 'absolute',
                right: !isMobile ? 0 : undefined,
                alignItems: 'center',
                zIndex: 1,
              }}
              buttons={
                <>
                  <EarningsChartSettings />
                  <ExpandableContentButton
                    type={HomeEventsCalendarTab.EARNINGS_CHART}
                  />
                </>
              }
            />
          )}
          {transformedEarningsData && (
            <div
              ref={ref}
              style={{
                height: isMobile && chartOnly && !isExpanded ? '400px' : '100%',
                maxHeight: isExpanded ? '100%' : 600,
                width: '100%',
              }}
            >
              <ResponsiveContainer>
                <ScatterChart margin={DEFAULT_CHART_MARGINS}>
                  <CartesianGrid
                    strokeDasharray="1 10"
                    stroke={theme.palette.gray}
                  />
                  <XAxis
                    type="number"
                    dataKey="idx"
                    name="Date"
                    tickFormatter={(idx: number) => {
                      return ticks!.get(idx) ?? '';
                    }}
                    label={{
                      value: 'Date',
                      ...DEFAULT_X_AXIS_STYLES,
                    }}
                    domain={[`dataMin - 1`, `dataMax + 1`]}
                    tick={{ fontSize: 11 }}
                    ticks={[...ticks!.keys()]}
                  />
                  <YAxis
                    type="number"
                    dataKey="implied_move"
                    name="Implied Move"
                    domain={[0, 'dataMax + 0.01']}
                    label={{
                      value: 'Earnings Implied Move (%)',
                      ...DEFAULT_Y_AXIS_STYLES,
                    }}
                    tickFormatter={(v: number) => formatAsPercentage(v, true)}
                    tick={{ fontSize: 11 }}
                    ticks={getEarningsImpliedMovePercentTicks(unpartitioned)}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  {transformedEarningsData.map((data, index) => (
                    <Scatter
                      key={`scatter-${index}`}
                      name={data.period}
                      data={data.members}
                      fill={getFillColorFromEarningsPeriod(data.period, theme)}
                    >
                      <LabelList
                        dataKey="syms"
                        content={({ ...props }) =>
                          renderCustomizedLabel({ ...props, theme })
                        }
                      />
                    </Scatter>
                  ))}
                  <Legend
                    verticalAlign="top"
                    height={isMobile ? 48 : 36}
                    formatter={(value, entry) =>
                      renderColorfulLegendText(value, entry)
                    }
                  />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          )}
        </Box>
      )}
    </Loader>
  );
};
