export type CompassPayload = {
  x: number;
  y: number;
  sym: string;
};

export enum CompassSeverity {
  VERY_SLIGHT = 0,
  SLIGHT = 1,
  MILD = 2,
  NEUTRAL_LOW = 3,
  NEUTRAL = 4,
  NEUTRAL_HIGH = 5,
  MODERATE = 6,
  HIGH = 7,
  VERY_HIGH = 8,
}

export enum StrategyCompassMode {
  PriceVol = 'price',
  SkewVol = 'skew',
}
