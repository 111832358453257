import { PreferencesTab } from 'types';
import { isBloomberg } from '../util';

export const EDITABLE_ACCOUNT_DETAILS = isBloomberg()
  ? []
  : [
      {
        field: 'first_name',
        label: 'First Name',
        type: 'string',
      },
      {
        field: 'last_name',
        label: 'Last Name',
        type: 'string',
      },
      {
        field: 'email',
        label: 'Email',
        type: 'email',
      },
    ];

export const PREFERENCES_LABEL_MAPPING = new Map(
  isBloomberg()
    ? [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Alerts, 'Alerts'],
      ]
    : [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Payments, 'Payments'],
        [PreferencesTab.Watchlists, 'Watchlists'],
        [PreferencesTab.Alerts, 'Alerts'],
      ],
);
