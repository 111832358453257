import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { isMobileState, watchlistsState } from 'states';
import useTnSsymbols from './useTnSsymbols';
import {
  tnsActiveWatchlistsIdsState,
  tnsColumnOrderState,
  tnsColumnSizesState,
  tnsColumnsVisibilityState,
  tnsFilterModelState,
} from 'states/optionsFeed';
import OptionsFeed from './OptionsFeed';
import { updateSearch } from 'util/shared';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { OptionsFeedColumnKey } from 'types/optionsFeed';

const OptionsFeedContainer = () => {
  const isMobile = useRecoilValue(isMobileState);
  const [, setSearch] = useSearchParams();
  const { symbols, updateSymbols } = useTnSsymbols();
  const activeWatchlistIds = useRecoilValue(tnsActiveWatchlistsIdsState);

  const watchlists = useRecoilValue(watchlistsState);
  const activeWatchlists =
    watchlists?.filter((w) => activeWatchlistIds.includes(w.id as number)) ??
    [];

  const watchlistSyms = new Set(activeWatchlists.flatMap((w) => w.symbols));

  useEffect(() => {
    // initialize syms
    const syms = watchlistSyms.size > 0 ? [...watchlistSyms] : symbols;
    updateSymbols(syms);
    setSearch(updateSearch({ sym: null }));
  }, []);

  return (
    <Box
      sx={{
        height: isMobile ? '80vh' : '87vh',
        paddingBottom: isMobile ? '60px' : '20px',
        width: '100%',
      }}
    >
      <OptionsFeed
        disabledColumnFilters={[OptionsFeedColumnKey.Time]}
        currentSyms={symbols}
        filterModelState={tnsFilterModelState}
        columnVisibilityState={tnsColumnsVisibilityState}
        columnOrderState={tnsColumnOrderState}
        columnSizingState={tnsColumnSizesState}
        activeWatchlistIdsState={tnsActiveWatchlistsIdsState}
      />
    </Box>
  );
};

export default OptionsFeedContainer;
