import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getAppcues } from 'util/shared';
import { SGTooltip } from '../core';

interface AppcuesInfoButtonProps {
  articleKey: string;
}

export const AppcuesInfoButton = ({
  articleKey,
}: AppcuesInfoButtonProps): JSX.Element => {
  const appcues = getAppcues();
  return (
    appcues && (
      <SGTooltip title="What Is This?">
        <IconButton
          onClick={() => appcues.show(articleKey)}
          size="medium"
          color="primary"
        >
          <InfoOutlinedIcon />
        </IconButton>
      </SGTooltip>
    )
  );
};
