import { openDB, DBSchema, IDBPDatabase } from 'idb';

const VER = 1; // bump this for schema changes
const DB_NAME = 'endpoint_cache';
const OBJECT_STORE = 'payloads';
export const DEFAULT_TTL = 60 * 60 * 1_000; // 1 hour
const EXPIRY_INDEX = 'by-expiry';
const WRITE_CHECK_THRESHOLD = 100;

let _inst: EndpointCache | null = null;

interface EndpointCacheSchema extends DBSchema {
  [OBJECT_STORE]: {
    key: string;
    value: EndpointCacheData;
    indexes: { [EXPIRY_INDEX]: number };
  };
}

export interface EndpointCacheData {
  value: any;
  expiry: number; // epoch milliseconds
}

export class EndpointCache {
  // @ts-ignore: db gets initialized outside constructor in async init()
  db: IDBPDatabase<EndpointCacheSchema>;
  dbInitPromise: Promise<IDBPDatabase<EndpointCacheSchema>> | null = null;
  writes: number = 0;

  static async inst() {
    if (_inst == null) {
      _inst = new EndpointCache();
      await _inst.init();
    } else if (_inst.dbInitPromise != null) {
      await _inst.dbInitPromise;
    }
    return _inst!;
  }

  async init() {
    this.dbInitPromise = openDB<EndpointCacheSchema>(DB_NAME, VER, {
      upgrade(db, _oldVersion, _newVersion, _txn, _evt) {
        const objectStore = db.createObjectStore(OBJECT_STORE);
        objectStore.createIndex(EXPIRY_INDEX, 'expiry');
      },
      blocked(_curVer, _blockedVer, event) {
        console.error(event);
      },
      blocking(_curVer, _blockedVer, event) {
        console.error(event);
      },
    });
    this.db = await this.dbInitPromise;
    await this.pruneCache();

    // Once the promise is complete, we can remove it to avoid future calls to
    // inst() from awaiting it
    this.dbInitPromise = null;
  }

  static async get(key: string) {
    try {
      const inst = await EndpointCache.inst();
      return await inst.get(key);
    } catch (err) {
      // TODO: Log to BetterStack
      console.error(err);

      // Capture any errors resulting from the cache stack. Just return null
      // and let the frontend fetch
      return null;
    }
  }

  async get(endpoint: string) {
    const result = await this.db.get(OBJECT_STORE, endpoint);
    if (result == null) {
      return null;
    } else if (result.expiry < Date.now()) {
      this.db.delete(OBJECT_STORE, endpoint);
      return null;
    }
    return result.value;
  }

  static async set(key: string, value: any, ttl?: number | null) {
    const inst = await EndpointCache.inst();
    const expiry = (ttl ?? DEFAULT_TTL) + Date.now();
    return await inst.set(key, { value, expiry });
  }

  async set(endpoint: string, obj: EndpointCacheData) {
    try {
      if (++this.writes > WRITE_CHECK_THRESHOLD) {
        this.pruneCache();
        this.writes = 0;
      }
      return await this.db.put(OBJECT_STORE, obj, endpoint);
    } catch (err) {
      // TODO: Log to BetterStack
      console.error(err);
    }
  }

  // Ensure that endpoints no longer being accessed are eventually deleted
  async pruneCache() {
    // Process rows in order of expiration
    const endpoints = await this.db.getAllKeysFromIndex(
      OBJECT_STORE,
      EXPIRY_INDEX,
    );
    for (const endpoint of endpoints) {
      const row = await this.get(endpoint);
      // Row could be null in a race condition with parallel calls to pruneCache with set(...) and init()
      if (row == null || row.expiry >= Date.now()) {
        break;
      }
      this.db.delete(OBJECT_STORE, endpoint);
    }
  }
}
export default EndpointCache;
