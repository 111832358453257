import { isAdmin, shadeColor } from '../../util';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import { FoundersNote } from '../foundersNotes/FoundersNote';
import dayjs from 'dayjs';
import { FoundersNoteWrapper } from '../foundersNotes/FoundersNoteWrapper';
import { useContent } from '../../hooks/admin';
import { TooltipDefinition, TooltipEditor } from './TooltipEditor';

import 'react-quill/dist/quill.snow.css';
import {
  AdminContentCategory,
  adminContentSpecialCategories,
  AdminWrittenContent,
  SGCustomTag,
  SGCustomTagType,
  sgCustomTagTypeToReadable,
} from '../../types';
import { NON_PROD } from '../../config';
import { SGHtmlEditor } from './SGHtmlEditor';
import { Loader } from 'components/shared';
import { useRecoilValue } from 'recoil';
import { timezoneState, userDetailsState } from 'states';

type AdminContentEditorProps = {
  editContent: AdminWrittenContent | null;
  onClose: (content: AdminWrittenContent) => void;
};

export const AdminContentEditor = ({
  editContent,
  onClose,
}: AdminContentEditorProps) => {
  const currentTimezone = useRecoilValue(timezoneState);
  const now = dayjs().tz(currentTimezone);
  const theme = useTheme();
  const { saveContent, publishContent, fetchHtmlForKey, previewContent } =
    useContent();

  const [snackbarOpen, setSnackbarOpen] = useState(
    editContent?.copiedFromKey != null,
  );
  const [html, setHtml] = useState(editContent?.html ?? '');
  const [previewHtml, setPreviewHtml] = useState('');
  const defaultTitle =
    editContent?.title ??
    `Founder's Note: ${now.format('ddd, MMMM DD, YYYY')} at ${
      now.hour() >= 10 ? '4:00 PM' : '7:00 AM'
    } ET`;
  const [title, setTitle] = useState(defaultTitle);
  const [lastTitle, setLastTitle] = useState(defaultTitle);
  const [category, setCategory] = useState<AdminContentCategory>(
    (editContent?.category as AdminContentCategory) ??
      AdminContentCategory.FoundersNote,
  );
  const [key, setKey] = useState<string | null>(editContent?.key ?? null);
  const [id, setId] = useState<string | null>(editContent?.id ?? null);
  const [lastId, setLastId] = useState<string | null>(editContent?.id ?? null);
  const [published, setPublished] = useState(
    (editContent?.published ?? false) && editContent?.copiedFromKey == null,
  );
  const [previewing, setPreviewing] = useState(false);
  const [loadingState, setLoadingState] = useState<string | null>('page');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [emailOnPublish, setEmailOnPublish] = useState(false);
  const [tooltips, setTooltips] = useState<TooltipDefinition[]>([]);
  const userDetails = useRecoilValue(userDetailsState);

  useEffect(() => {
    async function fetchHtml() {
      const key = editContent?.copiedFromKey ?? editContent?.key;
      if (key != null && editContent?.html == null) {
        setLoadingState('page');
        const originallyPublished = editContent?.published ?? published;
        const response = await fetchHtmlForKey(key, originallyPublished);
        if (category === 'tooltips' && response.tooltips != null) {
          setTooltips(response.tooltips);
        } else if (response.html != null) {
          setHtml(response.html);
        } else {
          alert(JSON.stringify(response.error));
        }
        setLoadingState(null);
      } else {
        setLoadingState(null);
      }
    }

    fetchHtml();
  }, []);

  if (!isAdmin(userDetails)) {
    return <></>;
  }

  const toContent = () => {
    return {
      html,
      title,
      category,
      key,
      published,
      emailOnPublish,
      id,
      extra: category === AdminContentCategory.Tooltips ? tooltips : undefined,
    };
  };

  const updateCategory = (newCat: AdminContentCategory) => {
    if (
      !adminContentSpecialCategories.includes(category) &&
      adminContentSpecialCategories.includes(newCat)
    ) {
      setTitle(newCat === AdminContentCategory.Tooltips ? '' : newCat);
      setId(null);
    } else if (
      adminContentSpecialCategories.includes(category) &&
      !adminContentSpecialCategories.includes(newCat)
    ) {
      setTitle(lastTitle);
      setId(lastId);
    }

    setCategory(newCat);
  };

  const insertCustomTag = (tag: SGCustomTagType) => {
    const addHtml = `${SGCustomTag}${tag}${SGCustomTag}`;
    setHtml(html + addHtml);
    setAnchorEl(null);
  };

  const save = async () => {
    if (title == null || title.length === 0) {
      return alert('Please set the title');
    }

    if (published) {
      if (
        !window.confirm(
          "Saving this will create a draft unpublished copy. To save these changes to the published version, click 'Save and Publish'.",
        )
      ) {
        return;
      }
    }
    setLoadingState('save');
    const response = await saveContent(toContent());
    setLoadingState(null);
    if (response.error != null) {
      return alert(JSON.stringify(response.error));
    }
    setKey(response.key);
    setId(response.id);
    setLastId(response.id);
  };
  const publish = async () => {
    if (
      !window.confirm(
        'Are you sure you want to publish? After publishing, this content will be visible to the public.',
      )
    ) {
      return;
    }
    setLoadingState('publish');
    const response = await publishContent(toContent());
    setLoadingState(null);
    if (response.error != null) {
      return alert(JSON.stringify(response.error));
    }
    setKey(response.key);
    setId(response.id);
    setLastId(response.id);

    if (response.published) {
      if (emailOnPublish && category === AdminContentCategory.FoundersNote) {
        if (response.emailSentError != null) {
          alert('ERROR SENDING EMAIL: ' + response.emailSentError);
        } else {
          alert('Successfully sent email (may take up to 30 mins).');
        }
      }
      setPublished(true);
      setEmailOnPublish(false);
      return alert(`Successfully published ${title}`);
    }

    alert(`Error publishing ${title}`);
  };

  const preview = async () => {
    setLoadingState('preview');
    const response = await previewContent(toContent());
    setLoadingState(null);
    if (response.error != null) {
      return alert(JSON.stringify(response.error));
    }
    if (response.key != null) {
      setKey(response.key);
    }
    setPreviewHtml(response.html);
    setPreviewing(true);
  };

  const sgToolbar = (
    <Box
      sx={{
        padding: '16px 8px 8px 8px',
        background: shadeColor(theme.palette.primary.main, -10),
        display: 'flex',
        gap: '10px',
      }}
    >
      <TextField
        label="Title"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          setLastTitle(e.target.value);
        }}
        sx={{ width: '350px' }}
        disabled={
          adminContentSpecialCategories.includes(category) &&
          category !== AdminContentCategory.Tooltips
        }
      />
      <FormControl>
        <FormHelperText sx={{ marginTop: '-15px' }}>Category</FormHelperText>
        <Select
          value={category}
          label="Category"
          onChange={(e) =>
            updateCategory(e.target.value as AdminContentCategory)
          }
          disabled={published}
        >
          {Object.values(AdminContentCategory).map((cat) => {
            return <MenuItem value={cat}>{cat}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <div>
        {!previewing && (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            sx={{ height: '35px', fontSize: '13px', marginTop: '5px' }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            Insert SG Element
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          open={anchorEl != null}
          onClose={() => setAnchorEl(null)}
        >
          {Object.values(SGCustomTagType).map((tagType) => (
            <MenuItem
              key={tagType}
              onClick={() => {
                insertCustomTag(tagType);
              }}
            >
              {sgCustomTagTypeToReadable(tagType)}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              emailOnPublish && category === AdminContentCategory.FoundersNote
            }
            color="secondary"
            disabled={
              category !== AdminContentCategory.FoundersNote || published
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEmailOnPublish(event.target.checked)
            }
          />
        }
        label="Send emails after publish"
      />

      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginLeft: 'auto',
          order: 2,
        }}
      >
        {!previewing && (
          <LoadingButton
            loading={loadingState === 'preview'}
            variant="contained"
            color={previewing ? undefined : 'secondary'}
            sx={{ height: '35px', fontSize: '13px', marginTop: '5px' }}
            onClick={() => preview()}
            disableElevation
          >
            Save and Preview
          </LoadingButton>
        )}

        {previewing && (
          <LoadingButton
            loading={loadingState === 'publish'}
            sx={{
              height: '35px',
              fontSize: '13px',
              marginTop: '5px',
              whiteSpace: 'nowrap',
            }}
            variant="contained"
            color="secondary"
            onClick={() => publish()}
            disableElevation
          >
            Save and Publish
          </LoadingButton>
        )}
        <LoadingButton
          loading={loadingState === 'save'}
          sx={{
            height: '35px',
            fontSize: '13px',
            marginTop: '5px',
            whiteSpace: 'nowrap',
          }}
          variant="contained"
          disableElevation
          onClick={() => save()}
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );

  return (
    <Loader isLoading={loadingState == 'page'}>
      <Box width={'100%'} height={'100%'} sx={{ overflow: 'auto' }}>
        <Button
          onClick={() => {
            if (previewing) {
              setPreviewing(false);
              setPreviewHtml('');
            } else {
              onClose(toContent());
            }
          }}
          sx={{ width: '100px' }}
        >
          {'<- Back'}
        </Button>

        {sgToolbar}
        {previewing ? (
          <FoundersNoteWrapper>
            <FoundersNote
              html={previewHtml}
              title={
                adminContentSpecialCategories.includes(category) ? '' : title
              }
              fromWordpress={false}
              id={key ?? undefined}
            />
          </FoundersNoteWrapper>
        ) : (
          <Box height={'calc(100% - 100px)'} width={'100%'}>
            {category === AdminContentCategory.Tooltips ? (
              <TooltipEditor
                initialTooltips={tooltips}
                onChange={(newTooltips) => setTooltips(newTooltips)}
              />
            ) : (
              [
                <SGHtmlEditor
                  html={html}
                  onHtmlChange={(newHtml) => setHtml(newHtml)}
                />,
                NON_PROD && ( // show html debug in non-prod
                  <textarea
                    style={{
                      width: '100%',
                      height: '25%',
                      background: '#ccc',
                      color: '#000',
                    }}
                    disabled
                    value={html}
                  />
                ),
              ]
            )}
          </Box>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="success"
            sx={{ fontSize: '16px' }}
          >
            Successfully copied note contents into this new note.
          </Alert>
        </Snackbar>
      </Box>
    </Loader>
  );
};
