import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';

import SettingsPopout from '../../shared/SettingsPopout';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';
import {
  currentStatsState,
  iVolErrorState,
  iVolLoadingState,
  fixedStrikeColumnDataState,
  fixedStrikeCurrentDataState,
  fixedStrikeCurrentGreeksState,
  matrixCompareDate,
  matrixCurrentDate,
  matrixExpirationRangeSelectorState,
  matrixExpirationRangeState,
  matrixPercentOTMRangeSelectorState,
  matrixPercentOTMRangeState,
  matrixPrevExpiryMapState,
  matrixZoomPropsState,
  matrixZoomState,
  pruneColumnsPercentSelectorState,
  pruneColumnsPercentState,
  pruneRowsPercentSelectorState,
  pruneRowsPercentState,
  showHighlightsState,
  matrixDataModeState,
  currentStatsLookbackState,
  showHistoricalDataState,
} from 'states/iVol';
import { useCallback, useMemo, useState } from 'react';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {
  getCurrentDate,
  getDefaultSlotProps,
  getQueryDate,
  isBusinessDay,
  isMarketOpen,
  isMarketOpenOnDate,
  nextBusinessDay,
  prevBusinessDayOpenMarket,
} from 'util/shared';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import {
  FixedStrikeZoomLevel,
  IVolSettingsProps,
  MATRIX_EXPIRATIONS_RANGE_MAPPING,
  MATRIX_PERCENT_OTM_RANGE_MAPPING,
  MatrixDataMode,
  MatrixExpirationsRange,
  MatrixPercentOTMRange,
  PRUNE_PERCENT_MAPPING,
  PrunePercentRange,
  RawGreeksDataMap,
  RawStatsData,
} from 'types';
import {
  DEFAULT_STATS_LOOKBACK,
  MATRIX_DATA_SETTING_TOOLTIPS,
  MATRIX_SETTINGS_ZOOMED_NORMAL,
  MATRIX_SETTINGS_ZOOMED_OUT,
  MIN_QUERY_DATE,
  buildMatrixTableData,
  getExpStatsMap,
} from 'util/iVol';
import InfoPopper from '../InfoPopper';
import { isMobileState } from 'states';
import StatsLookbackSettings from '../StatsLookbackSetting';

const FixedStrikeControls = ({ selectedSym }: IVolSettingsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const defaultSlotProps = getDefaultSlotProps(theme, isMobile);
  const [currentDate, setCurrentDate] = useRecoilState(matrixCurrentDate);
  const [compareDate, setCompareDate] = useRecoilState(matrixCompareDate);
  const loading = useRecoilValue(iVolLoadingState);
  const error = useRecoilValue(iVolErrorState);
  const [matrixDataMode, setMatrixDataMode] =
    useRecoilState(matrixDataModeState);
  const [showHighlights, setShowHighlights] =
    useRecoilState(showHighlightsState);

  const [showHistoricalData, setShowHistoricalData] = useRecoilState(
    showHistoricalDataState,
  );

  const [fixedStrikeCurrentGreeks, setFixedStrikeCurrentGreeks] =
    useRecoilState(fixedStrikeCurrentGreeksState);

  const currentStats = useRecoilValue(currentStatsState);
  const currentStatsLookback = useRecoilValue(currentStatsLookbackState);

  const statsDataObj: RawStatsData | null =
    currentStats &&
    (currentStats[currentStatsLookback] ??
      currentStats[DEFAULT_STATS_LOOKBACK]);

  const [matrixPrevExpiryMap, setMatrixPrevExpiryMapState] = useRecoilState(
    matrixPrevExpiryMapState,
  );

  const setMatrixData = useSetRecoilState(fixedStrikeCurrentDataState);
  const setMatrixColumnData = useSetRecoilState(fixedStrikeColumnDataState);

  const [currentDateHelperText, setCurrentDateHelpText] = useState<
    string | null
  >(null);
  const [compareDateHelperText, setCompareDateHelperText] = useState<
    string | null
  >(null);

  const [matrixZoomLevel, setMatrixZoomLevel] = useRecoilState(matrixZoomState);
  const setZoomProps = useSetRecoilState(matrixZoomPropsState);

  const [matrixExpirationRange, setMatrixExpirationRange] = useRecoilState(
    matrixExpirationRangeState,
  );
  const [matrixPercentOTMRange, setMatrixPercentOTMRange] = useRecoilState(
    matrixPercentOTMRangeState,
  );

  const [matrixExpirationRangeOpen, setMatrixExpirationRangeOpen] =
    useRecoilState(matrixExpirationRangeSelectorState);

  const [matrixPercentOTMRangeOpen, setMatrixPercentOTMRangeOpen] =
    useRecoilState(matrixPercentOTMRangeSelectorState);

  const [pruneRowsPercentSelectorOpen, setPruneRowsPercentSelectorOpen] =
    useRecoilState(pruneRowsPercentSelectorState);

  const [pruneColumnsPercentSelectorOpen, setPruneColumnsPercentSelectorOpen] =
    useRecoilState(pruneColumnsPercentSelectorState);

  const [pruneColumnsPercent, setPruneColumnsPercent] = useRecoilState(
    pruneColumnsPercentState,
  );
  const [pruneRowsPercent, setPruneRowsPercent] = useRecoilState(
    pruneRowsPercentState,
  );

  const handleExpirationChange = (event: SelectChangeEvent) => {
    setMatrixExpirationRange(event.target.value as MatrixExpirationsRange);
  };

  const handlePercentOTMChange = (event: SelectChangeEvent) => {
    setMatrixPercentOTMRange(event.target.value as MatrixPercentOTMRange);
  };

  const handlePruneRowsChange = (event: SelectChangeEvent) => {
    setPruneRowsPercent(event.target.value as PrunePercentRange);
  };

  const handlePruneColumnsChange = (event: SelectChangeEvent) => {
    setPruneColumnsPercent(event.target.value as PrunePercentRange);
  };

  const { getCurrentGreeksData, getDailyGreeksData } = useImpliedVolatility();

  const maxCompareDate = prevBusinessDayOpenMarket();
  const maxCurrentDate = getQueryDate(true);

  const isSelectedDateValid = useCallback((date: Dayjs, maxDate: Dayjs) => {
    return date?.isValid() && date?.isBefore(nextBusinessDay(maxDate), 'day');
  }, []);

  const isCompareMode = useMemo(
    () => matrixDataMode === MatrixDataMode.COMPARE_MODE,
    [matrixDataMode],
  );

  const isStatsMode = useMemo(
    () => matrixDataMode === MatrixDataMode.STATS_MODE,
    [matrixDataMode],
  );

  const isSkewPremMode = useMemo(
    () => matrixDataMode === MatrixDataMode.SKEW_PREMIUM_MODE,
    [matrixDataMode],
  );

  const onPrevTradeDateChange = useCallback(
    async (newValue: Dayjs | null) => {
      if (newValue && isSelectedDateValid(newValue, maxCompareDate)) {
        // update selected date
        setCompareDate(newValue);
        const formattedDate: string = newValue.format('YYYY-MM-DD');
        // fetch the relevant greeks based on the selected date
        const rawData: RawGreeksDataMap | null = await getDailyGreeksData(
          formattedDate,
          selectedSym,
        );
        if (rawData && statsDataObj) {
          const expStatsMap = getExpStatsMap(
            statsDataObj,
            fixedStrikeCurrentGreeks,
          );

          // rebuild table with updated prev raw greeks data map
          const { matrixData, matrixColumnData } = buildMatrixTableData(
            currentDate,
            fixedStrikeCurrentGreeks,
            isCompareMode,
            rawData,
            expStatsMap,
          );

          setMatrixData(matrixData);
          setMatrixColumnData(matrixColumnData);
          setMatrixPrevExpiryMapState(rawData);
          setCompareDateHelperText(null);
        } else {
          setCompareDateHelperText(
            'Failed to fetch data for your selected date. Please try again or choose a different date!',
          );
        }
      } else {
        setCompareDateHelperText(
          'Invalid compare date selection. Please try again!',
        );
      }
    },
    [
      isSelectedDateValid,
      maxCompareDate,
      currentDate,
      setCompareDate,
      getDailyGreeksData,
      selectedSym,
      fixedStrikeCurrentGreeks,
      statsDataObj,
      isCompareMode,
      setMatrixData,
      setMatrixColumnData,
      setMatrixPrevExpiryMapState,
    ],
  );

  const onCurrentTradeDateChange = useCallback(
    async (newValue: Dayjs | null) => {
      if (newValue && isSelectedDateValid(newValue, maxCurrentDate)) {
        // update selected date
        setCurrentDate(newValue);
        const formattedDate: string = newValue.format('YYYY-MM-DD');
        const rawData: RawGreeksDataMap | null = newValue.isSame(
          getCurrentDate(),
          'day',
        )
          ? await getCurrentGreeksData(selectedSym)
          : await getDailyGreeksData(formattedDate, selectedSym);
        if (rawData && statsDataObj) {
          const expStatsMap = getExpStatsMap(statsDataObj, rawData);

          // rebuild table with updated current raw greeks data map
          const { matrixData, matrixColumnData } = buildMatrixTableData(
            newValue,
            rawData,
            isCompareMode,
            matrixPrevExpiryMap,
            expStatsMap,
          );

          setMatrixData(matrixData);
          setMatrixColumnData(matrixColumnData);
          setCurrentDateHelpText(null);
          setFixedStrikeCurrentGreeks(rawData);
        } else {
          setCurrentDateHelpText(
            'Failed to fetch data for your selected date. Please try again or choose a different date!',
          );
        }
      } else {
        setCurrentDateHelpText('Invalid date selection. Please try again!');
      }
    },
    [
      isSelectedDateValid,
      maxCurrentDate,
      setCurrentDate,
      getCurrentGreeksData,
      selectedSym,
      statsDataObj,
      getDailyGreeksData,
      isCompareMode,
      matrixPrevExpiryMap,
      setMatrixData,
      setMatrixColumnData,
    ],
  );

  const getNeighborDate = (date: Dayjs) => {
    const prevBusinessDay = prevBusinessDayOpenMarket(date);
    const followingBusinessDay = nextBusinessDay(date);

    if (
      prevBusinessDay.isSame(MIN_QUERY_DATE, 'day') ||
      prevBusinessDay.isAfter(MIN_QUERY_DATE, 'day')
    ) {
      return prevBusinessDay;
    }

    if (
      followingBusinessDay.isSame(MIN_QUERY_DATE, 'day') ||
      followingBusinessDay.isBefore(maxCompareDate, 'day')
    ) {
      return followingBusinessDay;
    }

    return date;
  };

  const onCompareModeToggle = useCallback(
    async (_e: any, checked: boolean) => {
      onToggleDataSettings(MatrixDataMode.COMPARE_MODE, checked);
      if (checked) {
        let correctCompareDate = compareDate;
        if (currentDate.isSame(MIN_QUERY_DATE, 'day')) {
          onCurrentTradeDateChange(nextBusinessDay(MIN_QUERY_DATE));
        } else {
          if (
            showHistoricalData &&
            (compareDate.isSame(currentDate, 'day') ||
              compareDate.isAfter(currentDate, 'day'))
          ) {
            correctCompareDate = getNeighborDate(currentDate);
            setCompareDate(correctCompareDate);
          }
        }
        const formattedDate: string = correctCompareDate.format('YYYY-MM-DD');
        // fetch the relevant greeks based on the selected date
        const rawData: RawGreeksDataMap | null = await getDailyGreeksData(
          formattedDate,
          selectedSym,
        );

        // raw data is compare date daily greeks data
        if (rawData) {
          const expStatsMap =
            statsDataObj && getExpStatsMap(statsDataObj, rawData);

          // rebuild table with updated current raw greeks data map
          const { matrixData, matrixColumnData } = buildMatrixTableData(
            currentDate,
            fixedStrikeCurrentGreeks,
            checked,
            rawData,
            expStatsMap,
          );

          setMatrixData(matrixData);
          setMatrixColumnData(matrixColumnData);

          // update prev raw greeks data map
          setMatrixPrevExpiryMapState(rawData);
        }
      }
    },
    [
      compareDate,
      setCompareDate,
      getDailyGreeksData,
      selectedSym,
      currentDate,
      fixedStrikeCurrentGreeks,
      statsDataObj,
      setMatrixData,
      setMatrixColumnData,
      setMatrixPrevExpiryMapState,
    ],
  );

  const onHistoricalModeToggle = useCallback(
    async (_e: any, checked: boolean) => {
      setShowHistoricalData(checked);

      if (
        checked &&
        (compareDate.isSame(currentDate, 'day') ||
          compareDate.isAfter(currentDate, 'day'))
      ) {
        setCompareDate(getNeighborDate(currentDate));
      }
      const formattedDate = currentDate.format('YYYY-MM-DD');

      const rawData = !checked
        ? await getCurrentGreeksData(selectedSym)
        : await getDailyGreeksData(formattedDate, selectedSym, isMarketOpen());

      // If raw data was fetched successfully, process and set it
      if (rawData && statsDataObj) {
        const expStatsMap = getExpStatsMap(statsDataObj, rawData);
        const { matrixData, matrixColumnData } = buildMatrixTableData(
          currentDate,
          rawData,
          isCompareMode,
          matrixPrevExpiryMap,
          expStatsMap,
        );

        setMatrixData(matrixData);
        setMatrixColumnData(matrixColumnData);
        setCurrentDateHelpText(null);
        setFixedStrikeCurrentGreeks(rawData);
      }
    },
    [
      compareDate,
      setCompareDate,
      getDailyGreeksData,
      getCurrentGreeksData,
      selectedSym,
      currentDate,
      statsDataObj,
      setMatrixData,
      setMatrixColumnData,
      setCurrentDateHelpText,
      setFixedStrikeCurrentGreeks,
      isMarketOpen,
      getNeighborDate,
    ],
  );

  const onToggleDataSettings = (type: MatrixDataMode, checked: boolean) => {
    if (checked) {
      setMatrixDataMode(type);
    } else {
      setMatrixDataMode(MatrixDataMode.DEFAULT);
    }
  };

  const onStatsLookbackChange = (newStatsData: RawStatsData) => {
    const expStatsMap = getExpStatsMap(newStatsData, fixedStrikeCurrentGreeks);

    // rebuild table with updated current raw greeks data map
    const { matrixData, matrixColumnData } = buildMatrixTableData(
      currentDate,
      fixedStrikeCurrentGreeks,
      isCompareMode,
      matrixPrevExpiryMap,
      expStatsMap,
    );

    setMatrixData(matrixData);
    setMatrixColumnData(matrixColumnData);
  };

  const statsLookbackComponent = useMemo(() => {
    if (!isStatsMode) {
      return null;
    }

    return <StatsLookbackSettings onLookbackChange={onStatsLookbackChange} />;
  }, [isStatsMode]);

  const compareModeToggle = useMemo(() => {
    return (
      <FormControlLabel
        sx={{ marginLeft: isMobile ? 0 : '10px' }}
        control={
          <Switch
            sx={{
              '& .MuiSwitch-track': {
                opacity: 0.25,
                backgroundColor: theme.palette.success.main,
              },
              '&.Mui-checked .MuiSwitch-track': {
                opacity: 1,
              },
            }}
            color="success"
            checked={isCompareMode}
            onChange={onCompareModeToggle}
          />
        }
        label={MatrixDataMode.COMPARE_MODE}
        labelPlacement={isMobile ? 'start' : 'top'}
      />
    );
  }, [isCompareMode, isMobile]);

  const showHistoricalDataToggle = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          sx={{
            cursor: 'default',
          }}
        >
          Current
        </Typography>
        <Tooltip
          title={
            showHistoricalData
              ? 'Displaying historical greeks data using market close prices'
              : 'Displaying latest greeks data live'
          }
          PopperProps={{ style: { zIndex: 10001 } }}
        >
          <Switch
            sx={{
              '& .MuiSwitch-track': {
                opacity: 0.25,
                backgroundColor: theme.palette.success.main,
              },
              '&.Mui-checked .MuiSwitch-track': {
                opacity: 1,
              },
            }}
            color="success"
            checked={showHistoricalData}
            onChange={onHistoricalModeToggle}
          />
        </Tooltip>

        <Typography
          sx={{
            cursor: 'default',
          }}
        >
          Historical
        </Typography>
      </Stack>
    );
  }, [showHistoricalData]);

  return (
    <Stack sx={{ textAlign: 'left' }} gap={4}>
      <Typography>Table View Settings</Typography>
      <Stack gap={5}>
        <ButtonGroup aria-label="outlined secondary button group">
          <Button
            startIcon={<CropFreeIcon />}
            sx={{
              fontFamily: 'SF Pro Display',
              backgroundColor:
                matrixZoomLevel === FixedStrikeZoomLevel.Normal
                  ? alpha(theme.palette.secondary.main, 0.25)
                  : 'transparent',
              '&:hover': {
                color: alpha(theme.palette.secondary.main, 1),
                backgroundColor:
                  matrixZoomLevel === FixedStrikeZoomLevel.Normal
                    ? alpha(theme.palette.secondary.main, 0.25)
                    : 'transparent',
                borderColor: alpha(theme.palette.secondary.main, 1),
              },
              fontSize: isMobile ? 12 : 14,
              textTransform: 'none',
              width: '50%',
            }}
            onClick={() => {
              setMatrixZoomLevel(FixedStrikeZoomLevel.Normal);
              setZoomProps(MATRIX_SETTINGS_ZOOMED_NORMAL);
            }}
          >
            {FixedStrikeZoomLevel.Normal}
          </Button>
          <Button
            startIcon={<ZoomOutMapIcon />}
            sx={{
              fontFamily: 'SF Pro Display',
              backgroundColor:
                matrixZoomLevel === FixedStrikeZoomLevel.ZoomedOut
                  ? alpha(theme.palette.secondary.main, 0.25)
                  : 'transparent',
              '&:hover': {
                color: alpha(theme.palette.secondary.main, 1),
                backgroundColor:
                  matrixZoomLevel === FixedStrikeZoomLevel.ZoomedOut
                    ? alpha(theme.palette.secondary.main, 0.25)
                    : 'transparent',
                borderColor: alpha(theme.palette.secondary.main, 1),
              },
              fontSize: isMobile ? 12 : 14,
              textTransform: 'none',
              width: '50%',
            }}
            onClick={() => {
              setMatrixZoomLevel(FixedStrikeZoomLevel.ZoomedOut);
              setZoomProps(MATRIX_SETTINGS_ZOOMED_OUT);
            }}
          >
            {FixedStrikeZoomLevel.ZoomedOut}
          </Button>
        </ButtonGroup>
        <Stack gap={6} width="100%">
          <Stack direction="row" gap={3} width="100%">
            <FormControl
              sx={{
                marginTop: '5px',
                width: '50%',
              }}
              size="small"
            >
              <InputLabel id="expiration-range-select-label">
                Expiration Range
              </InputLabel>
              <Select
                labelId="expiration-range-select-label"
                id="expirations-range-select"
                value={matrixExpirationRange.toString()}
                label="Expiration Range"
                onChange={handleExpirationChange}
                open={matrixExpirationRangeOpen}
                onOpen={() => setMatrixExpirationRangeOpen(true)}
                onClose={() => setMatrixExpirationRangeOpen(false)}
                size="small"
                input={
                  <OutlinedInput
                    style={{
                      fontSize: isMobile ? 12 : 14,
                    }}
                    sx={{
                      svg: {
                        color: theme.palette.text.primary,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: alpha(theme.palette.primary.main, 0.5),
                      },
                    }}
                    label="Expiration Range"
                  />
                }
                MenuProps={{
                  sx: {
                    zIndex: 10001,
                  },
                  PaperProps: {
                    style: {
                      maxHeight: '250px',
                    },
                  },
                }}
              >
                {Array.from(MATRIX_EXPIRATIONS_RANGE_MAPPING.entries()).map(
                  ([range, label]: [MatrixExpirationsRange, string]) => (
                    <MenuItem key={label} value={range}>
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>

            <FormControl
              sx={{
                marginTop: '5px',
                width: '50%',
              }}
              size="small"
            >
              <InputLabel id="percent-otm-select-label">
                Percent OTM Range
              </InputLabel>
              <Select
                labelId="percent-otm-select-label"
                id="percent-otm-select"
                value={matrixPercentOTMRange.toString()}
                label="Percent OTM Range"
                onChange={handlePercentOTMChange}
                open={matrixPercentOTMRangeOpen}
                onOpen={() => setMatrixPercentOTMRangeOpen(true)}
                onClose={() => setMatrixPercentOTMRangeOpen(false)}
                size="small"
                input={
                  <OutlinedInput
                    style={{
                      fontSize: isMobile ? 12 : 14,
                    }}
                    sx={{
                      svg: {
                        color: theme.palette.text.primary,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: alpha(theme.palette.primary.main, 0.5),
                      },
                    }}
                    label="Percent OTM Range"
                  />
                }
                MenuProps={{
                  sx: {
                    zIndex: 10001,
                  },
                  PaperProps: {
                    style: {
                      maxHeight: '250px',
                    },
                  },
                }}
              >
                {Array.from(MATRIX_PERCENT_OTM_RANGE_MAPPING.entries()).map(
                  ([range, label]: [MatrixPercentOTMRange, string]) => (
                    <MenuItem key={label} value={range}>
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={3} width="100%">
            <FormControl
              sx={{
                width: '50%',
              }}
              size="small"
            >
              <InputLabel id="prune-expirations-label">
                Prune Expirations
              </InputLabel>
              <Select
                labelId="prune-expirations-label"
                id="prune-expirations-select"
                value={pruneRowsPercent.toString()}
                label="Prune Expirations"
                onChange={handlePruneRowsChange}
                open={pruneRowsPercentSelectorOpen}
                onOpen={() => setPruneRowsPercentSelectorOpen(true)}
                onClose={() => setPruneRowsPercentSelectorOpen(false)}
                size="small"
                input={
                  <OutlinedInput
                    style={{
                      fontSize: isMobile ? 12 : 14,
                    }}
                    sx={{
                      svg: {
                        color: theme.palette.text.primary,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: alpha(theme.palette.primary.main, 0.5),
                      },
                    }}
                    label="Prune Expirations"
                  />
                }
                MenuProps={{
                  sx: {
                    zIndex: 10001,
                  },
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                    },
                  },
                }}
              >
                {Array.from(PRUNE_PERCENT_MAPPING.entries()).map(
                  ([range, label]: [PrunePercentRange, string]) => (
                    <MenuItem key={label} value={range}>
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>

            <FormControl
              sx={{
                width: '50%',
              }}
              size="small"
            >
              <InputLabel id="prune-strikes-label">Prune Strikes</InputLabel>
              <Select
                labelId="prune-strikes-label"
                id="prune-strikes-select"
                value={pruneColumnsPercent.toString()}
                label="Prune Strikes"
                onChange={handlePruneColumnsChange}
                open={pruneColumnsPercentSelectorOpen}
                onOpen={() => setPruneColumnsPercentSelectorOpen(true)}
                onClose={() => setPruneColumnsPercentSelectorOpen(false)}
                size="small"
                input={
                  <OutlinedInput
                    style={{
                      fontSize: isMobile ? 12 : 14,
                    }}
                    sx={{
                      svg: {
                        color: theme.palette.text.primary,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: alpha(theme.palette.primary.main, 0.5),
                      },
                    }}
                    label="Prune Strikes"
                  />
                }
                MenuProps={{
                  sx: {
                    zIndex: 10001,
                  },
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                    },
                  },
                }}
              >
                {Array.from(PRUNE_PERCENT_MAPPING.entries()).map(
                  ([range, label]: [PrunePercentRange, string]) => (
                    <MenuItem key={label} value={range}>
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>

      <Divider
        sx={{
          borderColor: theme.palette.getContrastText(
            theme.palette.background.default,
          ),
          marginTop: '5px',
          opacity: 0.5,
        }}
        flexItem
      />

      <Stack gap={3}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>Table Data Settings</Typography>
          <InfoPopper
            contents={[
              {
                label: 'Statistical Mode',
                description: MATRIX_DATA_SETTING_TOOLTIPS.statsMode,
              },
              {
                label: 'Statistical look back window',
                description: MATRIX_DATA_SETTING_TOOLTIPS.statsLookback,
              },
              {
                label: 'Skew Premium',
                description: MATRIX_DATA_SETTING_TOOLTIPS.skewPrem,
              },
              {
                label: 'Show Highlights',
                description: MATRIX_DATA_SETTING_TOOLTIPS.highlightsMode,
              },
              {
                label: 'Compare Mode',
                description: MATRIX_DATA_SETTING_TOOLTIPS.compareMode,
              },
              {
                label: 'Z-Score',
                description: MATRIX_DATA_SETTING_TOOLTIPS.zScore,
              },
              {
                label: 'Teal Gradient with No Toggles on',
                description: MATRIX_DATA_SETTING_TOOLTIPS.absoluteMode,
              },
            ]}
          />
        </Stack>

        <Grid container rowGap={2} spacing={2}>
          <Grid item xs={isMobile ? 4 : 3}>
            <FormControlLabel
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-track': {
                      opacity: 0.25,
                      backgroundColor: theme.palette.success.main,
                    },
                    '&.Mui-checked .MuiSwitch-track': {
                      opacity: 1,
                    },
                  }}
                  color="success"
                  checked={isStatsMode}
                  onChange={(_e, checked) =>
                    onToggleDataSettings(MatrixDataMode.STATS_MODE, checked)
                  }
                />
              }
              label={MatrixDataMode.STATS_MODE}
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={isMobile ? 4 : 3}>
            <FormControlLabel
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-track': {
                      opacity: 0.25,
                      backgroundColor: theme.palette.success.main,
                    },
                    '&.Mui-checked .MuiSwitch-track': {
                      opacity: 1,
                    },
                  }}
                  color="success"
                  checked={isSkewPremMode}
                  onChange={(_e, checked) =>
                    onToggleDataSettings(
                      MatrixDataMode.SKEW_PREMIUM_MODE,
                      checked,
                    )
                  }
                />
              }
              label={MatrixDataMode.SKEW_PREMIUM_MODE}
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={isMobile ? 4 : 3}>
            <FormControlLabel
              control={
                <Switch
                  disabled={isCompareMode}
                  sx={{
                    '& .MuiSwitch-track': {
                      opacity: 0.25,
                      backgroundColor: theme.palette.success.main,
                    },
                    '&.Mui-checked .MuiSwitch-track': {
                      opacity: 1,
                    },
                  }}
                  color="success"
                  checked={showHighlights}
                  onChange={(_e, checked) => setShowHighlights(checked)}
                />
              }
              label="Show Highlights"
              labelPlacement="top"
            />
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              {statsLookbackComponent}
            </Grid>
          )}

          <Grid item xs={isMobile ? 12 : 0.5}>
            <Divider
              sx={{
                borderColor: theme.palette.getContrastText(
                  theme.palette.background.default,
                ),
                marginTop: isMobile ? '5px' : 0,
                opacity: 0.5,
              }}
              orientation={isMobile ? 'horizontal' : 'vertical'}
            />
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              {showHistoricalDataToggle}
            </Grid>
          )}

          <Grid item xs={isMobile ? 12 : 2.5}>
            {compareModeToggle}
          </Grid>

          {!isMobile && (
            <Grid item xs={12}>
              {statsLookbackComponent}
            </Grid>
          )}
        </Grid>
      </Stack>

      <Stack gap={4}>
        {!isMobile && showHistoricalDataToggle}

        {(showHistoricalData || isCompareMode) && (
          <Stack direction="row" alignItems="center" gap={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {showHistoricalData && (
                <DatePicker
                  views={['day']}
                  label="Reference Date"
                  value={currentDate}
                  onChange={onCurrentTradeDateChange}
                  shouldDisableDate={(date: Dayjs) =>
                    !isBusinessDay(date) ||
                    !isMarketOpenOnDate(date) ||
                    (isCompareMode &&
                      (date.isSame(compareDate, 'day') ||
                        date.isBefore(compareDate, 'day')))
                  }
                  format="YYYY-MM-DD"
                  minDate={MIN_QUERY_DATE}
                  maxDate={maxCurrentDate}
                  slotProps={{
                    ...defaultSlotProps,
                    textField: {
                      ...defaultSlotProps.textField,
                      style: {
                        width: '50%',
                        fontSize: '14px',
                      },
                      helperText: currentDateHelperText,
                      error: error != null,
                    },
                  }}
                />
              )}

              {isCompareMode ? (
                <DatePicker
                  views={['day']}
                  label="Comparison Date"
                  value={compareDate}
                  onChange={onPrevTradeDateChange}
                  shouldDisableDate={(date: Dayjs) =>
                    !isBusinessDay(date) ||
                    !isMarketOpenOnDate(date) ||
                    (showHistoricalData &&
                      (date.isSame(currentDate, 'day') ||
                        date.isAfter(currentDate, 'day')))
                  }
                  format="YYYY-MM-DD"
                  minDate={MIN_QUERY_DATE}
                  maxDate={maxCompareDate}
                  slotProps={{
                    ...defaultSlotProps,
                    textField: {
                      ...defaultSlotProps.textField,
                      style: {
                        width: '50%',
                        fontSize: '14px',
                      },
                      helperText: compareDateHelperText,
                      error: error != null,
                    },
                  }}
                />
              ) : null}
            </LocalizationProvider>
          </Stack>
        )}
      </Stack>

      {loading ? (
        <CircularProgress
          style={{
            color: theme.palette.getContrastText(
              theme.palette.background.default,
            ),
            alignSelf: 'flex-end',
          }}
        />
      ) : null}
    </Stack>
  );
};

export const FixedStrikeTableSettings = ({
  selectedSym,
}: IVolSettingsProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const isMatrixExpiryRangeSelectorOpen = useRecoilValue(
    matrixExpirationRangeSelectorState,
  );
  const isMatrixPercentOTMSelectorOpen = useRecoilValue(
    matrixPercentOTMRangeSelectorState,
  );
  const isPruneRowsSelectorOpen = useRecoilValue(pruneRowsPercentSelectorState);
  const isPruneColumnsSelectorOpen = useRecoilValue(
    pruneColumnsPercentSelectorState,
  );
  return (
    <SettingsPopout
      title="Fixed Strike Table Settings"
      popperID="fixed-strike-controls"
      placement="bottom-end"
      externalMenuOpen={
        isMatrixExpiryRangeSelectorOpen ||
        isMatrixPercentOTMSelectorOpen ||
        isPruneRowsSelectorOpen ||
        isPruneColumnsSelectorOpen
      }
      sx={{
        width: isMobile ? '280px' : '400px',
      }}
    >
      <FixedStrikeControls selectedSym={selectedSym} />
    </SettingsPopout>
  );
};
