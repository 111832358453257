import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ET_UTC_OFFSET_MS } from '../../util';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { timezoneState } from '../../states';

export type ChartTooltipProps = {
  hidden?: boolean;
  displayData: { text: string; color?: string }[];
  time?: number;
  containerWidth: number;
  containerHeight: number;
};

const WIDTH = 250;

export const ChartTooltip = ({
  displayData,
  time,
  containerWidth,
  containerHeight,
}: ChartTooltipProps) => {
  const theme = useTheme();
  const timezone = useRecoilValue(timezoneState);

  const timeStr = time
    ? dayjs(time * 1000 - ET_UTC_OFFSET_MS)
        .tz(timezone)
        .format('M/D [at] H:mm:ss')
    : null;

  // min 10, max 14, scale according to container height
  const fontSize = Math.min(Math.max(containerHeight / 25, 10), 14);
  return (
    <Box
      sx={{
        position: 'absolute',
        top: `0px`,
        left: containerWidth - WIDTH - 80,
        width: WIDTH,
        textAlign: 'right',
        pointerEvents: 'none',
      }}
    >
      {[
        { text: timeStr, color: theme.palette.text.primary },
        ...displayData,
      ].map(({ text, color }) => {
        return (
          <Typography
            sx={{
              color,
              fontSize: fontSize,
              textAlign: 'right',
              display: 'block',
            }}
            key={text}
          >
            {text}
          </Typography>
        );
      })}
    </Box>
  );
};
