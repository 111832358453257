import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useZendesk from 'hooks/useZendesk';
import { ZENDESK_ARTICLE_ID_MAPPING } from 'config';
import { SGTooltip } from '../core';

interface InfoButtonProps {
  articleKey: string;
}

export const InfoButton = ({ articleKey }: InfoButtonProps) => {
  const { openZendesk } = useZendesk();
  const mapping = ZENDESK_ARTICLE_ID_MAPPING[articleKey];
  if (mapping == null) {
    return <></>;
  }

  return (
    <SGTooltip title="What Is This?">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          openZendesk(mapping);
        }}
        size="medium"
        color="primary"
      >
        <InfoOutlinedIcon />
      </IconButton>
    </SGTooltip>
  );
};
