import { AdminContentCategory, ProductType } from '../../types';
import { Button, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { currentProductTypeState, isMobileState } from '../../states';
import { SGTooltip } from '../core';
import { readableProductType } from '../../util';
import { useTheme } from '@mui/material/styles';
import { AdminStrategyCategoryForProductType } from '../../util/admin';

export const getOpenWindowCb = (category: AdminContentCategory) => {
  return () => {
    const newWindow = window.open(
      `/html/${category}`,
      category,
      'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes',
    );
    newWindow?.focus && newWindow?.focus();
  };
};

export const getAdminContentCategory = (
  currentProductType: ProductType | null,
) => {
  return currentProductType == null
    ? undefined
    : AdminStrategyCategoryForProductType.get(currentProductType);
};

export const HowToTradeButton = ({ button }: { button: boolean }) => {
  const currentProductType = useRecoilValue(currentProductTypeState);
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const category = getAdminContentCategory(currentProductType);

  if (category == null || isMobile) {
    return null;
  }

  return (
    <>
      <SGTooltip
        placement="right"
        title={`Learn how to trade with ${readableProductType(
          currentProductType,
        )}`}
      >
        {button ? (
          <Button
            sx={{
              textTransform: 'none',
              fontSize: isMobile ? '11px' : '12px',
              height: '30px',
              marginTop: '-4px',
              padding: '0px 6px',
            }}
            onClick={getOpenWindowCb(category)}
            variant="outlined"
          >
            Trading with {readableProductType(currentProductType)}
          </Button>
        ) : (
          <Typography
            sx={{
              marginTop: '-4px',
              whiteSpace: 'nowrap',
              fontSize: '12px',
              textDecoration: 'none',
              color: theme.palette.text.primary,
              justifyContent: 'flex-start',
            }}
          >
            Trading with {readableProductType(currentProductType)}
          </Typography>
        )}
      </SGTooltip>
    </>
  );
};
