import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  isMobileState,
  oiFullscreenState,
  oiHiroSymsState,
  oiIntradayFilterPrice,
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiIntradayPriceBoundsState,
  oiIntradayTimestampState,
  oiNegativeGammaColorState,
  oiPositiveGammaColorState,
  oiPriceCandleDurationState,
  oiScaleRangeState,
  oiSelectedLenseState,
  oiShowColorScaleState,
  oiShowContourLinesState,
  oiShowGexZeroDteState,
  oiShowKeyLevelsState,
  oiStatsLookbackDaysState,
  oiStrikeBarTypeState,
  screenHeightState,
  screenWidthWithoutSidebarState,
  timezoneState,
  todaysOpenArrState,
  workerState,
} from 'states';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  fetchRawAPI,
  formatAsCompactNumber,
  getDateFormatted,
  getQueryDate,
  isLatestTradingDay,
  nonProdDebugLog,
  predicateSearch,
} from 'util/shared';
import useWasmParquet from 'hooks/useWasmParquet';
import { readParquet } from 'parquet-wasm';
import * as arrow from 'apache-arrow';
import dayjs from 'dayjs';
import { Plot } from './Plot';
import {
  HiroLense,
  HiroOptionType,
  IntradayGammaLense,
  IntradayStrikeBarType,
  PriceCandleWithDateTime,
  PriceLineKey,
  ProcessingState,
  ProductType,
  ZoomData,
} from '../../types';
import { Loader } from '../../components';
import { Center } from '../../components/shared/Center';
import poll from '../../util/poll';
import {
  convertTwelveCandleDatetimes,
  getCandles,
  getContourData,
  getGexData,
  getGexRangeXAxis,
  getHiro,
  getHiroRange,
  getStatsPercentile,
  greekForLense,
  shouldShowAxisLabels,
} from '../../util/oi';
import useToast from '../../hooks/useToast';
import { IntradayGammaControls } from './IntradayGammaControls';
import useStreaming, {
  INCLUDE_STREAMING_DATA_TYPE,
} from '../../hooks/bloomberg/streaming/useStreaming';
import { capitalize } from 'lodash';
import { useLog, useSetSym } from '../../hooks';
import { getPriceLines, getSgData, sigHighLow } from '../../util';
import {
  HEATMAP_FIRST_AVAILABLE_DATE,
  IntradayFiltersAxisLabels,
  IntradayShowChartType,
} from '../../config/oi';
import useUserDetails from '../../hooks/user/useUserDetails';
import { TraceTimeSlider } from './TraceTimeSlider';

const DEFAULT_MAX_AGE_PADDING_MS = 10_000; // add 10 secs to max age to ensure there is data to poll
const DEFAULT_MAX_AGE_MS = 10 * 60 * 1_000; // 5 mins
const UPDATE_INTERVAL_MS = 30_000; // 30 secs
const END_CHART_X_PADDING = 50;

type IntradayGammaProps = {
  productType: ProductType;
};

const DIRECTIONAL_LENSES = new Set([
  IntradayGammaLense.DELTA_DIRECTIONAL,
  IntradayGammaLense.GAMMA_DIRECTIONAL,
  IntradayGammaLense.DELTA_END_DIFF,
]);

const INTRADAY_BAR_TITLES = new Map([
  [IntradayStrikeBarType.GAMMA, 'GEX by Strike'],
  [IntradayStrikeBarType.OI, 'OI by Strike'],
  [IntradayStrikeBarType.OI_NET, 'Net OI by Strike'],
]);

const VALID_SYMS = new Set(['SPX', 'VIX']);

export const IntradayGamma = ({ productType }: IntradayGammaProps) => {
  const { getWasmPromise } = useWasmParquet();
  const theme = useTheme();
  const { openToast } = useToast();
  const { getParam, searchParams } = useSetSym();
  const { fetchAPIWithLog } = useLog(productType);
  const { saveSgSettings } = useUserDetails();

  const worker = useRecoilValue(workerState);
  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const hiroSymsMap = useRecoilValue(oiHiroSymsState);
  const priceBounds = useRecoilValue(oiIntradayPriceBoundsState);
  const tz = useRecoilValue(timezoneState);
  const [timestamp, setTimestamp] = useRecoilState(oiIntradayTimestampState);
  const filterPriceRecoilVal = useRecoilValue(oiIntradayFilterPrice);
  const screenWidth = useRecoilValue(screenWidthWithoutSidebarState);
  const screenHeight = useRecoilValue(screenHeightState);
  const isMobile = useRecoilValue(isMobileState);
  const negativeTrendColor = useRecoilValue(oiNegativeGammaColorState);
  const positiveTrendColor = useRecoilValue(oiPositiveGammaColorState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const fullscreen = useRecoilValue(oiFullscreenState);

  const todaysOpenArr = useRecoilValue(todaysOpenArrState);
  const candleDuration = useRecoilValue(oiPriceCandleDurationState);
  const selectedLense = useRecoilValue(oiSelectedLenseState);
  const selectedScaleRange = useRecoilValue(oiScaleRangeState);
  const showKeyLevels = useRecoilValue(oiShowKeyLevelsState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const showColorScale = useRecoilValue(oiShowColorScaleState);
  const showContourLines = useRecoilValue(oiShowContourLinesState);
  const statsLookbackDays = useRecoilValue(oiStatsLookbackDaysState);

  const lastUpdateTimestampPrice = useRef(dayjs().valueOf());
  const lastUpdateTimestampHiro = useRef(dayjs().valueOf());
  const [useTwelveData, setUseTwelveData] = useState(false);
  const [sgData, setSgData] = useState<any>(null);
  const [gexLoading, setGexLoading] = useState(false);
  const [streamingApiLoading, setStreamingApiLoading] = useState(true);
  const [twelveDataLoading, setTwelveDataLoading] = useState(false);
  const [boundsStr, setBoundsStr] = useState<string | null>(null);
  const [lastDirectionalFilterCandle, setLastDirectionalFilterCandle] =
    useState<PriceCandleWithDateTime | undefined>(undefined);
  const [gexLoadingLastPolledTs, setGexLoadingLastPolledTs] = useState<
    dayjs.Dayjs | undefined
  >();
  const [pollAt, setPollAt] = useState<number | undefined>(undefined);
  const [gexPollAt, setGexPollAt] = useState<number | undefined>(undefined);
  const [processingState, setProcessingState] = useState<ProcessingState>(
    ProcessingState.FETCHING,
  );
  const [twelveCandles, setTwelveCandles] = useState<PriceCandleWithDateTime[]>(
    [],
  );
  const [table, setTable] = useState<arrow.Table | null>(null);
  const [gexTable, setGexTable] = useState<arrow.Table | null>(null);
  const [zoomData, setZoomData] = useState<ZoomData | undefined>();
  // use a map for stats so that we dont fetch stats for the same date more than once
  const [statsDataMap, setStatsDataMap] = useState<Map<string, any>>(new Map());
  const [hoverInfo, setHoverInfo] = useState<ReactNode>();
  const [plotHovering, setPlotHovering] = useState(false);
  const [tooltipHovering, setTooltipHovering] = useState(false);
  const [showChartType, setShowChartType] = useState(
    isMobile ? IntradayShowChartType.Heatmap : IntradayShowChartType.Both,
  );

  const selectedGreek = greekForLense(selectedLense);

  const intradaySym = useMemo(() => {
    let sym = getParam('sym')?.toUpperCase() ?? '';
    return VALID_SYMS.has(sym) ? sym : 'SPX';
  }, [searchParams]);

  const intradayDate = useMemo(() => {
    const param = getParam('date');
    const newDate = dayjs(param);
    if (
      param == null ||
      !newDate?.isValid() ||
      newDate.isBefore(HEATMAP_FIRST_AVAILABLE_DATE, 'date')
    ) {
      return getQueryDate(true);
    }

    return newDate;
  }, [searchParams]);

  const firstChartTs = useRef<number | null>(null);
  const [lastAddedCandleTs, setLastAddedCandleTs] = useState<
    number | undefined
  >();
  const [lastAddedHiroTs, setLastAddedHiroTs] = useState<number | undefined>();
  const hiroSym = useMemo(
    () => hiroSymsMap.get(intradaySym) ?? intradaySym,
    [hiroSymsMap, intradaySym],
  );

  const { getPrices, getRolledCandles } = useStreaming(true, {
    selectedLenses: [HiroLense.All],
    selectedOptionType: HiroOptionType.TOT,
    setChartLoading: setStreamingApiLoading,
    includeStreamingData:
      INCLUDE_STREAMING_DATA_TYPE.PRICE | INCLUDE_STREAMING_DATA_TYPE.HIRO,
    priceUpdateCallback: (_price, _lastPrice) => {
      const now = dayjs().valueOf();
      if (now - lastUpdateTimestampPrice.current < UPDATE_INTERVAL_MS) {
        return;
      }
      // updates every UPDATE_INTERVAL_MS
      // note that you will not be able to accurately access states here, only set them
      // this is because this is put in a socket onmessage handler which only gets set once on socket creation time
      // and does not update as state updates

      // need the ... here to clone getPrices so candles get set properly.
      // since getPrices uses a ref, without it, React would not recognize the change
      lastUpdateTimestampPrice.current = now;
      setLastAddedCandleTs(now);
    },
    hiroUpdateCallback: (_latestHiroVal: any, lense, option) => {
      const now = dayjs().valueOf();
      if (
        lense !== HiroLense.All ||
        option !== HiroOptionType.TOT ||
        now - lastUpdateTimestampHiro.current < UPDATE_INTERVAL_MS
      ) {
        return;
      }

      lastUpdateTimestampHiro.current = now;
      setLastAddedHiroTs(now);
    },
    sym: hiroSym,
    candleDurationSecs: candleDuration,
    showPremarket: true,
    showPostmarket: false,
    useCandlesForPrice: true,
    useDayjs: true,
    useUtcFakeOffset: false,
    startDate: intradayDate,
    endDate: intradayDate,
  });

  const getPricesFiltered = () => {
    const prices = getPrices();
    if (firstChartTs.current == null || prices == null) {
      return [];
    }

    const candles = [...prices];
    const firstIdx =
      predicateSearch(
        candles,
        (candle) => candle.time < firstChartTs.current! / 1_000,
      ) + 1;
    return candles.slice(firstIdx);
  };

  const getHiroFiltered = () => {
    if (firstChartTs.current == null) {
      return [];
    }
    const candles = [...getRolledCandles(HiroLense.All, HiroOptionType.TOT)];
    const firstIdx =
      predicateSearch(
        candles,
        (candle) => candle.time < firstChartTs.current! / 1_000,
      ) + 1;
    return candles.slice(firstIdx);
  };

  const timestamps = useMemo(() => {
    if (table == null) {
      return [];
    }
    const ts = new Set(table.toArray().map((e) => e.timestamp));
    return [...ts].sort().map((t) => dayjs.utc(t).tz(tz));
  }, [table, tz]);

  const isLatestTimestamp =
    timestamps.length > 0 &&
    timestamp != null &&
    timestamps[timestamps.length - 1].isSame(timestamp, 'second');
  const filterPrice = isLatestTimestamp ? false : filterPriceRecoilVal;
  // if we have space, increase the width, but keep the aspect ratio fixed and some x padding if possible
  const width = screenWidth * (isMobile ? 1 : 0.95);
  // TODO: make height calculation dynamic and not hardcoded
  let height = isMobile
    ? screenHeight - 350
    : screenHeight * 0.95 -
      200 - // (top bar + title)
      (productType === ProductType.INTERNAL_OPEN_INTEREST ? 100 : 0); // extra row of settings
  height -= fullscreen ? 0 : 60; // support button

  const getTzOffsetMs = () => {
    const browserOffset = dayjs().utcOffset(); // in mins
    const userTzOffset = dayjs().tz(tz).utcOffset(); // in mins
    return (userTzOffset - browserOffset) * 60 * 1_000;
  };

  const getParquetUrl = (action: string) => {
    const sym = encodeURIComponent(intradaySym);
    const params = new URLSearchParams({ sym });
    if (intradayDate != null) {
      params.append('date', getDateFormatted(intradayDate));
    }
    return `v1/oi/${action}?${params.toString()}`;
  };

  const parquetUrl = useMemo(() => {
    const action =
      greekForLense(selectedLense) === 'delta'
        ? 'intradayDelta'
        : 'intradayGamma';
    return getParquetUrl(action);
  }, [selectedLense, intradayDate, intradaySym]);

  const maxAgeMsFromHeaders = (headers: any) => {
    if (headers == null) {
      return DEFAULT_MAX_AGE_MS + DEFAULT_MAX_AGE_PADDING_MS;
    }

    const maxAgeStr = headers.get
      ? headers.get('cache-control')
      : headers['cache-control'];
    const maxAgeNumStr = /max-age=(\d+)/.exec(maxAgeStr)?.[1];
    const newMaxAge = maxAgeNumStr == null ? NaN : parseFloat(maxAgeNumStr);
    return (
      (isNaN(newMaxAge) ? DEFAULT_MAX_AGE_MS : newMaxAge * 1_000) +
      DEFAULT_MAX_AGE_PADDING_MS
    );
  };

  const pollAtFromHeaders = (headers: any) =>
    dayjs().valueOf() + maxAgeMsFromHeaders(headers);

  const handleLatestResponseOI = useCallback(
    async ({ data, headers }: any) => {
      if (data == null) {
        openToast({
          message: 'There was an error updating the open interest data.',
          type: 'error',
        });
        return;
      }

      const arrowTable = readParquet(new Uint8Array(data));
      const tableAppend = arrow.tableFromIPC(arrowTable.intoIPCStream());
      const array = tableAppend.toArray();
      const lastTS = array[array.length - 1].timestamp;
      const polledLastTs = dayjs.utc(lastTS).tz(tz);
      nonProdDebugLog(
        'last timestamp received from polling oi: ',
        polledLastTs,
      );
      // if the last timestamp we've polled and received is later than the current timestamp
      // and the current timestamp is the latest timestamp in the timestamps array
      // set the current timestamp to the last polled timestamp
      if (
        polledLastTs?.isAfter(timestamp) &&
        timestamp?.isSameOrAfter(timestamps[timestamps.length - 1])
      ) {
        nonProdDebugLog(
          'current timestamp below. setting timestamp to polledLastTs',
          timestamp,
          polledLastTs,
        );
        setTimestamp(polledLastTs);
      }

      setTable((oldTable) => oldTable?.concat(tableAppend) ?? null);

      const newPollAt = pollAtFromHeaders(headers);
      setPollAt(newPollAt);

      nonProdDebugLog(
        `received polling data for OI. polling at: ${dayjs(newPollAt)}`,
      );
    },
    [timestamp, timestamps],
  );

  const fetchSgData = async () => {
    // not worth showing a loading spinner, but invalidate prior sg data first so we dont show incorrect levels
    setSgData(null);
    const newData = await getSgData(intradayDate, intradaySym);
    setSgData(newData);
  };

  // do not rely on any states here. setters are fine though
  const handleLatestResponseGex = async ({ data, headers }: any) => {
    setGexLoading(false);
    if (data == null) {
      openToast({
        message: 'There was an error updating the gamma exposure data.',
        type: 'error',
      });
      return;
    }

    const arrowTable = readParquet(new Uint8Array(data));
    const tableAppend = arrow.tableFromIPC(arrowTable.intoIPCStream());
    setGexTable((oldTable) => oldTable?.concat(tableAppend) ?? null);

    const newPollAt = pollAtFromHeaders(headers);
    setGexPollAt(newPollAt);

    nonProdDebugLog(
      `received polling data for gex. now polling at: ${dayjs(newPollAt)}`,
    );
  };

  useEffect(() => {
    const pollIn = pollAt == null ? -1 : pollAt - dayjs().valueOf();
    if (pollIn < 0) {
      return;
    }

    nonProdDebugLog('setting up OI poller to poll in', pollIn);

    return poll(worker, {
      url: `${parquetUrl}&last=1&cb=${pollAt}`,
      interval: pollIn,
      onResponse: handleLatestResponseOI,
      noPollOnInit: true,
      buffer: true,
      onlyPollOnce: true,
    });
  }, [pollAt, parquetUrl, handleLatestResponseOI]);

  useEffect(() => {
    setUseTwelveData(false);

    if (showKeyLevels) {
      fetchSgData();
    }
  }, [intradayDate, showKeyLevels]);

  useEffect(() => {
    if (!useTwelveData) {
      return;
    }

    fetchTwelveDataCandles();
  }, [useTwelveData, candleDuration, intradaySym]);

  useEffect(() => {
    if (useTwelveData) {
      return;
    }

    if (
      !streamingApiLoading &&
      getPrices() != null &&
      getPrices().length === 0 &&
      !isLatestTradingDay(intradayDate)
    ) {
      // if we've loaded no candles and the date selected is not the latest trading day, fetch from twelve data
      setUseTwelveData(true);
      return;
    }
  }, [streamingApiLoading, useTwelveData]);

  const fetchTwelveDataCandles = async () => {
    setTwelveDataLoading(true);
    nonProdDebugLog('fetching twelve data...');
    const startDate = intradayDate;
    const endDate = startDate.add(1, 'day');
    const seriesParams = new URLSearchParams({
      symbol: encodeURIComponent(intradaySym),
      start_date: getDateFormatted(startDate),
      end_date: getDateFormatted(endDate),
      interval: `${candleDuration / 60}min`,
      order: 'ASC',
    });
    const series = await fetchAPIWithLog(
      `v1/twelve_series?${seriesParams.toString()}`,
    );
    const result = series[Object.keys(series)[0]];
    const newCandles = convertTwelveCandleDatetimes(
      result.values,
      result.meta.exchange_timezone,
    );
    setTwelveCandles(newCandles ?? []);
    setTwelveDataLoading(false);
  };

  const strikeBarParquetUrlForType = (strikeType: IntradayStrikeBarType) =>
    strikeType === IntradayStrikeBarType.NONE
      ? null
      : getParquetUrl(
          [IntradayStrikeBarType.OI, IntradayStrikeBarType.OI_NET].includes(
            strikeType,
          )
            ? 'intradayStrikeOI'
            : 'intradayStrikeGEX',
        );

  const strikeBarParquetUrl = useMemo(
    () => strikeBarParquetUrlForType(strikeBarType),
    [strikeBarType, intradayDate, intradaySym],
  );

  const statsParquetUrl = useMemo(
    () => getParquetUrl('intradayStats'),
    [intradayDate, intradaySym],
  );

  const pollForStrikeBars = (pollIn = 0, showLoading = false) => {
    if (pollIn < 0 || strikeBarType === IntradayStrikeBarType.NONE) {
      return;
    }

    if (showLoading) {
      setGexLoading(true);
    }

    nonProdDebugLog('setting up GEX poller to poll in', pollIn);

    return poll(worker, {
      url: `${strikeBarParquetUrl}&last=1&cb=${dayjs().valueOf()}`,
      interval: pollIn,
      onResponse: handleLatestResponseGex,
      noPollOnInit: true,
      buffer: true,
      onlyPollOnce: true,
    });
  };

  useEffect(() => {
    const pollIn = pollAt == null ? -1 : pollAt - dayjs().valueOf();
    return pollForStrikeBars(pollIn);
  }, [gexPollAt, strikeBarParquetUrl]);

  const responseToTable = async (resp: any) => {
    const buffer = await resp.arrayBuffer();
    const arrowTable = readParquet(new Uint8Array(buffer));
    return arrow.tableFromIPC(arrowTable.intoIPCStream());
  };

  const fetchParquet = async () => {
    try {
      setProcessingState(ProcessingState.FETCHING);

      const [resp, _wasm] = await Promise.all([
        fetchRawAPI(parquetUrl),
        getWasmPromise(),
      ]);

      // only tell the poller to poll if the date we're fetching is today
      if (isLatestTradingDay(intradayDate)) {
        setPollAt(pollAtFromHeaders(resp.headers));
      } else {
        setPollAt(undefined);
      }

      setProcessingState(ProcessingState.READING);
      if (resp.status !== 200) {
        console.error('Received status: ' + resp.status);
        throw await resp.json();
      }

      const table = await responseToTable(resp);

      setTable(table);

      const array = table.toArray();
      if (
        timestamp == null ||
        !new Set(array.map((e) => e.timestamp.valueOf())).has(
          timestamp.valueOf(),
        )
      ) {
        const lastTS = array[array.length - 1].timestamp;
        const newTs = dayjs.utc(lastTS).tz(tz);
        setTimestamp(newTs);
      }

      setProcessingState(ProcessingState.DONE);
    } catch (err) {
      console.error(err);
      setProcessingState(ProcessingState.FAILED_FETCH);
    }
  };

  useEffect(() => {
    fetchParquet();
  }, [parquetUrl]);

  const fetchStrikeBars = async (retries = 0) => {
    if (strikeBarParquetUrl == null) {
      return;
    }

    nonProdDebugLog('fetching strike bar data...');
    try {
      setGexLoading(true);
      const [_wasm, gexResp] = await Promise.all([
        getWasmPromise(),
        fetchRawAPI(strikeBarParquetUrl),
      ]);

      // only tell the poller to poll if the date we're fetching is today
      if (isLatestTradingDay(intradayDate)) {
        setGexPollAt(pollAtFromHeaders(gexResp.headers));
      } else {
        setGexPollAt(undefined);
      }

      if (gexResp.status !== 200) {
        if (retries < 1) {
          // keep it as loading, and try again in a few secs
          console.error('Received GEX resp status: ' + gexResp.status);
          setTimeout(() => fetchStrikeBars(retries + 1), 5_000);
        } else {
          openToast({
            type: 'error',
            message: `There was an error loading the strike bar chart data.`,
          });
        }
        return;
      }

      const gexRespTable = await responseToTable(gexResp);
      setGexTable(gexRespTable);
    } catch (err) {
      console.error(err);
    } finally {
      setGexLoading(false);
    }
  };

  useEffect(() => {
    fetchStrikeBars();
  }, [strikeBarParquetUrl]);

  const fetchStats = async (retries = 0) => {
    const dateKey = getDateFormatted(intradayDate);
    if (statsDataMap.has(dateKey)) {
      return;
    }

    try {
      const resp = await fetchAPIWithLog(statsParquetUrl);
      nonProdDebugLog('fetched stats data', resp);
      setStatsDataMap((map) => map.set(dateKey, resp));
    } catch (err) {
      console.error(err);
      if (retries < 1) {
        setTimeout(() => fetchStats(retries + 1), 5_000);
      } else {
        openToast({
          type: 'error',
          message: `There was an error loading stats data.`,
        });
      }
      return;
    }
  };

  useEffect(() => {
    fetchStats();
  }, [statsParquetUrl, statsDataMap]);

  const meanSpot = useMemo(() => {
    // Grab the last timestamp in our payload and get the mean spot price
    const array = table?.toArray();
    if (array == null) {
      return null;
    }
    const lastTS = array[array.length - 1].timestamp;
    const start = predicateSearch(array, (e) => e.timestamp < lastTS!) + 1;
    let end = predicateSearch(array, (e) => e.timestamp <= lastTS!);
    end = Math.max(0, end);
    return start > end ? null : Number(array[start].spot + array[end].spot) / 2;
  }, [table]);

  const contourData = useMemo<any>(() => {
    if (table == null) {
      return null;
    }
    const bounds = boundsStr?.split(',').map((v) => parseFloat(v)) ?? null;
    const data = getContourData(
      table,
      timestamp?.valueOf(),
      parquetKeys,
      invert,
      bounds,
      getTzOffsetMs(),
      negativeTrendColor,
      positiveTrendColor,
      selectedLense,
      lastDirectionalFilterCandle,
      selectedScaleRange,
      width,
    );
    firstChartTs.current = data?.firstChartTimestamp ?? null;
    return data;
  }, [
    parquetKeys,
    invert,
    table,
    timestamp,
    boundsStr,
    tz,
    positiveTrendColor,
    negativeTrendColor,
    selectedLense,
    lastDirectionalFilterCandle,
    selectedScaleRange,
    screenWidth,
  ]);

  const getCandlesArr = () =>
    (useTwelveData ? twelveCandles : [...getPricesFiltered()]) ?? [];

  // need to be able to add candles in a useEffect/useMemo dep
  // we should not use this to render though as it causes a noticeable chart lag
  // instead we use getCandlesArr() directly, since that uses a ref
  const candlesMemo = useMemo(
    () => getCandlesArr(),
    [
      useTwelveData,
      twelveCandles,
      timestamp,
      lastAddedCandleTs,
      contourData,
      streamingApiLoading,
    ],
  );

  useEffect(() => {
    if (!DIRECTIONAL_LENSES.has(selectedLense)) {
      return setLastDirectionalFilterCandle(undefined);
    }
    let idx = candlesMemo.length - 1;
    if (timestamp != null) {
      const tgt = timestamp.valueOf();
      idx = predicateSearch(candlesMemo, (c) => c.datetime.valueOf() <= tgt);
    }
    nonProdDebugLog(`using last directional filter candle`, candlesMemo[idx]);
    setLastDirectionalFilterCandle(candlesMemo[idx]);
  }, [selectedLense, timestamp, candlesMemo]);

  useEffect(() => {
    // it's likely that the price bounds will stay the same for most of the day except when we make a new high and low
    // rather than recalculate the contour data on every candles change because of the bounds
    // let's save the bounds in a string (not array, since setting that will always re-trigger a re-render regardless)
    // and only have the contour data re-render if the bounds actually change
    if (
      priceBounds == null ||
      (!(candlesMemo.length > 0) && meanSpot == null)
    ) {
      setBoundsStr(null);
      return;
    }
    let lo = Infinity;
    let hi = -Infinity;
    if (candlesMemo?.length > 0) {
      for (const candle of candlesMemo) {
        lo = Math.min(lo, candle.low);
        hi = Math.max(hi, candle.high);
      }
    } else {
      lo = hi = meanSpot!;
    }
    const newBounds = [lo * (1 - priceBounds), hi * (1 + priceBounds)].join(
      ',',
    );

    setBoundsStr(newBounds);
    nonProdDebugLog(`using new price bounds`, newBounds);
  }, [candlesMemo, meanSpot, priceBounds, zoomData]);

  const gexData = useMemo<any>(() => {
    if (gexTable == null || contourData?.chartData == null) {
      return null;
    }

    // ensure the gex y axis matches the contour data y axis by getting the min/max of the contour data strikes
    // and not displaying any gex data with strikes outside that range
    const contourStrikes = contourData.chartData.y;
    const min = contourStrikes[0];
    const max = contourStrikes[contourStrikes.length - 1];

    return getGexData(
      gexTable,
      invert,
      parquetKeys,
      min,
      max,
      timestamp,
      negativeTrendColor,
      positiveTrendColor,
      showGexZeroDte,
      strikeBarType,
    );
  }, [
    gexTable,
    parquetKeys,
    contourData,
    timestamp,
    negativeTrendColor,
    positiveTrendColor,
    invert,
    showGexZeroDte,
    strikeBarType,
  ]);

  useEffect(() => {
    if (timestamp == null || gexData == null) {
      return;
    }

    // if gex data is not null but we have no values, it means the gex timestamp does not match
    // the selected timestamp. if this is the case, show 'loading'
    if (gexData.length === 0 && !timestamp.isSame(gexLoadingLastPolledTs)) {
      // then immediately fetch the latest gex data by telling it to poll in the next 2 seconds
      const pollIn =
        gexPollAt == null ? Infinity : gexPollAt - dayjs().valueOf();
      if (pollIn > 3_000 || pollIn < 0) {
        nonProdDebugLog('gex is missing the latest timestmap. refetching...');
        fetchStrikeBars();
        // if the next fetch fails, dont keep fetching
        setGexLoadingLastPolledTs(timestamp);
      }
    }
  }, [timestamp, gexData, gexLoadingLastPolledTs, gexPollAt]);

  const levelsChartData = useMemo<{
    chartData: any[];
    annotations: any[];
  }>(() => {
    const contourX = contourData?.chartData?.x ?? [];
    if (sgData == null || contourX.length === 0 || !showKeyLevels) {
      return { chartData: [], annotations: [] };
    }

    // get the min and max x coordinates for the chart since the lines we draw will need them so they appear
    // as horizontal lines
    const x1 = contourX[0];
    const x2 = contourX[contourX.length - 1];
    const xMid = contourX[Math.floor(contourX.length / 2)];

    // don't show levels that are not within the strike ranges
    const contourStrikes = contourData.chartData.y;
    const minY = contourStrikes[0];
    const maxY = contourStrikes[contourStrikes.length - 1];

    const priceLines = getPriceLines(
      sgData,
      theme,
      sigHighLow(sgData, todaysOpenArr, getDateFormatted(intradayDate)),
    );
    const chartData: any[] = [];
    const annotations: any[] = [];

    Object.keys(priceLines).forEach((priceLineName) => {
      const levelData = priceLines[priceLineName as PriceLineKey];
      const val = levelData?.value ?? 0;
      if (val < minY || val > maxY) {
        return;
      }

      chartData.push({
        x: [x1, x2],
        y: [val, val],
        xaxis: 'x',
        yaxis: 'y',
        mode: 'lines',
        line: {
          color: levelData.color,
          width: 3,
        },
        name: priceLineName,
        hoverinfo: 'name+y',
      });

      annotations.push({
        x: xMid.valueOf(),
        y: val,
        text: priceLineName,
        font: {
          size: 12,
          color: '#000',
        },
        showarrow: false,
        bgcolor: levelData.color,
      });
    });
    return { chartData, annotations };
  }, [contourData, sgData, todaysOpenArr, theme, showKeyLevels, intradayDate]);

  const getCandlesData = () => {
    const candles = getCandlesArr();
    if (candles.length === 0) {
      return {};
    }

    return getCandles(
      candles,
      filterPrice ? timestamp?.valueOf() : undefined,
      getTzOffsetMs(),
    ) as any;
  };

  const hiroChartData = useMemo(() => {
    const hiroCandles = getHiroFiltered();
    return getHiro(
      hiroCandles,
      filterPrice ? timestamp?.valueOf() : undefined,
      getTzOffsetMs(),
      theme.palette.hiro.lenses.all.total,
      hiroSym,
    );
  }, [
    tz,
    timestamp,
    lastAddedHiroTs,
    streamingApiLoading,
    hiroSym,
    filterPrice,
  ]);

  const loading =
    streamingApiLoading ||
    twelveDataLoading ||
    (processingState !== ProcessingState.DONE &&
      processingState !== ProcessingState.FAILED_FETCH);

  const gexTitle = () => {
    if (!shouldShowAxisLabels(width)) {
      return undefined;
    }

    const title = INTRADAY_BAR_TITLES.get(strikeBarType)!;
    if (
      showGexZeroDte &&
      (gexData?.length ?? 0) > 0 &&
      gexData[0].x.find((v: number) => v !== 0) == null
    ) {
      return `${title} <br /> (market closed, 0DTE n/a)`;
    }

    return title;
  };

  const onZoom = useCallback(
    (eventData: any) => {
      const xZoomedOut = eventData['xaxis.autorange'];
      const yZoomedOut = eventData['yaxis.autorange'];

      const xBounds = [
        eventData['xaxis.range[0]'],
        eventData['xaxis.range[1]'],
      ].filter((d) => d != null);
      const yBounds = [eventData['yaxis.range[0]'], eventData['yaxis.range[1]']]
        .filter((d) => d != null)
        .map((d) => Math.floor(d));

      const y2Bounds = [
        eventData['yaxis2.range[0]'],
        eventData['yaxis2.range[1]'],
      ]
        .filter((d) => d != null)
        .map((d) => Math.floor(d));

      const newZoomData: ZoomData = {};
      // for some reason, plotly sometimes does not pass in autorange for the y2 axis.
      // so we dont always know when y2 is zoomed out. instead, use the autorange for y
      if (!yZoomedOut) {
        newZoomData.y = yBounds.length > 0 ? yBounds : zoomData?.y;
        newZoomData.y2 = y2Bounds.length > 0 ? y2Bounds : zoomData?.y2;
      }
      if (!xZoomedOut) {
        newZoomData.x = xBounds.length > 0 ? xBounds : zoomData?.x;
      }
      setZoomData(
        Object.keys(newZoomData).length > 0 ? newZoomData : undefined,
      );
    },
    [zoomData],
  );

  const xAxisStartDomain = isMobile
    ? 0.15
    : strikeBarType === IntradayStrikeBarType.NONE
    ? 0.05
    : 0.27;
  // ensure there is fixed padding at the end regardless of chart width
  const xAxisEndDomain = isMobile ? 1 : 1 - END_CHART_X_PADDING / width;
  const xAxis2Domain =
    showChartType === IntradayShowChartType.StrikeBars
      ? [xAxisStartDomain, xAxisEndDomain]
      : [0, 0.19];

  const header = !isMobile && (
    <Typography fontSize={18}>
      SpotGamma's {intradayDate.format('M/D/YY')} {intradaySym}{' '}
      {capitalize(selectedGreek)} Exposure
    </Typography>
  );

  const gexChartData =
    strikeBarType === IntradayStrikeBarType.NONE
      ? []
      : (gexData?.length ?? 0) > 0 && !gexLoading
      ? gexData
      : // needed for it to show loading even without data
        [{ xaxis: 'x2', type: 'bar' }];

  useEffect(() => {
    if (!plotHovering && !tooltipHovering) {
      setHoverInfo(undefined);
    }
  }, [plotHovering, tooltipHovering]);

  const onHover = (e: any) => {
    if ((e.points?.length ?? 0) === 0) {
      return;
    }

    const hoveredPoint = e.points[0];
    const isStrikeBar = hoveredPoint.fullData.type === 'bar';
    const coords = hoveredPoint.bbox;
    const xDate = dayjs(hoveredPoint.x).subtract(
      getTzOffsetMs(),
      'milliseconds',
    );
    const hiroAndPriceCandles = [getCandlesArr(), getHiroFiltered()].map(
      (arr) => {
        const firstIdx = predicateSearch(
          arr,
          (candle) => candle.time < xDate.valueOf() / 1_000,
        );
        const possibleCandle = arr[firstIdx + 1];
        return possibleCandle?.time === xDate.valueOf() / 1_000
          ? possibleCandle
          : undefined;
      },
    );

    const statsData = statsDataMap.get(getDateFormatted(intradayDate));
    const percentile = getStatsPercentile(
      isStrikeBar ? hoveredPoint.x : hoveredPoint.z,
      isStrikeBar ? strikeBarType : selectedLense,
      statsData,
      parquetKeys,
      invert,
      isStrikeBar,
      showGexZeroDte,
      hoveredPoint.fullData.name,
      statsLookbackDays,
    );

    const divider = (
      <Box
        sx={{
          height: '1px',
          width: '100%',
          marginY: '5px',
          background: theme.palette.text.primary,
        }}
      />
    );

    let tooltipBody;

    if (isStrikeBar) {
      tooltipBody = (
        <>
          <Typography>
            Strike: <b>{hoveredPoint.y}</b>
          </Typography>
          <Typography>
            {showGexZeroDte ? '0DTE' : ''} {hoveredPoint.fullData.name}:{' '}
            <b>
              {strikeBarType === IntradayStrikeBarType.GAMMA ? '$' : ''}
              {formatAsCompactNumber(hoveredPoint.x, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })}
            </b>
          </Typography>
          {percentile != null && (
            <Typography>
              (<b>{percentile}%</b> {statsLookbackDays}d Percentile)
            </Typography>
          )}
        </>
      );
    } else {
      tooltipBody = (
        <>
          <Typography>
            Time: <b>{xDate.tz(tz).format('HH:mm')}</b>
          </Typography>
          {divider}
          <Typography>
            Price: <b>{hoveredPoint.y}</b>
          </Typography>
          {hoveredPoint.z != null && (
            <Typography>
              {IntradayFiltersAxisLabels.get(selectedLense)}:{' '}
              <b>{formatAsCompactNumber(hoveredPoint.z)}</b>
            </Typography>
          )}
          {percentile != null && (
            <Typography>
              (<b>{percentile}%</b> 30d Percentile)
            </Typography>
          )}
          {hiroAndPriceCandles.filter((v) => v != null).length > 0 && divider}
          {hiroAndPriceCandles[0] != null && (
            <Typography>
              {intradaySym} Price at time: <br />
              H: <b>{Math.round(hiroAndPriceCandles[0].high)}</b>
              &nbsp;&nbsp;&nbsp;L:{' '}
              <b>{Math.round(hiroAndPriceCandles[0].low)}</b>
              &nbsp;&nbsp;&nbsp;C:
              <b>{Math.round(hiroAndPriceCandles[0].close)}</b>
            </Typography>
          )}
          {hiroAndPriceCandles[1] != null && (
            <Typography>
              {hiroSym} HIRO at time:{' '}
              <b>{formatAsCompactNumber(hiroAndPriceCandles[1].close)}</b>
            </Typography>
          )}
        </>
      );
    }

    const tooltip = (
      <Box
        onMouseEnter={() => setTooltipHovering(true)}
        onMouseLeave={() => setTooltipHovering(false)}
        sx={{
          position: 'absolute',
          left: coords.x0 + (isStrikeBar ? 0 : 30),
          top: coords.y0 + (isStrikeBar ? 10 : 30),
          background: theme.palette.background.paper,
          padding: '15px',
        }}
      >
        <Stack direction="column">{tooltipBody}</Stack>
      </Box>
    );

    setPlotHovering(true);
    setHoverInfo(tooltip);
  };

  const getPlotlyData = () => {
    const heatmapData = [
      getCandlesData(),
      contourData?.chartData
        ? {
            ...contourData.chartData,
            showscale: showColorScale,
            line: {
              smoothing: 0.9,
              width: showContourLines ? undefined : 0,
            },
          }
        : null,
      ...levelsChartData.chartData,
      hiroChartData?.chartData,
    ].filter((d) => d != null);

    const strikeBarsData = [...gexChartData].filter((d) => d != null);

    if (showChartType === IntradayShowChartType.Both) {
      return heatmapData.concat(strikeBarsData);
    } else if (showChartType === IntradayShowChartType.Heatmap) {
      return heatmapData;
    } else {
      return strikeBarsData;
    }
  };

  const body = (
    <Loader isLoading={loading}>
      <Center>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box width={1} flexGrow={1} textAlign="center">
            {header}
          </Box>
          <Box>
            {contourData == null ? (
              <Box sx={{ textAlign: 'center', marginTop: '25px' }}>
                <Typography sx={{ cursor: 'pointer' }} onClick={fetchParquet}>
                  There was an error. Please click here to retry.
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: `${theme.palette.background.paper} !important`,
                  marginTop: isMobile ? 0 : '10px',
                }}
              >
                <Box>
                  <IntradayGammaControls
                    timestamps={timestamps}
                    showAllSettings={
                      productType === ProductType.INTERNAL_OPEN_INTEREST
                    }
                    intradayDate={intradayDate}
                    zoomData={zoomData}
                    resetZoom={() => setZoomData(undefined)}
                    setStrikeBarType={(newType) => {
                      if (newType !== IntradayStrikeBarType.NONE) {
                        if (
                          strikeBarParquetUrlForType(newType) !==
                          strikeBarParquetUrlForType(strikeBarType)
                        ) {
                          // there is this very annoying lag where we set the label title first, then set the loading setting
                          // this is because of the slight lag caused by everything bubbling up into the useMemo/useEffects
                          // instead, setGexLoading here if we anticipate needing to fetch
                          setGexLoading(true);
                        }
                      }
                      saveSgSettings({ oi: { strikeBarType: newType } });
                    }}
                    showChartType={showChartType}
                  />
                </Box>
                {isMobile && (
                  <Box
                    margin="auto"
                    width={1}
                    textAlign="center"
                    marginTop="5px"
                  >
                    <ButtonGroup>
                      <Button
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        variant={
                          showChartType === IntradayShowChartType.Heatmap
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setShowChartType(IntradayShowChartType.Heatmap)
                        }
                      >
                        Heatmap
                      </Button>
                      <Button
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        variant={
                          showChartType === IntradayShowChartType.StrikeBars
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setShowChartType(IntradayShowChartType.StrikeBars)
                        }
                      >
                        Strike Bars
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}

                <Box margin="auto">
                  <Plot
                    onRelayout={onZoom}
                    data={getPlotlyData()}
                    onHover={onHover}
                    onUnhover={() => setPlotHovering(false)}
                    layout={{
                      width,
                      height,
                      margin: {
                        l: 20,
                        r: isMobile ? 40 : 20,
                        b: isMobile ? 30 : 65,
                        t: 20,
                        pad: 4,
                      },
                      paper_bgcolor: theme.palette.background.paper,
                      plot_bgcolor: theme.palette.background.paper,
                      font: {
                        color: theme.palette.text.primary,
                      },
                      xaxis:
                        showChartType === IntradayShowChartType.StrikeBars
                          ? {}
                          : {
                              rangeslider: {
                                visible: false,
                              },
                              domain: [xAxisStartDomain, xAxisEndDomain],
                              fixedrange: false,
                              ...(zoomData?.x ? { range: zoomData.x } : {}),
                            },
                      xaxis2:
                        strikeBarType === IntradayStrikeBarType.NONE ||
                        showChartType === IntradayShowChartType.Heatmap
                          ? {}
                          : {
                              domain: xAxis2Domain,
                              range: getGexRangeXAxis(
                                gexData,
                                selectedScaleRange,
                                gexTable,
                                invert,
                                showGexZeroDte,
                                parquetKeys,
                                strikeBarType,
                                timestamp,
                              ),
                              ...(gexLoading
                                ? { title: 'Loading...', showticklabels: false }
                                : {
                                    title: { text: gexTitle() },
                                  }),
                            },
                      yaxis: shouldShowAxisLabels(width)
                        ? {
                            fixedrange: false,
                            title: {
                              text: 'Strike / Price',
                              standoff: width > 1050 ? 15 : 5,
                            },
                            ...(zoomData?.y ? { range: zoomData.y } : {}),
                          }
                        : { fixedrange: false },
                      yaxis2:
                        hiroChartData == null
                          ? { fixedrange: false }
                          : {
                              fixedrange: false,
                              overlaying: 'y',
                              side: 'right',
                              position:
                                xAxisEndDomain + (isMobile ? -0.01 : 0.005),
                              range:
                                zoomData?.y2 ??
                                getHiroRange(hiroChartData, priceBounds),
                              ...(shouldShowAxisLabels(width)
                                ? {
                                    title: {
                                      text: 'HIRO',
                                    },
                                  }
                                : {}),
                            },
                      showlegend: false,
                      annotations: levelsChartData.annotations,
                      modebar: {
                        // remove all plotly buttons except the download as image one
                        // they simply dont work well with our chart
                        remove: [
                          'autoScale2d',
                          'hoverCompareCartesian',
                          'hovercompare',
                          'lasso2d',
                          'orbitRotation',
                          'pan2d',
                          'pan3d',
                          'resetCameraDefault3d',
                          'resetCameraLastSave3d',
                          'resetGeo',
                          'resetScale2d',
                          'resetViewMapbox',
                          'resetViews',
                          'select2d',
                          'sendDataToCloud',
                          'tableRotation',
                          'toggleHover',
                          'toggleSpikelines',
                          'togglehover',
                          'togglespikelines',
                          'zoom2d',
                          'zoom3d',
                          'zoomIn2d',
                          'zoomInGeo',
                          'zoomInMapbox',
                          'zoomOut2d',
                          'zoomOutGeo',
                          'zoomOutMapbox',
                          // remove toImage if mobile. if not, add dup key because ts complains
                          isMobile ? 'toImage' : 'zoom2d',
                        ],
                      },
                    }}
                  />
                  {hoverInfo}
                </Box>
                <Box>
                  <TraceTimeSlider timestamps={timestamps} chartWidth={width} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Center>
    </Loader>
  );
  return (
    <Box sx={loading ? { width: 1, height: isMobile ? 1 : `${height}px` } : {}}>
      {body}
    </Box>
  );
};
