import { Box } from '@mui/material';
import OptionsFeedDatagrid from './OptionsFeedDatagrid';
import useTnSWebsocket from 'hooks/useTnSWebsocket';
import { useEffect, useState } from 'react';
import { RecoilState, useRecoilValue } from 'recoil';
import { isMarketOpenState } from 'states';
import { decode } from '@msgpack/msgpack';
import { OPTIONS_FEED_MAX_ROWS } from 'config/optionsFeed';
import { getOptionFeedDataRow } from 'util/optionsFeed';
import { fetchRawAPI } from 'util/shared';
import { useLog } from 'hooks';
import {
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
} from 'types/optionsFeed';
import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSlotsComponentsProps,
  useGridApiRef,
} from '@spotgamma/x-data-grid-premium';
import { useOptionsFeedColumns } from './useOptionsFeedColumns';

interface OptionsFeedProps {
  disabledColumnFilters?: OptionsFeedColumnKey[];
  disableWatchlistSelector?: boolean;
  currentSyms: string[];
  filterModelState: RecoilState<GridFilterModel>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  activeWatchlistIdsState: RecoilState<number[]>;
  customGridSlotProps?: GridSlotsComponentsProps;
}

const OptionsFeed = ({
  disabledColumnFilters,
  currentSyms,
  filterModelState,
  columnVisibilityState,
  columnOrderState,
  columnSizingState,
  activeWatchlistIdsState,
  customGridSlotProps,
}: OptionsFeedProps) => {
  const { logError } = useLog('OptionsFeedPage');
  const [rows, setRows] = useState<RawOptionFeedData[]>([]);

  const [histError, setHistError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const { columns } = useOptionsFeedColumns({ disabledColumnFilters });

  const isMarketOpen = useRecoilValue(isMarketOpenState);

  const handleNewData = (newRows: RawOptionFeedData[]) => {
    setRows((prev) => [...newRows, ...prev].slice(0, OPTIONS_FEED_MAX_ROWS));
  };

  // data comes through this websocket
  const { error } = useTnSWebsocket(currentSyms, handleNewData);

  useEffect(() => {
    const getData = async (limit = OPTIONS_FEED_MAX_ROWS) => {
      try {
        setLoading(true);
        const response = await fetchRawAPI(
          `v2/history?sym=${encodeURIComponent(
            currentSyms.join(','),
          )}&limit=${limit}&stream=absolute`,
          {},
          'https://dev.stream.spotgamma.com', // temporarily hardcoding host for fetching
        );

        const arrayBuffer = await response.arrayBuffer();
        const histData: any = decode(arrayBuffer);
        // last 10k rows of data
        const cleanData = [
          ...histData
            .map((d: any[]) => getOptionFeedDataRow(d))
            .slice(histData.length - OPTIONS_FEED_MAX_ROWS, histData.length),
        ];

        setRows((prev) =>
          [...cleanData, ...prev].slice(0, OPTIONS_FEED_MAX_ROWS),
        );
      } catch (err) {
        console.error(err);
        logError(err);
        setHistError('Something went wrong while fetching data...');
      } finally {
        setLoading(false);
      }
    };

    if (!isMarketOpen) {
      getData(OPTIONS_FEED_MAX_ROWS);
    } else {
      // get quarter of data just to populate using most recent data
      getData(OPTIONS_FEED_MAX_ROWS / 4);
    }
  }, [isMarketOpen, currentSyms]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        overFlowX: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <OptionsFeedDatagrid
        rows={rows}
        error={error || histError}
        columns={columns}
        filterModelState={filterModelState}
        columnVisibilityState={columnVisibilityState}
        columnOrderState={columnOrderState}
        columnSizingState={columnSizingState}
        activeWatchlistIdsState={activeWatchlistIdsState}
        customGridSlotProps={customGridSlotProps}
      />
    </Box>
  );
};

export default OptionsFeed;
