import { Button, Stack, Typography, useTheme } from '@mui/material';
import { InfoButton } from 'components/shared';
import { WatchlistMultiSelect } from 'components/shared/WatchlistMultiSelect';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isMobileState, productAccessState } from 'states';
import {
  scannerActiveWatchlistsIdsState,
  scannerDisplayTypeState,
} from 'states/scanners';
import { ProductType } from 'types/shared';
import ScannersSection from './ScannersSection';
import { ScannerDisplayType } from '../../types/scanners';
import useAuth from '../../hooks/auth/useAuth';
import { shadeColor } from '../../util';

const ScannerHeader = () => {
  const theme = useTheme();
  const products = useRecoilValue(productAccessState);
  const hasScannersAccess = useMemo(
    () => products.includes(ProductType.SCANNERS),
    [products],
  );
  const { canViewCompass } = useAuth();
  const isMobile = useRecoilValue(isMobileState);
  const [scannerDisplayState, setScannerDisplayState] = useRecoilState(
    scannerDisplayTypeState,
  );
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    scannerActiveWatchlistsIdsState,
  );

  const toggleCompass = useCallback(() => {
    if (scannerDisplayState === ScannerDisplayType.ScannersWithCompass) {
      setScannerDisplayState(ScannerDisplayType.Scanners);
    } else {
      setScannerDisplayState(ScannerDisplayType.ScannersWithCompass);
    }
  }, [scannerDisplayState, setScannerDisplayState]);

  const compassButton = canViewCompass &&
    scannerDisplayState !== ScannerDisplayType.ScannersWithCompass && (
      <Button
        onClick={toggleCompass}
        variant="outlined"
        sx={{
          fontSize: isMobile ? 11 : 13,
          textTransform: 'capitalize',
          borderWidth: 2,
          ':hover': {
            borderWidth: 2,
            borderColor: shadeColor(theme.palette.compass.buttonColor, 20),
          },
          borderColor: theme.palette.compass.buttonColor,
          color: theme.palette.compass.buttonColor,
        }}
      >
        Open Trade Compass
      </Button>
    );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <ScannersSection />

        <Stack direction="row" gap={1} alignItems="flex-start">
          {compassButton}
          <InfoButton key="scanners" articleKey="scanners" />
          <WatchlistMultiSelect
            activeWatchlistIds={activeWatchlistIds}
            setActiveWatchlistIds={setActiveWatchlistIds}
          />
        </Stack>
      </Stack>
      {!hasScannersAccess && (
        <Typography
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          (Access limited to a small number of equities)
        </Typography>
      )}
    </>
  );
};

export default ScannerHeader;
