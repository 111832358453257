import { TOKEN } from './token';
import { DEFAULT_TTL, EndpointCache } from './EndpointCache';
import { cloneDeep, merge } from 'lodash';
import { getCachedToken } from './auth';
import { isBloomberg } from '../bloomberg';
import { getEnv } from './common';

const MAX_FETCH_RETRIES = 1;
export const DEFAULT_OPTS = Object.freeze({
  method: 'GET',
  crossDomain: true,
  Origin: '*',
  'X-Requested-With': 'XMLHttpRequest',
  headers: Object.freeze({
    'x-json-web-token': TOKEN,
    'Content-Type': 'application/json',
    'App-Type': isBloomberg() ? 'bloomberg' : 'web',
  }),
});

export function getAuthHeader() {
  const sgBearerToken = getCachedToken();
  return sgBearerToken == null
    ? {}
    : { headers: { Authorization: `Bearer ${sgBearerToken}` } };
}

export function getVersionHeader() {
  return process.env.REACT_APP_VERSION_NUM == null
    ? {}
    : { headers: { Version: `${process.env.REACT_APP_VERSION_NUM}` } };
}

// Fetch raw response.  Don't parse JSON
export async function fetchRawAPI(
  endpoint: string,
  auxiliaryOptions: any = {},
  host = process.env.REACT_APP_SPOTGAMMA_API,
): Promise<any> {
  try {
    let currentHost = host;
    if (
      getEnv() === 'development' &&
      (auxiliaryOptions?.local ||
        process.env.REACT_APP_ALL_LOCAL_FETCH === 'true')
    ) {
      currentHost = process.env.REACT_APP_LOCAL_API ?? currentHost;
    }
    const url = `${currentHost}/${endpoint}`;
    const opts = merge(
      { ...cloneDeep(DEFAULT_OPTS) },
      getAuthHeader(),
      getVersionHeader(),
      auxiliaryOptions,
    );
    const response = await fetch(url, opts);
    if (response.status >= 300) {
      console.error(`${url} returned status: ${response.status}`);
      let text = null;
      try {
        text = await response.text();
      } catch (e) {}
      return {
        error: text ?? 'There was an error. Please try again.',
        status: response.status,
        response,
      };
    }
    return response;
  } catch (err: any) {
    if (err.message?.includes('Failed to fetch')) {
      const retryCounter = auxiliaryOptions.retryCounter ?? 0;
      if (retryCounter < MAX_FETCH_RETRIES) {
        return fetchRawAPI(endpoint, {
          ...auxiliaryOptions,
          retryCounter: retryCounter + 1,
        });
      }
    }

    throw err;
  }
}

export async function fetchCached(
  endpoint: string,
  auxiliaryOptions?: any,
): Promise<any> {
  const cachedVal = await EndpointCache.get(endpoint);
  if (cachedVal != null) {
    return cachedVal;
  }
  let response = null;
  try {
    response = await fetchRawAPI(endpoint, auxiliaryOptions);
    const cacheControl = response.headers.get('cache-control');
    let ttl = auxiliaryOptions?.ttl;
    if (cacheControl != null) {
      const match = /max-age=(\d+)/.exec(cacheControl);
      if (match != null) {
        // Don't set a TTL any longer than what the reponse gives
        ttl = Math.min(ttl ?? DEFAULT_TTL, Number(match[1]) * 1_000);
      }
    }
    const value = await response.json();
    EndpointCache.set(endpoint, value, ttl);
    return value;
  } catch (err: any) {
    return { error: err, status: response?.status, response };
  }
}

export async function fetchAPI(
  endpoint: string,
  auxiliaryOptions?: any,
): Promise<any> {
  let response = null;
  try {
    response = await fetchRawAPI(endpoint, auxiliaryOptions);
    return response.error ? response : await response.json();
  } catch (err: any) {
    return { error: err, status: response?.status, response };
  }
}

export const getPollingHeader = (interval: number) => ({
  headers: { 'Polling-Interval-ms': interval },
});
