import React, { ReactNode, useCallback, useState } from 'react';
import {
  alpha,
  Badge,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Paper,
  Popper,
  PopperPlacementType,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import UserNotificationsList from './UserNotificationsList';
import {
  unseenUserNotificationsState,
  userNotificationsState,
} from 'states/notifications';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLog } from 'hooks';
import { UserNotification } from 'types/notifications';
import { getUserNotification } from 'util/notifications';
import { DEFAULT_NOTIFICATION_DAYS } from 'config/notifications';

interface UserNotificationsDropdownProps {
  children: ReactNode;
  placement?: PopperPlacementType;
}

const UserNotificationsDropdown = ({
  children,
  placement = 'bottom-end',
}: UserNotificationsDropdownProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setNotifications = useSetRecoilState(userNotificationsState);
  const unseenCount = useRecoilValue(unseenUserNotificationsState);

  const { fetchAPIWithLog } = useLog('userNotifications');

  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrors([]);

    try {
      // Fetch notifications
      const fetchResponse = await fetchAPIWithLog(
        `v1/me/notifications?days=${DEFAULT_NOTIFICATION_DAYS}`,
      );
      if (fetchResponse.error) {
        throw new Error(fetchResponse.error);
      }

      const newNotifications: UserNotification[] =
        fetchResponse.map(getUserNotification);
      setNotifications(newNotifications);

      // Mark notifications as seen
      const lastId = newNotifications[0]?.id;
      if (!lastId) {
        return;
      } // Early exit if there's no lastId, implies no new notifications

      const updateResponse = await fetchAPIWithLog(
        `v1/me/markNotificationsSeen`,
        {
          method: 'PATCH',
          body: JSON.stringify({ lastId }),
        },
      );

      if (updateResponse.error) {
        throw new Error(updateResponse.error);
      }

      // Update notifications to seen status
      setNotifications((prev) =>
        prev.map((notification) => ({
          ...notification,
          isSeen: true,
        })),
      );
    } catch (error: any) {
      console.error(error);
      setErrors((prev) => [...prev, error.message]);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleClick = async (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
    await fetchData();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-notifications-popper' : undefined;

  return (
    <>
      <Box position="relative" display="inline-block" onClick={handleClick}>
        <Badge
          badgeContent={unseenCount}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              right: 5,
              top: 5,
              fontSize: '0.6rem',
              minWidth: '16px',
              height: '16px',
              padding: '0 4px',
            },
          }}
        >
          {children}
        </Badge>
      </Box>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        sx={{ zIndex: 1000 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              borderRadius: 2,
              border: `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
            }}
          >
            {loading ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 280,
                  height: 280,
                  padding: 4,
                }}
              >
                <CircularProgress color="primary" />
              </Stack>
            ) : errors.length > 0 ? (
              <Stack
                justifyContent="center"
                sx={{
                  width: 280,
                  padding: 4,
                }}
                gap={4}
              >
                <Stack>
                  {errors.map((err: string) => (
                    <Typography key={err} color="red" fontSize={14}>
                      • {err}
                    </Typography>
                  ))}
                </Stack>
                <Button sx={{ textTransform: 'none' }} onClick={fetchData}>
                  Try Again
                </Button>
              </Stack>
            ) : (
              <UserNotificationsList />
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default UserNotificationsDropdown;
