import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ColorMode } from 'theme';
import { useRecoilValue } from 'recoil';
import {
  drawerExpandedState,
  isMobileState,
  isSideDrawerExpandedState,
  sidebarExpandedState,
  userDetailsState,
} from 'states';
import { HideSupportStyle } from '../HideSupportStyle';

interface UpsellProps {
  title: string;
  subtitle: string;
  items: string[];
  imageUrl?: string;
}

export const Upsell = ({ title, subtitle, items, imageUrl }: UpsellProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userDetails = useRecoilValue(userDetailsState);
  const isMobile = useRecoilValue(isMobileState);
  const sidebarExpanded = useRecoilValue(isSideDrawerExpandedState);

  const isPortrait = window.innerHeight > window.innerWidth;
  const backgroundImageUrl = `images/upsell/${
    theme.colorMode === ColorMode.LIGHT ? 'light' : 'dark'
  }-${isPortrait ? 'portrait' : 'landscape'}-${imageUrl}.png`;

  const sidebarOffset = sidebarExpanded ? 240 : 50;
  return (
    <Box
      sx={{
        backgroundImage: isMobile ? undefined : `url("${backgroundImageUrl}")`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: isMobile ? '0px' : `${sidebarOffset}px`,
        top: isMobile ? '65px' : '0px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: isMobile ? undefined : 'rgba(0,0,0,0.4)',
          zIndex: 1,
        }}
      />
      <HideSupportStyle />
      <Paper
        elevation={8}
        sx={{
          padding: '2em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '12px',
          zIndex: 2,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.disabled,
            fontSize: '16px',
            textTransform: 'uppercase',
          }}
        >
          {`${userDetails == null ? 'Subscribe' : 'Upgrade'} to Unlock`}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '24px',
            textTransform: 'uppercase',
            display: 'block',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            paddingBottom: '1em',
            paddingLeft: '1em',
            fontSize: '16px',
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 'inherit',
              textTransform: 'uppercase',
            }}
          >
            {subtitle}
          </Typography>
          <List
            dense={true}
            sx={{ color: theme.palette.common.white, flex: 1 }}
          >
            {items.map((item, idx) => {
              return (
                <ListItem key={`upsell-item-${idx}`}>
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        color: theme.palette.text.primary,
                        fontSize: '14px',
                        lineHeight: '16px',
                      },
                    }}
                    primary={item}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '6px',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{
              textTransform: 'uppercase',
              backgroundColor: theme.palette.primary.main,
              // TODO: We need better default text color when on buttons.  All
              // modes should have roughly the below to be legible on our
              // SpotGamma green button
              color: 'black',
              fontSize: '14px',
              py: '6px',
              px: '24px',
            }}
            onClick={() =>
              window.open(
                'https://spotgamma.com/subscribe-to-spotgamma/',
                '_blank',
              )
            }
          >
            {`${userDetails == null ? 'Subscribe' : 'Upgrade'} Now`}
          </Button>
          {userDetails == null && (
            <Button
              sx={{
                textTransform: 'uppercase',
                backgroundColor: theme.palette.text.disabled,
                color: 'black',
                fontSize: '14px',
                py: '6px',
                px: '24px',
              }}
              onClick={() => navigate('/login')}
            >
              Login
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
