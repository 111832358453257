import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  screenHeightState,
  screenWidthState,
} from '../../states';
import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Typography,
  DialogContent,
  Slide,
  DialogActions,
  DialogTitle,
  Box,
} from '@mui/material';
import { ReactNode, forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';

export type SGModalProps = {
  onClose: () => void;
  onSave?: () => void;
  saveLoading?: boolean;
  open?: boolean;
  children: ReactNode;
  title?: string;
  description?: string;
  customActions?: ReactNode[];
  cancelText?: string;
  mobileFullscreen?: boolean;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SGModal = ({
  onClose,
  onSave,
  open = false,
  children,
  title,
  description,
  saveLoading,
  customActions,
  cancelText = 'Cancel',
  mobileFullscreen = true,
}: SGModalProps) => {
  const screenWidth = useRecoilValue(screenWidthState);
  const screenHeight = useRecoilValue(screenHeightState);
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const contentStyle = {
    ...(isMobile
      ? { marginTop: '20px' }
      : {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          maxWidth: screenWidth * 0.85,
          maxHeight: screenHeight * 0.75,
        }),
  };

  customActions = customActions ?? [
    <Button
      key="cancel"
      onClick={onClose}
      sx={{ fontSize: '16px', height: '40px' }}
    >
      {cancelText}
    </Button>,
    onSave != null && (
      <LoadingButton
        key="save"
        type="submit"
        onClick={onSave}
        loading={saveLoading}
        sx={{
          fontSize: '16px',
          color: '#ffffff',
          height: '40px',
        }}
        loadingIndicator={<CircularProgress color="info" size={16} />}
        variant="contained"
      >
        Save
      </LoadingButton>
    ),
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen={isMobile && mobileFullscreen}
      scroll="paper"
      fullWidth={true}
    >
      {title != null && (
        <DialogTitle
          sx={{
            paddingBottom: '8px !important',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: '600',
            }}
          >
            {title}
          </Typography>
          {description != null && (
            <Typography
              sx={{
                fontSize: '12px',
                color: theme.palette.text.secondary,
                display: 'block',
                marginTop: '5px',
              }}
            >
              {description}
            </Typography>
          )}
        </DialogTitle>
      )}
      <DialogContent sx={contentStyle}>
        <IconButton
          sx={{
            width: '40px',
            height: '40px',
            position: 'absolute',
            top: '15px',
            right: '15px',
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Box sx={{ margin: '15px 0px', overflow: 'auto' }}>{children}</Box>
        <DialogActions sx={isMobile ? { justifyContent: 'center' } : {}}>
          {customActions}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
