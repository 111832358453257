import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isMobileState,
  oiFullscreenState,
  oiHiroSymsState,
  oiIntradayFilterPrice,
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiIntradayPriceBoundsState,
  oiIntradayTimestampState,
  oiMobileFullscreenDefaultState,
  oiNegativeGammaColorState,
  oiPositiveGammaColorState,
  oiPriceCandleDurationState,
  oiScaleRangeState,
  oiSelectedLenseState,
  oiShowColorScaleState,
  oiShowContourLinesState,
  oiShowGexZeroDteState,
  oiShowKeyLevelsState,
  oiStatsLookbackDaysState,
  oiStrikeBarTypeState,
} from '../../states';
import dayjs, { Dayjs } from 'dayjs';
import {
  alpha,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  getDateFormatted,
  getDefaultSlotProps,
  getQueryDate,
  greekForLense,
  isMarketOpenOnDate,
} from '../../util';
import { useCallback, useMemo, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  GreeksStatsLookback,
  IntradayGammaLense,
  IntradayStrikeBarType,
  OIEntity,
  OIScaleRange,
  OISettings,
  PRICE_BOUNDS,
  PROD_GAMMA_LENSES,
  ZoomData,
} from '../../types';
import { ZoomOut } from '@mui/icons-material';
import { SGTooltip } from '../../components/core';
import useUserDetails from '../../hooks/user/useUserDetails';
import SettingsPopout from '../../components/shared/SettingsPopout';
import { MuiColorInput } from 'mui-color-input';
import { SGSelect } from '../../components';
import { capitalize, isNil, omitBy } from 'lodash';
import {
  HEATMAP_FIRST_AVAILABLE_DATE,
  IntradayFiltersReadable,
  IntradayShowChartType,
} from '../../config/oi';
import { useSetSym } from '../../hooks';
import { ComboSymbol } from '../../config';
import { ChartSizingButton } from '../../components/shared/ChartSizingButton';
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import { HideSupportStyle } from '../../components/shared/HideSupportStyle';
import EditIcon from '@mui/icons-material/Edit';

type IntradayGammaControlsProps = {
  timestamps: dayjs.Dayjs[];
  showAllSettings?: boolean;
  intradayDate: dayjs.Dayjs;
  zoomData: ZoomData | undefined;
  resetZoom: () => void;
  setStrikeBarType: (strikeBarType: IntradayStrikeBarType) => void;
  showChartType: IntradayShowChartType;
};

const IntradayFiltersTooltip = new Map([
  [IntradayGammaLense.GAMMA, 'Shows gamma values on the chart'],
  [IntradayGammaLense.DELTA, 'Shows delta values on the chart'],
  [
    IntradayGammaLense.DELTA_DIRECTIONAL,
    `Shows the direction of delta at the current price per timestamp and strike`,
  ],
  [
    IntradayGammaLense.GAMMA_DIRECTIONAL,
    `Shows the direction of gamma at the current price per timestamp and strike`,
  ],
  [IntradayGammaLense.GAMMA_COLOR, `Shows the change in gamma per minute`],
  [IntradayGammaLense.DELTA_CHARM, `Shows the change in delta per minute`],
  [
    IntradayGammaLense.DELTA_END_DIFF,
    `Shows the change in delta between close and spot/time`,
  ],
]);

const StrikeBarTypeLabels = new Map([
  [IntradayStrikeBarType.GAMMA, `GEX`],
  [IntradayStrikeBarType.OI, 'OI'],
  [IntradayStrikeBarType.OI_NET, 'Net OI'],
  [IntradayStrikeBarType.NONE, 'None'],
]);

const StrikeBarTypeTooltips = new Map([
  [IntradayStrikeBarType.GAMMA, `Show Gamma Exposure in the bar chart`],
  [IntradayStrikeBarType.OI, 'Show Open Interest in the bar chart'],
  [IntradayStrikeBarType.OI_NET, 'Show Net Open Interest in the bar chart'],
  [IntradayStrikeBarType.NONE, 'Hide the strike bar chart'],
]);

const OIENTITY_READABLE = new Map([
  [OIEntity.CUST, 'Customers'],
  [OIEntity.PROCUST, 'Pro Customers'],
  [OIEntity.BD, 'Broker Dealers'],
  [OIEntity.FIRM, 'Firms'],
  [OIEntity.MARKET_MAKER, 'Market Makers'],
]);

export const IntradayGammaControls = ({
  timestamps,
  showAllSettings,
  intradayDate,
  zoomData,
  resetZoom,
  setStrikeBarType,
  showChartType,
}: IntradayGammaControlsProps) => {
  const timestamp = useRecoilValue<Dayjs | null>(oiIntradayTimestampState);
  const [filterPrice, setFilterPrice] = useRecoilState(oiIntradayFilterPrice);
  const [parquetKeys, setParquetKeys] = useRecoilState(oiIntradayParquetKeys);
  const [isInverted, setIsInverted] = useRecoilState(oiIntradayInvertedState);
  const [mobileFullscreen, setMobileFullscreen] = useRecoilState(
    oiMobileFullscreenDefaultState,
  );

  const theme = useTheme();
  const { getParam, searchParams } = useSetSym();
  const defaultSlotProps = getDefaultSlotProps(theme);
  const maxQueryDate = dayjs(getQueryDate(true).format());
  const isMobile = useRecoilValue(isMobileState);
  const { saveSgSettings } = useUserDetails();
  const priceBounds = useRecoilValue(oiIntradayPriceBoundsState);
  const posColor = useRecoilValue(oiPositiveGammaColorState);
  const negColor = useRecoilValue(oiNegativeGammaColorState);
  const candleDuration = useRecoilValue(oiPriceCandleDurationState);
  const selectedLense = useRecoilValue(oiSelectedLenseState);
  const showKeyLevels = useRecoilValue(oiShowKeyLevelsState);
  const scaleRange = useRecoilValue(oiScaleRangeState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const hiroSymsMap = useRecoilValue(oiHiroSymsState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const showColorScale = useRecoilValue(oiShowColorScaleState);
  const showContourLines = useRecoilValue(oiShowContourLinesState);
  const fullscreen = useRecoilValue(oiFullscreenState);
  const statsLookbackDays = useRecoilValue(oiStatsLookbackDaysState);

  const { setParams } = useSetSym();
  // used when playing the day, since if we use an interval we dont have access to updated states

  const intradaySym = useMemo(
    () => getParam('sym')?.toUpperCase() ?? 'SPX',
    [searchParams],
  );
  const hiroSym = hiroSymsMap.get(intradaySym) ?? intradaySym;

  const [unsavedChanges, setUnsavedChanges] = useState<OISettings>({});

  const noUnsavedChanges = Object.keys(unsavedChanges).length === 0;

  const screenSizes = [
    useMediaQuery(theme.breakpoints.down('md')),
    useMediaQuery(theme.breakpoints.down('lg')),
    useMediaQuery(theme.breakpoints.up('lg')),
  ];
  const screenSizeLevel = screenSizes.findIndex((v) => v);
  const horizGap = ['5px', '15px', '25px'][screenSizeLevel];
  const maxScreenSizeLevel = screenSizes.length - 1;

  let selectedTimestampIndex = timestamps.length - 1;
  for (let i = timestamps.length - 1; i >= 0; i--) {
    if (timestamp?.isSame(timestamps[i], 'second')) {
      selectedTimestampIndex = i;
      break;
    }
  }

  const isLatestTimestamp = selectedTimestampIndex === timestamps.length - 1;

  const datePicker = (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={['day']}
        value={intradayDate}
        onChange={(newValue: Dayjs | null) => {
          if (newValue && newValue.isValid()) {
            setParams({ date: getDateFormatted(newValue) });
          }
        }}
        minDate={HEATMAP_FIRST_AVAILABLE_DATE}
        maxDate={maxQueryDate}
        shouldDisableDate={(d) => !isMarketOpenOnDate(d)}
        format="YYYY-MM-DD"
        slotProps={{
          ...defaultSlotProps,
          textField: {
            ...defaultSlotProps.textField,
            style: {
              width: isMobile ? '110px' : '150px',
              fontSize: '14px',
              marginBottom: '12px',
              marginRight: '5px',
            },
          },
        }}
      />
    </LocalizationProvider>
  );

  const handleBoundsChange = (
    _evt: Event,
    idx: number | number[],
    _activeThumb: number,
  ) => {
    if (!Array.isArray(idx)) {
      saveSgSettings({ oi: { zoom: PRICE_BOUNDS[idx] } });
    }
  };

  const updateUnsavedChanges = (newSettings: Partial<OISettings>) => {
    setUnsavedChanges((oldSettings) => {
      // delete null values
      return omitBy({ ...oldSettings, ...newSettings }, isNil);
    });
  };

  const saveChanges = useCallback(() => {
    if (Object.keys(unsavedChanges).length > 0) {
      saveSgSettings({ oi: unsavedChanges });
      setUnsavedChanges({});
    }
  }, [unsavedChanges, saveSgSettings]);

  const reset = useCallback(() => {
    setUnsavedChanges({});
  }, []);

  const settingsPopout = (
    <Box sx={{ height: '40px' }}>
      <SettingsPopout
        title={'Settings'}
        popperID={'intraday-gamma-settings'}
        zIndex={1}
      >
        <Stack direction="row">
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="h4" gutterBottom color="primary">
              Positive {capitalize(greekForLense(selectedLense))} Color
            </Typography>
            <MuiColorInput
              format="hex"
              value={unsavedChanges.posColor ?? posColor}
              onChange={(value: string) => {
                updateUnsavedChanges({
                  posColor: value === posColor ? undefined : value,
                });
              }}
              PopoverProps={{
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
              }}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="h4" gutterBottom color="primary">
              Negative {capitalize(greekForLense(selectedLense))} Color
            </Typography>
            <MuiColorInput
              format="hex"
              value={unsavedChanges.negColor ?? negColor}
              onChange={(value: string) => {
                updateUnsavedChanges({
                  negColor: value === negColor ? undefined : value,
                });
              }}
              PopoverProps={{
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'bottom',
                },
              }}
            />
          </Box>
        </Stack>

        <Box marginY="5px">
          <FormControl>
            <SGTooltip
              title={
                'Controls the level of the high and low of all values on the chart: ' +
                'the color scale range for the heatmap, as well as the GEX bars.' +
                "To calculate it dynamically, select 'auto'. For pre-defined ranges, select one of the other options."
              }
              placement="left"
            >
              <Typography variant="h4" gutterBottom color="primary">
                Scale Range
              </Typography>
            </SGTooltip>

            <RadioGroup
              row
              value={unsavedChanges.scaleRange ?? scaleRange}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateUnsavedChanges({
                  scaleRange:
                    event.target.value === scaleRange
                      ? undefined
                      : (event.target.value as OIScaleRange),
                });
              }}
            >
              <FormControlLabel
                value={OIScaleRange.AUTO}
                control={<Radio />}
                label="auto"
              />
              <FormControlLabel
                value={OIScaleRange.LOW}
                control={<Radio />}
                label="low"
              />
              <FormControlLabel
                value={OIScaleRange.MEDIUM}
                control={<Radio />}
                label="medium"
              />
              <FormControlLabel
                value={OIScaleRange.HIGH}
                control={<Radio />}
                label="high"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box marginY="5px">
          <FormControl>
            <SGTooltip
              title={
                'Select which HIRO symbol to plot on the HIRO line in the chart'
              }
              placement="left"
            >
              <Typography variant="h4" gutterBottom color="primary">
                HIRO Symbol
              </Typography>
            </SGTooltip>

            <RadioGroup
              row
              value={unsavedChanges.hiroSyms?.[intradaySym] ?? hiroSym}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateUnsavedChanges({
                  hiroSyms:
                    event.target.value === hiroSym
                      ? undefined
                      : {
                          ...hiroSymsMap.entries(),
                          [intradaySym]: event.target.value,
                        },
                });
              }}
            >
              <SGTooltip title={'Not available premarket'} placement="left">
                <FormControlLabel
                  value={ComboSymbol.SPX_EQUITIES}
                  control={<Radio />}
                  label={ComboSymbol.SPX_EQUITIES}
                />
              </SGTooltip>
              <FormControlLabel
                value={ComboSymbol.SPX}
                control={<Radio />}
                label={ComboSymbol.SPX}
              />
              <FormControlLabel value={'SPX'} control={<Radio />} label="SPX" />
            </RadioGroup>
          </FormControl>
        </Box>

        <Stack direction="row" justifyContent="center">
          <FormControlLabel
            control={
              <Switch
                checked={
                  unsavedChanges.hideColorScale == null
                    ? showColorScale
                    : !unsavedChanges.hideColorScale
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateUnsavedChanges({
                    hideColorScale: !event.target.checked,
                  })
                }
              />
            }
            label={<Typography>Color scale</Typography>}
            labelPlacement={'end'}
          />
          <FormControlLabel
            control={
              <Switch
                checked={
                  unsavedChanges.hideContourLines == null
                    ? showContourLines
                    : !unsavedChanges.hideContourLines
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateUnsavedChanges({
                    hideContourLines: !event.target.checked,
                  })
                }
              />
            }
            label={<Typography>Contour lines</Typography>}
            labelPlacement={'end'}
          />
        </Stack>

        <Stack gap={3} marginTop="10px">
          <Typography variant="h4" gutterBottom color="primary">
            Statistics Look Back Period
          </Typography>
          <ButtonGroup aria-label="stats lookback settings">
            {[30, 60, 90].map((period) => (
              <Button
                size="small"
                key={period}
                variant={
                  period ===
                  (unsavedChanges.statsLookbackDays ?? statsLookbackDays)
                    ? 'contained'
                    : 'outlined'
                }
                sx={{
                  fontSize: isMobile ? 12 : 14,
                  textTransform: 'none',
                  width: '33.3%',
                }}
                onClick={() =>
                  updateUnsavedChanges({ statsLookbackDays: period })
                }
              >
                {period} days
              </Button>
            ))}
          </ButtonGroup>
        </Stack>

        <Box marginTop="10px" display="flex" justifyContent="space-between">
          <Button
            sx={{
              textTransform: 'capitalize',
              fontSize: '14px',
              height: '30px',
              minWidth: '40px',
              paddingX: '10px',
            }}
            onClick={reset}
            disabled={noUnsavedChanges}
          >
            Reset
          </Button>

          <Button
            variant="contained"
            disabled={noUnsavedChanges}
            sx={{
              textTransform: 'capitalize',
              fontSize: '14px',
              height: '30px',
              minWidth: '40px',
              paddingX: '10px',
            }}
            onClick={saveChanges}
          >
            Save
          </Button>
        </Box>
      </SettingsPopout>
    </Box>
  );

  const strikeBarSelectors = (
    <Stack direction="row" gap={'10px'} key="strikeBarSelectors">
      {(!fullscreen || showChartType === IntradayShowChartType.StrikeBars) && (
        <Box>
          <SGSelect
            key={'strike_bar_type'}
            label={'Strike Bar Type'}
            multiple={false}
            value={unsavedChanges.strikeBarType ?? strikeBarType}
            setter={(val) => setStrikeBarType(val)}
            options={[
              IntradayStrikeBarType.GAMMA,
              IntradayStrikeBarType.OI,
              IntradayStrikeBarType.OI_NET,
              IntradayStrikeBarType.NONE,
            ]}
            optionsTextTransform={(s) => StrikeBarTypeLabels.get(s) ?? s}
            tooltipTransform={(s) => StrikeBarTypeTooltips.get(s)!}
            selectTooltipProps={{
              title:
                'Select what data to show in the strike bar chart on the left',
            }}
          />
        </Box>
      )}
      <Box
        width="90px"
        visibility={
          strikeBarType === IntradayStrikeBarType.NONE ? 'hidden' : 'visible'
        }
      >
        <SGTooltip
          title={`Show only 0dte ${StrikeBarTypeLabels.get(
            strikeBarType,
          )!} in the strike bar chart on the left. Note that after market close these values will be 0.`}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showGexZeroDte}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  saveSgSettings({
                    oi: {
                      showGexZeroDte: event.target.checked,
                    },
                  })
                }
              />
            }
            label={
              <Typography width="40px">
                0dte {StrikeBarTypeLabels.get(strikeBarType)!}
              </Typography>
            }
            labelPlacement={'end'}
          />
        </SGTooltip>
      </Box>
    </Stack>
  );

  const keyLevelsSwitch = (
    <Box sx={{ marginTop: '-4px' }} key="keyLevelsSwitch">
      <FormControlLabel
        control={
          <Switch
            checked={showKeyLevels}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              saveSgSettings({
                oi: { hideLevels: !event.target.checked },
              })
            }
          />
        }
        label={<Typography textAlign="center">Key levels</Typography>}
        labelPlacement={'bottom'}
        sx={{ marginX: 0 }}
      />
    </Box>
  );

  const zoomOutButton = (
    <Box
      key="zoomOutButton"
      width="30px"
      visibility={zoomData == null ? 'hidden' : 'visible'}
      sx={{ cursor: 'pointer', position: 'relative', top: '5px' }}
    >
      <SGTooltip title={'Click to zoom out'}>
        <IconButton onClick={resetZoom}>
          <ZoomOut color="primary" />
        </IconButton>
      </SGTooltip>
    </Box>
  );

  const candleDurationSelect = (
    <SGSelect
      key={'candle_duration'}
      label={'Candle Duration'}
      multiple={false}
      value={candleDuration}
      setter={(newVal: any) =>
        saveSgSettings({
          oi: { candleDuration: newVal },
        })
      }
      options={[60, 5 * 60, 15 * 60]}
      optionsTextTransform={(duration: any) => `${duration / 60}m`}
      tooltipTransform={(duration: any) =>
        `Set the duration of each price candle to ${duration / 60} minutes`
      }
      sx={{ width: '75px', height: '40px', textAlign: 'center' }}
      selectTooltipProps={{
        title: 'Set the duration of each price candle',
      }}
    />
  );

  const zoomSlider = (
    <Box width={'100px'} key="zoomSlider">
      <Slider
        aria-label="Zoom"
        valueLabelDisplay="auto"
        marks
        value={PRICE_BOUNDS.indexOf(priceBounds)}
        min={0}
        max={PRICE_BOUNDS.length - 1}
        onChange={handleBoundsChange}
      />
      <Typography
        sx={{
          fontSize: { xs: 12, sm: 13 },
          width: 1,
          textAlign: 'center',
          display: 'inline-block',
        }}
      >
        Zoom
      </Typography>
    </Box>
  );

  const lenseSelect = (
    <SGSelect
      key="intraday-gamma-filters"
      label="Lenses"
      multiple={false}
      value={selectedLense}
      setter={(newVal: any) =>
        saveSgSettings({
          oi: { selectedLense: newVal },
        })
      }
      options={(showAllSettings
        ? Object.values(IntradayGammaLense).filter((k) => typeof k != 'string')
        : [...PROD_GAMMA_LENSES.keys()]
      ).sort((a: any, b: any) => a - b)}
      optionsTextTransform={(filter: any) =>
        IntradayFiltersReadable.get(filter) ?? ''
      }
      tooltipTransform={(filter: any) => IntradayFiltersTooltip.get(filter)}
      sx={{ width: '125px', height: '40px', textAlign: 'center' }}
      selectTooltipProps={{
        title: 'Select what type of data to display on the TRACE heatmap',
      }}
    />
  );

  const timeCutoff = (
    <Box
      visibility={isLatestTimestamp ? 'hidden' : 'visible'}
      width="75px"
      sx={{ position: 'relative', top: '6px' }}
    >
      <SGTooltip
        title={
          'Limit the price candles and HIRO datapoints shown to only those up to your selected timestamp'
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={filterPrice}
              onChange={(evt) => {
                setFilterPrice(evt.target.checked);
              }}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: {
                  xs: 11,
                  sm: 12,
                },
                color: theme.palette.primary.main,
              }}
            >
              Time Cutoff
            </Typography>
          }
        />
      </SGTooltip>
    </Box>
  );

  // if we're showing just strike bars and we wont be showing both the strike bars and date picker,
  // only show the strike bars
  const showStrikeBarInsteadOfDate =
    screenSizeLevel < maxScreenSizeLevel - 1 &&
    showChartType === IntradayShowChartType.StrikeBars;

  const toolbarComponents = [
    [
      screenSizeLevel >= maxScreenSizeLevel - 1,
      // if we're showing strike bars in the toolbar, put the date picker in the edit, and vice versa
      !showStrikeBarInsteadOfDate ? strikeBarSelectors : datePicker,
    ],
    [true, timeCutoff],
    [true, zoomOutButton],
    [screenSizeLevel === maxScreenSizeLevel, keyLevelsSwitch],
    [screenSizeLevel === maxScreenSizeLevel, candleDurationSelect],
    [screenSizeLevel === maxScreenSizeLevel, zoomSlider],
    [screenSizeLevel >= maxScreenSizeLevel - 1, lenseSelect],
  ];

  const componentsInEditPopout = toolbarComponents.flatMap((arr) =>
    !arr[0] ? [arr[1]] : [],
  );

  const componentsInToolbar = toolbarComponents
    .slice(1)
    .map((arr) => arr[0] && arr[1]);

  const editPopout =
    componentsInEditPopout.length === 0 ? undefined : (
      <Box sx={{ height: '40px' }}>
        <SettingsPopout
          title={'Edit'}
          popperID={'trace-edit-settings'}
          icon={<EditIcon />}
          zIndex={1}
          useModal={true}
          modalProps={{
            mobileFullscreen: false,
            customActions: [],
          }}
          sx={{ width: undefined, boxShadow: 'none' }}
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            gap="15px"
            justifyContent="center"
            overflow="hidden"
          >
            {componentsInEditPopout}
          </Stack>
        </SettingsPopout>
      </Box>
    );

  const strikeBarInToolbar = toolbarComponents[0][0] && toolbarComponents[0][1];

  const toolbar = (
    <Stack
      direction="row"
      gap={horizGap}
      marginTop="3px"
      justifyContent={strikeBarInToolbar ? 'space-between' : 'center'}
    >
      {strikeBarInToolbar}
      <Stack direction="row" gap={horizGap}>
        {componentsInToolbar}
        <Stack direction={'row'}>
          {showStrikeBarInsteadOfDate ? strikeBarSelectors : datePicker}
          <ChartSizingButton
            onClick={() => {
              // this defaults to true on load so mobile starts off in fullscreen
              // once fullscreen is set at all we no longer need it and can rely on the fullscreen
              // in settings
              setMobileFullscreen(false);
              saveSgSettings({ oi: { fullscreen: !fullscreen } });
            }}
            icon={
              fullscreen ? <FullscreenExitSharpIcon /> : <FullscreenSharpIcon />
            }
            title={fullscreen ? 'Close Full Screen' : 'Open Full Screen'}
            buttonSx={{ height: '40px', marginTop: '-1px' }}
          />
          {settingsPopout}
          {editPopout}
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Stack
      sx={{
        marginBottom: showAllSettings ? 0 : '15px',
        paddingX: isMobile ? 0 : '10px',
        margin: 'auto',
        width: 1,
      }}
      gap="5px"
    >
      {fullscreen && <HideSupportStyle />}
      {toolbar}
      {showAllSettings && (
        <Box width={1} display="flex" justifyContent="flex-end">
          <Select
            id="intraday-gamma-select"
            multiple
            value={parquetKeys}
            displayEmpty
            size="small"
            onChange={(event: SelectChangeEvent<string[]>) => {
              setParquetKeys(event.target.value as OIEntity[]);
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return `Select an entity`;
              }
              return selected.map((k) => OIENTITY_READABLE.get(k)!).join(',');
            }}
            sx={{
              padding: 0,
              marginLeft: '50px',
              color: theme.palette.text.secondary,
            }}
          >
            {[...OIENTITY_READABLE.entries()].map(([key, label]) => (
              <MenuItem key={key} value={key}>
                <Checkbox checked={parquetKeys.indexOf(key as OIEntity) > -1} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={isInverted}
                onChange={(evt) => {
                  setIsInverted(evt.target.checked);
                }}
              />
            }
            label={
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: {
                    xs: 12,
                    sm: 13,
                  },
                  color: theme.palette.primary.main,
                }}
              >
                Invert
              </Typography>
            }
          />
        </Box>
      )}
    </Stack>
  );
};
