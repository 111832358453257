import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { isMobileState, userDetailsState } from '../../states';
import { PageBanner } from './PageBanner';
import { useNavigate } from 'react-router-dom';
import { Box, SxProps } from '@mui/material';
import { showNoLoginUI } from '../../util';

export type PageProps = {
  children: ReactNode | ReactNode[];
  showLoggedOutBanner?: boolean;
  sx?: SxProps;
};

export const Page = ({
  children,
  showLoggedOutBanner = true,
  sx = {},
}: PageProps) => {
  const userDetails = useRecoilValue(userDetailsState);
  const navigate = useNavigate();
  const isMobile = useRecoilValue(isMobileState);

  const loggedOutBanner = userDetails == null &&
    showLoggedOutBanner &&
    !showNoLoginUI() && (
      <PageBanner
        message={
          'Viewing the free SpotGamma Preview. Have an account? Click to login.'
        }
        onClick={() => navigate('/login')}
      />
    );

  return (
    <Box
      sx={{
        height: isMobile ? undefined : 'calc(100vh - 120px)',
        ...sx,
      }}
    >
      {loggedOutBanner}
      {children}
    </Box>
  );
};
