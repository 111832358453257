import dayjs from 'dayjs';

export enum OiTab {
  DAILY_OI = 'daily',
  INTRADAY = 'intraday',
}

export const OI_TAB_LABELS = new Map([
  [OiTab.DAILY_OI, 'Daily Open Interest'],
  [OiTab.INTRADAY, 'Intraday OI + Gamma'],
]);

export enum ProcessingState {
  FETCHING = 'fetching',
  READING = 'reading',
  FAILED_FETCH = 'failed_fetch',
  DONE = 'done',
}

export const PRICE_BOUNDS = [
  0.001, 0.002, 0.003, 0.004, 0.005, 0.01, 0.02, 0.03, 0.04,
].reverse(); // reverse to ensure that a higher zoom value on the slider leads to a higher zoom,
// instead of a lower val meaning higher zoom

export type PriceCandleWithDateTime = {
  datetime: dayjs.Dayjs;
} & PriceCandle;

export type PriceCandle = {
  time: number; // in seconds
  open: number;
  high: number;
  low: number;
  close: number;
};

export type OISettings = {
  zoom?: number;
  posColor?: string;
  negColor?: string;
  candleDuration?: number;
  selectedLense?: IntradayGammaLense;
  hideLevels?: boolean;
  scaleRange?: OIScaleRange;
  showGexZeroDte?: boolean;
  hiroSyms?: any;
  strikeBarType?: IntradayStrikeBarType;
  hideColorScale?: boolean;
  hideContourLines?: boolean;
  fullscreen?: boolean;
  statsLookbackDays?: number;
};

export enum IntradayGammaLense {
  DELTA_END_DIFF = 0,
  GAMMA = 1,
  GAMMA_DIRECTIONAL = 2,
  GAMMA_COLOR = 3,
  DELTA = 4,
  DELTA_DIRECTIONAL = 5,
  DELTA_CHARM = 6,
}

export const PROD_GAMMA_LENSES = new Set([
  IntradayGammaLense.DELTA_DIRECTIONAL,
  IntradayGammaLense.GAMMA,
  IntradayGammaLense.GAMMA_DIRECTIONAL,
  IntradayGammaLense.GAMMA_COLOR,
  IntradayGammaLense.DELTA,
  IntradayGammaLense.DELTA_CHARM,
]);

export enum OIEntity {
  CUST = 'cust',
  PROCUST = 'procust',
  MARKET_MAKER = 'mm',
  FIRM = 'firm',
  BD = 'bd',
}

export enum OIScaleRange {
  AUTO = 'auto',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum IntradayStrikeBarType {
  GAMMA = 'gamma',
  OI = 'oi',
  OI_NET = 'oi_net',
  NONE = 'none',
}

export type ZoomData = {
  x?: string[];
  y?: number[];
  y2?: number[];
};
