import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { productAccessState } from 'states';
import { fetchAPI } from '../../util';
import { selectedEquityState } from '../../states/equityhub';
import { ProductType, RawSkewObject } from '../../types';

const useSkew = () => {
  const selectedEquity = useRecoilValue(selectedEquityState);
  const products = useRecoilValue(productAccessState);
  const hasAccess = useMemo(
    () => products.includes(ProductType.EQUITYHUB),
    [products],
  );

  const getSkew = useCallback(async (): Promise<RawSkewObject | null> => {
    if (selectedEquity?.sym) {
      const url = hasAccess ? 'v2/skew' : 'v2/free_skew';
      return await fetchAPI(`${url}?sym=${selectedEquity?.sym}`);
    }

    return null;
  }, [hasAccess, selectedEquity?.sym]);

  return { getSkew };
};

export default useSkew;
