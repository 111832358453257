import { IntradayGammaLense, IntradayStrikeBarType } from '../types';
import dayjs from 'dayjs';
import { ComboSymbol } from './hiro';

export const IntradayFiltersReadable = new Map([
  [IntradayGammaLense.GAMMA, 'Gamma'],
  [IntradayGammaLense.DELTA, 'Delta'],
  [IntradayGammaLense.DELTA_DIRECTIONAL, 'Delta Pressure'],
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 'Gamma Pressure'],
  [IntradayGammaLense.GAMMA_COLOR, 'Color (Gamma)'],
  [IntradayGammaLense.DELTA_CHARM, 'Charm (Delta)'],
  [IntradayGammaLense.DELTA_END_DIFF, 'Delta Relative Close'],
]);

export const IntradayFiltersAxisLabels = new Map([
  [IntradayGammaLense.GAMMA, 'Gamma Notional'],
  [IntradayGammaLense.DELTA, 'Delta Notional'],
  [IntradayGammaLense.DELTA_DIRECTIONAL, 'Relative Delta Notional'],
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 'Relative Gamma Notional'],
  [IntradayGammaLense.GAMMA_COLOR, 'Gamma Notional / minute'],
  [IntradayGammaLense.DELTA_CHARM, 'Delta Notional / minute'],
  [IntradayGammaLense.DELTA_END_DIFF, 'Relative Delta Notional'],
]);

export const IntradayFiltersStatsKeys = new Map<
  IntradayGammaLense | IntradayStrikeBarType,
  string
>([
  [IntradayGammaLense.GAMMA, 'gamma'],
  [IntradayGammaLense.DELTA, 'delta'],
  [IntradayGammaLense.GAMMA_COLOR, 'color'],
  [IntradayGammaLense.DELTA_CHARM, 'charm'],
  [IntradayStrikeBarType.OI, 'strike_oi'],
  [IntradayStrikeBarType.OI_NET, 'strike_oi'],
  [IntradayStrikeBarType.GAMMA, 'strike_gex'],
  [IntradayGammaLense.DELTA_DIRECTIONAL, 'rel_delta'],
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 'rel_gamma'],
]);

export const IntradayFiltersStatsInnerKeys = new Map<
  IntradayGammaLense | IntradayStrikeBarType,
  string
>([
  [IntradayStrikeBarType.OI_NET, 'net'],
  [IntradayStrikeBarType.GAMMA, 'gamma'],
]);

export const HEATMAP_FIRST_AVAILABLE_DATE = dayjs('2024-06-07');
export const HEATMAP_DEFAULT_POS_COLOR = '#5e16b9';
export const HEATMAP_DEFAULT_NEG_COLOR = '#e81152';

export const HEATMAP_DEFAULT_SPX_HIRO_SYM = ComboSymbol.SPX_EQUITIES;

export enum IntradayShowChartType {
  StrikeBars,
  Heatmap,
  Both,
}
