import { ProductType } from '../types';
import { AccessProtectedPage } from './AccessProtectedPage';
import { HomeV2 } from './components/HomeV2';
import { useCallback } from 'react';

export const HomePage = () => {
  const render = useCallback(() => <HomeV2 />, []);
  return (
    <AccessProtectedPage
      productType={ProductType.HOME}
      renderContent={render}
    />
  );
};
