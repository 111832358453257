import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { productAccessState } from 'states';
import { ProductType } from '../../types';
import { prevBusinessDay } from '../../util';
import useLog from '../useLog';

const useEquities = () => {
  const products = useRecoilValue(productAccessState);
  const { fetchAPIWithLog } = useLog('useEquities');

  const getEquities = useCallback(async () => {
    const date = prevBusinessDay().format('YYYY-MM-DD');
    const url = products.includes(ProductType.EQUITYHUB)
      ? 'v4/equities'
      : 'v1/free_equities';
    return await fetchAPIWithLog(`${url}?date=${date}`);
    // fetchAPIWithLog is safe to leave out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  // Outputs an object with keys being 'sym' and value being the 'name' of equity
  const getSymbols = useCallback(async () => {
    return await fetchAPIWithLog(`v1/eh_symbols`);
  }, []);

  const getEquityScanners = useCallback(async () => {
    const url = products.includes(ProductType.EQUITYHUB)
      ? 'v1/equityScanners'
      : 'v1/free_equityScanners';
    return await fetchAPIWithLog(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return { getEquities, getSymbols, getEquityScanners };
};

export default useEquities;
