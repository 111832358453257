export const OPTION_FEED_IDX = {
  SYM: 0,
  TIME: 1,
  DELTA: 2,
  GAMMA: 3,
  VEGA: 4,
  STOCK_PRICE: 5,
  TNS_INDEX: 6,
  EXPIRATION: 7,
  STRIKE: 8,
  SIZE: 9,
  FLAG: 10,
  EXCHANGE_SALE_CONDITIONS: 11,
  PRICE: 12,
  BID: 13,
  ASK: 14,
  IVOL: 15,
};

// history api
// [
//   underlying, //  0: string
//   timestamp,  //  1: 64-bit integer (UTC milliseconds)
//   delta,      //  2: double - Delta HIRO value
//   gamma,      //  3: double - Gamma HIRO value
//   vega,       //  4: double - Vega HIRO value
//   stockPrice, //  5: double - underlying stock price at the time the transaction took place
//   sequence,   //  6: 64-bit integer
//   expiry,     //  7: 64-bit integer (UTC milliseconds)
//   strike,     //  8: float - strike price of the option
//   flags,      //  9: unsigned 32-bit integer
//   instrument, // 10: string - original option instrument namee
// ]

export enum OptionsFeedColumnKey {
  Time = 'time',
  Sym = 'sym',
  Side = 'side', // dynamically generated value
  Strike = 'strike',
  Flags = 'flags',
  Expiration = 'expiration',
  StockPrice = 'stockPrice',
  Price = 'price',
  Bid = 'bid',
  Ask = 'ask',
  Size = 'size',
  Premium = 'premium', // dynamically generated value
  SaleType = 'saleType', // dynamically generated value
  IVol = 'iVol',
  Delta = 'delta',
  Gamma = 'gamma',
  Vega = 'vega',
  TnsIndex = 'tnsIndex',
  ExchangeSaleCondition = 'exchangeSaleCondition',
}

export interface RawOptionFeedData {
  id: string;
  [OptionsFeedColumnKey.Sym]: string;
  [OptionsFeedColumnKey.Time]: bigint;
  [OptionsFeedColumnKey.Delta]: number;
  [OptionsFeedColumnKey.Gamma]: number;
  [OptionsFeedColumnKey.Vega]: number;
  [OptionsFeedColumnKey.StockPrice]: number;
  [OptionsFeedColumnKey.TnsIndex]: bigint;
  [OptionsFeedColumnKey.Expiration]: bigint;
  [OptionsFeedColumnKey.Strike]: number;
  [OptionsFeedColumnKey.Size]: number;
  [OptionsFeedColumnKey.Flags]: OptionFlag;
  [OptionsFeedColumnKey.ExchangeSaleCondition]: string;
  [OptionsFeedColumnKey.Price]: number;
  [OptionsFeedColumnKey.Bid]: number;
  [OptionsFeedColumnKey.Ask]: number;
  [OptionsFeedColumnKey.IVol]: number;
  // dynamic
  [OptionsFeedColumnKey.Premium]: number;
  [OptionsFeedColumnKey.Side]: OptionTradeSide;
  [OptionsFeedColumnKey.SaleType]?: OptionSaleType;
}

export type OptionsFeedColumnVisibility = Record<OptionsFeedColumnKey, boolean>;

export type OptionsFeedColumnSizes = Record<OptionsFeedColumnKey, number>;

export enum OptionTransactionType {
  NEW = 'n',
  CORRECTION = 'r',
  CANCEL = 'c',
}

export enum OptionType {
  PUT = 'PUT',
  CALL = 'CALL',
}

export enum TradeSide {
  BUY = 'b',
  SELL = 's',
  UNDETERMINED = 'u',
}

export enum OptionSaleType {
  BLOCK = 'BLOCK',
  SPREAD = 'SPREAD',
  SWEEP = 'SWEEP',
  CROSS = 'CROSS',
}

export enum OptionTradeSide {
  BB = 'Below Bid',
  B = 'Bid',
  M = 'Mid',
  A = 'Ask',
  AA = 'Above Ask',
}

export interface OptionFlag {
  type: OptionType;
  transactionType: OptionTransactionType;
  sideType: TradeSide;
  isNextExp: boolean;
  isRetail: boolean;
  isBlock: boolean;
  isSpread: boolean;
}

// [
//     string, //  0: underlying
//     BigInt, //  1: time
//     number, //  2: delta_notional
//     number, //  3: gamma_notional
//     number, //  4: vega_notional
//     number, //  5: stock_price
//     number, //  6: tns_index
//     BigInt, //  7: expiry
//     number, //  8: strike
//     number, //  9: size
//     number, // 10: flags
//     string, // 11: exchange sale conditions
//     number, // 12: price
//     number, // 13: bid
//     number, // 14: ask
//     number, // 15: ivol
//   ];
