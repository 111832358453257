import { ProductType, RawGreeksDataMap, RawStatsDataMap } from '../../types';
import { fetchRawFormattedData, getFilteredGreeksData } from 'util/iVol';
import useLog from '../useLog';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { iVolErrorState, iVolLoadingState, productAccessState } from 'states';

const hasIVolAccess = (products: (ProductType | null)[]) =>
  products.includes(ProductType.IMPLIED_VOL);

const useImpliedVolatility = () => {
  const setLoading = useSetRecoilState(iVolLoadingState);
  const setError = useSetRecoilState(iVolErrorState);
  const { logError } = useLog('useImpliedVolatility');

  const getCurrentGreeksData = useRecoilCallback(
    ({ snapshot }) =>
      async (ticker: string): Promise<RawGreeksDataMap | null> => {
        try {
          setLoading(true);
          const products = await snapshot.getPromise(productAccessState);
          const rawData: RawGreeksDataMap = await fetchRawFormattedData(
            `v1/${
              !hasIVolAccess(products)
                ? 'free_current_greeks'
                : 'current_greeks'
            }?sym=${encodeURIComponent(ticker)}`,
          );

          const updatedRawData: RawGreeksDataMap =
            getFilteredGreeksData(rawData);

          setLoading(false);
          setError(null);
          return updatedRawData;
        } catch (error) {
          setError(error);
          logError(error, 'getCurrentGreeksData');
        } finally {
          setLoading(false);
        }

        // failed to fetch scenario
        return null;
      },
    // Making `useLog` a dependency causes infinite fetches
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getDailyGreeksData = useRecoilCallback(
    ({ snapshot }) =>
      async (
        date: string,
        ticker: string,
        isMktOpen?: boolean,
      ): Promise<RawGreeksDataMap | null> => {
        try {
          setLoading(true);
          const products = await snapshot.getPromise(productAccessState);
          const rawData: RawGreeksDataMap = await fetchRawFormattedData(
            `v1/${
              !hasIVolAccess(products) ? 'free_daily_greeks' : 'daily_greeks'
            }?sym=${encodeURIComponent(ticker)}&date=${date}&mkt_close=${
              isMktOpen ? 0 : 1
            }`,
          );

          // filter out any expirations before given trade "date"
          const updatedRawData: RawGreeksDataMap = getFilteredGreeksData(
            rawData,
            date,
          );

          setLoading(false);
          setError(null);
          return updatedRawData;
        } catch (error) {
          setError(error);
          logError(error, 'getDailyGreeksData');
        } finally {
          setLoading(false);
        }

        // failed to fetch scenario
        return null;
      },
    // Making `useLog` a dependency causes infinite fetches
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getStatisticsData = useRecoilCallback(
    ({ snapshot }) =>
      async (
        ticker: string,
        date?: string,
      ): Promise<RawStatsDataMap | null> => {
        try {
          setLoading(true);
          const products = await snapshot.getPromise(productAccessState);
          const rawData: RawStatsDataMap = await fetchRawFormattedData(
            `v1/${
              !hasIVolAccess(products) ? 'free_iv_stats' : 'iv_stats'
            }?sym=${encodeURIComponent(ticker)}${date ? `&date=${date}` : ''}`,
          );

          setLoading(false);
          setError(null);
          return rawData;
        } catch (error) {
          setError(error);
          console.error(`Failed to fetch data: ${error}`);
        } finally {
          setLoading(false);
        }

        // failed to fetch scenario
        return null;
      },
    // Making `useLog` a dependency causes infinite fetches
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    getCurrentGreeksData,
    getDailyGreeksData,
    getStatisticsData,
  };
};

export default useImpliedVolatility;
