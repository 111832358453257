import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import { useCallback } from 'react';
import OptionsFeedContainer from 'components/optionsFeed/OptionsFeedContainer';

export const OptionsFeedPage = () => {
  const render = useCallback(() => <OptionsFeedContainer />, []);

  return (
    <AccessProtectedPage
      productType={ProductType.OPTIONS_FEED}
      renderContent={render}
    />
  );
};
