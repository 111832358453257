import { useRecoilValue } from 'recoil';
import { productAccessState } from '../states';
import { ProductType, SubLevel } from '../types';
import { Upsell } from '../components';
import { Page, PageProps } from './shared/Page';
import { ReactNode } from 'react';
import { isBloomberg } from '../util';

type AccessProtectedPageProps = {
  productType: ProductType;
  renderContent: () => ReactNode;
  overrideCheck?: boolean;
  wrapperSx?: any;
  pageProps?: Partial<PageProps>;
};

const TYPES_TO_IMGS: Map<ProductType, string> = new Map([
  [ProductType.HOME, 'home-locked'],
  [ProductType.INDICES, 'indices-locked'],
  [ProductType.IMPLIED_VOL, 'implied-vol-locked'],
  [ProductType.INTEGRATIONS, 'integrations-locked'],
  [ProductType.FOUNDERS_NOTES, 'founders-note-locked'],
]);

export const AccessProtectedPage = ({
  productType,
  renderContent,
  wrapperSx,
  pageProps,
  overrideCheck = false,
}: AccessProtectedPageProps) => {
  const products = useRecoilValue(productAccessState);
  const productsWithAccess = new Set(products);

  if (
    !isBloomberg() &&
    !overrideCheck &&
    !productsWithAccess.has(productType)
  ) {
    const requiredLevel = () => {
      switch (productType) {
        case ProductType.IMPLIED_VOL:
        case ProductType.HIRO:
          return SubLevel.ALPHA;
        case ProductType.EQUITYHUB:
          return SubLevel.PRO;
        default:
          return SubLevel.STANDARD;
      }
    };

    const level = requiredLevel();
    let levelStr = 'Standard';
    if (level === SubLevel.ALPHA) {
      levelStr = 'Alpha';
    } else if (level === SubLevel.PRO) {
      levelStr = 'Pro';
    }

    let items: string[];

    if (level === SubLevel.STANDARD) {
      items = [
        'Unique support and resistance levels & expert commentary',
        'Key market levels & SpotGamma proprietary options flow data',
        'Daily trading ranges for major U.S. indices',
      ];
    } else {
      items = [
        'Key levels for 3,500+ stocks & indices',
        'Personalized watchlist to monitor tickers',
        'Highly actionable alerts for options and equity traders',
      ];

      if (level >= SubLevel.ALPHA) {
        items = ['Real-time charts incorporating all options trades'].concat(
          items,
        );
      }
    }

    const subtitle = `With a SpotGamma ${levelStr} subscription, you'll get:`;
    const title = `Unlock ${levelStr} Access`;

    return (
      <Upsell
        title={title}
        subtitle={subtitle}
        items={items}
        imageUrl={TYPES_TO_IMGS.get(productType) ?? ''}
      />
    );
  }

  return (
    <Page {...(pageProps ?? {})} sx={wrapperSx}>
      {renderContent()}
    </Page>
  );
};
