import { Button, Menu, MenuItem } from '@mui/material';
import {
  getAdminContentCategory,
  getOpenWindowCb,
  HowToTradeButton,
} from '../shared/HowToTradeButton';
import {
  getAppcues,
  tutorialFlowIdForLocation,
  readableProductType,
} from '../../util';
import { useRecoilValue } from 'recoil';
import { currentProductTypeState } from '../../states';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { TutorialButton } from './TutorialButton';

export const UsingProductButton = () => {
  const currentProductType = useRecoilValue(currentProductTypeState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const adminContentCategory = getAdminContentCategory(currentProductType);

  const [searchParams] = useSearchParams();

  const tutorialFlowId = tutorialFlowIdForLocation(
    currentProductType,
    searchParams,
  );
  const menuOpen = Boolean(anchorEl);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const hasTutorial = getAppcues() != null && tutorialFlowId != null;

  if (adminContentCategory == null && !hasTutorial) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          textTransform: 'none',
          fontSize: '11px',
          height: '30px',
          whiteSpace: 'nowrap',
          padding: '6px 12px',
        }}
        onClick={onClick}
      >
        Using {readableProductType(currentProductType)}
      </Button>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={onClose}>
        {adminContentCategory != null && (
          <MenuItem
            onClick={() => {
              getOpenWindowCb(adminContentCategory)();
              onClose();
            }}
          >
            <HowToTradeButton button={false} />
          </MenuItem>
        )}
        {hasTutorial && (
          <MenuItem
            onClick={() => {
              getAppcues().show(tutorialFlowId);
              onClose();
            }}
          >
            <TutorialButton button={false} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
