import react, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  activeSearchState,
  isMobileState,
  onSymSearchChangeState,
  productAccessState,
  searchHandlerState,
  searchSuggestionsData,
  searchSuggestionsState,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
  userDetailsState,
} from '../../../states';
import { useLocation, useSearchParams } from 'react-router-dom';

import { HIRO_UPSELL } from '../../../config';
import {
  AutoCompleteContainer,
  AutoCompleteItem,
  AutoCompleteItemButton,
} from './styles';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { productTypeForPath, updateSearch } from '../../../util';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { UpsellModal } from 'components/shared';
import { IVOL_UPSELL } from 'config/iVol';

const Root = styled.div`
  position: relative;
  width: 100%;
`;

interface autoCompleteProps {
  inputStyle?: react.CSSProperties;
  optionsStyle?: react.CSSProperties;
  onSymChange?: (newSym: string) => void;
}

export const AutoComplete: FC<autoCompleteProps> = ({
  inputStyle,
  optionsStyle,
}) => {
  const theme = useTheme();
  const [, setSearchParams] = useSearchParams();
  const [search, setSearch] = useRecoilState(searchSuggestionsState);
  const onSymSearchChange = useRecoilValue(onSymSearchChangeState);
  const { suggestions } = search;
  const stocks = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const location = useLocation();
  const suggestionsData = useRecoilValue(searchSuggestionsData);
  const suggestionsHandler = useRecoilValue(searchHandlerState);

  const [activeSearch, setActiveSearch] = useRecoilState(activeSearchState);
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const userDetails = useRecoilValue(userDetailsState);
  const products = useRecoilValue(productAccessState);
  const productType = productTypeForPath(location.pathname);
  const hasAccess = productType == null || products.includes(productType);
  const isMobile = useRecoilValue(isMobileState);

  useEffect(() => {
    setSearch({
      text: activeSearch,
      suggestions: [],
    });
  }, [setSearch, activeSearch]);

  const persistSearch = useCallback(
    (value: string) => {
      if (!hasAccess) {
        return;
      }
      if (value === '') {
        setSearchParams(updateSearch({}));
        return;
      }
      suggestionsHandler(value);
    },
    [hasAccess, setSearchParams, suggestionsHandler],
  );

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let suggestions = [] as { symbol: string; name: string }[];
    if (value.length > 0) {
      // escape any special characters
      const regex = new RegExp(
        value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        'i',
      );

      suggestions = [...suggestionsData]
        .filter(
          (v: { symbol: string; name: string }) =>
            regex.test(v.symbol) || regex.test(v.name),
        )
        .sort((a, b) => {
          const valLowerCase = value?.toLowerCase();
          const aSym = a.symbol.toLocaleLowerCase();
          const bSym = b.symbol.toLowerCase();
          const aName = a?.name?.toLowerCase();
          const bName = b?.name?.toLowerCase();

          // Check if symbol matches exactly with input value, give highest priority
          if (aSym === valLowerCase) {
            return -1;
          } else if (bSym === valLowerCase) {
            return 1;
          }

          // Check if symbol starts with input value
          else if (aSym.startsWith(valLowerCase)) {
            return -1;
          } else if (bSym.startsWith(valLowerCase)) {
            return 1;
          }

          // Otherwise, sort based on the name
          if (aName?.includes(valLowerCase) !== bName?.includes(valLowerCase)) {
            return aName?.includes(valLowerCase) ? -1 : 1;
          }

          // If both have the name, or neither, then sort alphabetically by symbol
          return a.symbol.localeCompare(b.symbol);
        })
        .slice(0, 100);
    }
    setIsComponentVisible(true);
    setSearch({ suggestions, text: value });
  };

  const onKeyDown = useCallback(
    (e: any) => {
      const value = e.target.value;
      if (e.key === 'Enter') {
        // When user hits enter on a symbol they don't have access to and isn't already actively selected, show upsell
        if (!hasAccess && value.toUpperCase() !== activeSearch.toUpperCase()) {
          setUpsellOpen(true);
        }
        setActiveSearch(value.toUpperCase());
        persistSearch(value.toUpperCase());
        if (onSymSearchChange) {
          onSymSearchChange(value.toUpperCase());
        }
      }
    },
    [setActiveSearch, persistSearch, onSymSearchChange],
  );

  const isItemDisabled = (sym: string) => !hasAccess && !stocks.get(sym)?.live;

  const onSuggestionSelected = useCallback(
    (value: { symbol: string; name: string }) => {
      setIsComponentVisible(false);
      setActiveSearch(value.symbol);
      persistSearch(value.symbol);
      if (onSymSearchChange) {
        onSymSearchChange(value.symbol);
      }
    },
    [persistSearch, setActiveSearch, setIsComponentVisible, onSymSearchChange],
  );

  const onDisabledSuggestionSelected = useCallback(
    (value: { symbol: string; name: string }) => {
      setIsComponentVisible(false);
      setActiveSearch(value.symbol);
      persistSearch(value.symbol);
      setUpsellOpen(true);
    },
    [persistSearch, setActiveSearch],
  );

  return (
    <Root>
      <div
        onClick={() => setIsComponentVisible(false)}
        style={{
          display: isComponentVisible ? 'block' : 'none',
          width: '100%',
          height: '200vh',
          backgroundColor: 'transparent',
          position: 'fixed',
          zIndex: 0,
          top: 0,
          left: 0,
        }}
      />
      <div>
        <TextField
          id="input"
          autoComplete="off"
          value={search.text}
          onChange={onTextChanged}
          onKeyDown={onKeyDown}
          style={inputStyle}
          placeholder={'Search'}
          InputProps={{
            endAdornment: !isMobile && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setActiveSearch('');
                    persistSearch('');
                  }}
                  disabled={search.text.length === 0}
                >
                  {search.text.length > 0 ? (
                    <ClearIcon width="20px" style={{ color: '#BCBCBC' }} />
                  ) : (
                    <SearchIcon width="20px" style={{ color: '#BCBCBC' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {suggestions.length > 0 && isComponentVisible && (
        <AutoCompleteContainer className="autocomplete" style={optionsStyle}>
          {suggestions.map((item: { symbol: string; name: string }) => (
            <AutoCompleteItem key={item.symbol}>
              <AutoCompleteItemButton
                key={item.symbol}
                onClick={
                  isItemDisabled(item.symbol)
                    ? () => onDisabledSuggestionSelected(item)
                    : () => onSuggestionSelected(item)
                }
              >
                <Typography color={theme.palette.text.primary} fontSize={12}>
                  {item.symbol}
                </Typography>
                <Typography color={theme.palette.text.secondary} fontSize={10}>
                  {item.name}
                </Typography>
              </AutoCompleteItemButton>
            </AutoCompleteItem>
          ))}
        </AutoCompleteContainer>
      )}
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={HIRO_UPSELL.title}
        subtitle={
          userDetails != null
            ? HIRO_UPSELL.upgrade_subtitle
            : HIRO_UPSELL.subtitle
        }
        items={HIRO_UPSELL.items}
      />
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={IVOL_UPSELL.title}
        subtitle={IVOL_UPSELL.subtitle}
        items={IVOL_UPSELL.items}
      />
    </Root>
  );
};
