import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { productAccessState } from 'states';
import { ProductType } from '../../types';
import useLog from 'hooks/useLog';

const useHistory = () => {
  const { fetchAPIWithLog } = useLog('useHistory');
  const products = useRecoilValue(productAccessState);
  const hasAccess = useMemo(
    () => products.includes(ProductType.EQUITYHUB),
    [products],
  );

  const getHistory = useCallback(
    async (sym: string) => {
      const url = hasAccess ? 'v4/historical' : 'v1/free_historical';
      return await fetchAPIWithLog(`${url}?sym=${sym}`);
    },
    [hasAccess],
  );

  return { getHistory };
};

export default useHistory;
