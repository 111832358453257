import { ExpandableDashboardLayout } from '../../layouts';
import { StrategyCompass } from '../../components/equityhub/Compass/StrategyCompass';
import { StockScanner } from './StockScanner';
import { WatchlistAndTrending } from '../../components';
import { ProductType } from '../../types';
import { useRecoilValue } from 'recoil';
import { scannerDisplayTypeState } from '../../states/scanners';
import { ScannerDisplayType } from '../../types/scanners';

export const StockScannerWithCompass = () => {
  const scannerDisplayType = useRecoilValue(scannerDisplayTypeState);
  if (scannerDisplayType === ScannerDisplayType.Scanners) {
    return <StockScanner />;
  }

  return (
    <ExpandableDashboardLayout
      mainContentComponent={<StrategyCompass />}
      secondarySidebarComponent={
        <WatchlistAndTrending type={ProductType.SCANNERS} />
      }
      bottomDrawerComponent={<StockScanner />}
      mainContentDefaultHeight={0.5}
    />
  );
};
