import { useEffect, useState, useRef, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { decode } from '@msgpack/msgpack';
import { userDetailsState } from 'states';
import { RawOptionFeedData } from 'types/optionsFeed';
import { getOptionFeedDataRow } from 'util/optionsFeed';
import { getCachedToken, nonProdDebugLog } from 'util/shared';
import { StreamType } from 'types';
import { DEFAULT_TNS_SYMBOLS } from 'components/optionsFeed/useTnSsymbols';
import { debounce } from 'lodash';

const MAX_RETRIES = 3;

const STREAM_HOST =
  process.env.REACT_APP_STREAMING_HOST ?? 'dev.stream.spotgamma.com';

const useTnSWebsocket = (
  syms = DEFAULT_TNS_SYMBOLS,
  handleNewData: (newRows: RawOptionFeedData[]) => void,
) => {
  const userDetails = useRecoilValue(userDetailsState);
  const [socketError, setSocketError] = useState<string | null>(null);
  const ws = useRef<WebSocket | null>(null);
  const retryTimeout = useRef<number | null>(null);
  const retryCount = useRef<number>(0);
  const tempData = useRef<RawOptionFeedData[]>([]);

  const debouncedHandleNewData = useCallback(
    debounce(() => {
      if (tempData.current.length > 0) {
        handleNewData([...tempData.current]);
        tempData.current = [];
      }
    }, 300),
    [handleNewData],
  );

  useEffect(() => {
    return () => {
      debouncedHandleNewData.cancel();
    };
  }, [debouncedHandleNewData]);

  useEffect(() => {
    // handle change event in symbols
    sendSocketEvent({ action: 'unsubscribe_all' });
    socketSubscribe(syms);
  }, [syms]);

  const cleanupWebSocket = useCallback(() => {
    if (ws.current) {
      ws.current.onopen = null;
      ws.current.onmessage = null;
      ws.current.onclose = null;
      ws.current.onerror = null;
      if (ws.current.readyState === WebSocket.OPEN) {
        sendSocketEvent({ action: 'unsubscribe_all' });
        ws.current.close();
      }
      ws.current = null;
    }
  }, []);

  const cleanupRetryTimeout = useCallback(() => {
    if (retryTimeout.current) {
      clearTimeout(retryTimeout.current);
      retryTimeout.current = null;
    }
  }, []);

  const sendSocketEvent = useCallback((msg: any) => {
    if (ws.current?.readyState !== WebSocket.OPEN) {
      nonProdDebugLog('Attempted to send msg but websocket is not open', msg);
      return;
    }
    ws.current?.send(JSON.stringify(msg));
  }, []);

  const socketSubscribe = useCallback(
    (symbols: string[]) => {
      nonProdDebugLog('Subscribing to ', symbols);
      const msg = {
        action: 'subscribe',
        underlyings: symbols,
        stream_types: StreamType.FULL_ABSOLUTE_SIGNAL,
      };
      sendSocketEvent(msg);
    },
    [sendSocketEvent],
  );

  const authenticateAndConnect = useCallback(() => {
    setSocketError(null);
    cleanupWebSocket();
    cleanupRetryTimeout();

    const streamingToken = getCachedToken();
    if (!streamingToken || userDetails?.isInstitutional !== false) {
      nonProdDebugLog('Found null streamingToken in authenticateAndConnect');
      return;
    }

    ws.current = new WebSocket(
      `wss://${STREAM_HOST}/stream?token=${encodeURIComponent(streamingToken)}`,
    );

    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
      retryCount.current = 0; // Reset retry count on successful connection
      socketSubscribe(syms);
    };

    ws.current.onmessage = async (event: MessageEvent) => {
      const { data } = event;
      if (typeof data === 'string') {
        console.log('Ack from server');
      } else {
        const signalTuple: any = decode(await data.arrayBuffer(), {
          useBigInt64: true,
        });
        const [, signal] = signalTuple;
        const newRow = getOptionFeedDataRow(signal);
        tempData.current.push(newRow);
        debouncedHandleNewData();
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed, attempting to reconnect...');
      if (retryCount.current < MAX_RETRIES) {
        retryCount.current += 1;
        retryTimeout.current = window.setTimeout(() => {
          console.log(`Attempting to reconnect... (${retryCount.current})`);
          authenticateAndConnect();
        }, 5000);
      } else {
        console.log('Max retry attempts reached. Connection failed.');
        cleanupRetryTimeout();
      }
    };

    ws.current.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
      setSocketError('Something went wrong during the connection...');
    };
  }, [
    cleanupWebSocket,
    cleanupRetryTimeout,
    sendSocketEvent,
    socketSubscribe,
    userDetails,
    debouncedHandleNewData,
    syms,
  ]);

  useEffect(() => {
    if (userDetails?.isInstitutional === false) {
      tempData.current = [];
      authenticateAndConnect();
    }

    return () => {
      cleanupWebSocket(); // Cleanup WebSocket on unmount
      cleanupRetryTimeout(); // Cleanup retry timeout on unmount
    };
  }, [userDetails]);

  return { error: socketError };
};

export default useTnSWebsocket;
