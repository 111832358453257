import {
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  OptionsFeedColumnVisibility,
} from 'types/optionsFeed';

export const DEFAULT_TNS_FIELDS = Object.values(OptionsFeedColumnKey);

export const OPTIONS_FEED_MAX_ROWS = 10_000;

export const OPTIONS_FEED_WATCHLIST_FILTER_KEY = 'watchlist-syms-filter';

export const DEFAULT_TNS_COL_VISIBILITY = Object.values(
  OptionsFeedColumnKey,
).reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {} as OptionsFeedColumnVisibility);

export const TNS_UNMODIFIABLE_FIELDS = [
  OptionsFeedColumnKey.Time,
  OptionsFeedColumnKey.Sym,
];

const getColumnSize = (key: OptionsFeedColumnKey) => {
  switch (key) {
    case OptionsFeedColumnKey.Time:
      return 110;
    case OptionsFeedColumnKey.Sym:
      return 110;
    case OptionsFeedColumnKey.Side:
      return 90;
    case OptionsFeedColumnKey.Strike:
      return 60;
    case OptionsFeedColumnKey.Expiration:
      return 110;
    case OptionsFeedColumnKey.StockPrice:
      return 125;
    case OptionsFeedColumnKey.Bid:
    case OptionsFeedColumnKey.Ask:
    case OptionsFeedColumnKey.Size:
      return 90;
    case OptionsFeedColumnKey.Price:
      return 120;
    case OptionsFeedColumnKey.Premium:
      return 110;
    case OptionsFeedColumnKey.SaleType:
      return 100;
    case OptionsFeedColumnKey.IVol:
      return 80;
    default:
      return 60;
  }
};

export const DEFAULT_TNS_COL_SIZES = Object.values(OptionsFeedColumnKey).reduce(
  (acc, key) => {
    acc[key] = getColumnSize(key);
    return acc;
  },
  {} as OptionsFeedColumnSizes,
);
